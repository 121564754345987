import {
	containerNumberNonSOWNSchema,
	containerNumberSchema,
} from '../schema/container';
import {
	type ValidationResult,
	validationError,
	validationSuccess,
} from '@uturn/portal/types';
import { z } from '@uturn/portal/zod';

export function useContainerNumberValidation() {
	/**
	 * validateContainerNumber
	 *
	 * - Must be a string.
	 * - Must be 11 characters long.
	 * - First 3 characters should be letters.
	 * - Last 7 characters should be numbers.
	 * - 4th character should be U, J or Z (unless shippingLine.code is 'SOWN').
	 *
	 * @param containerNumber
	 * @param shippingLineCode
	 * @returns
	 */
	const validateContainerNumber = (
		containerNumber: string,
		shippingLineCode?: string
	): ValidationResult => {
		const schema =
			shippingLineCode && shippingLineCode === 'SOWN'
				? containerNumberSchema
				: containerNumberNonSOWNSchema;
		try {
			schema.parse(containerNumber);
			return validationSuccess;
		} catch (err) {
			if (err instanceof z.ZodError) {
				return validationError(
					err.issues[0].message,
					'equipment.containerNumber'
				);
			}
		}
		return validationSuccess;
	};
	return { validateContainerNumber };
}
