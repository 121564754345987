import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { translateLegacyRoute } from '../utils/translate-legacy-route';

export function NotFound() {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const routeTranslation = translateLegacyRoute(pathname);
	const { t } = useTranslation();

	useEffect(() => {
		if (routeTranslation !== null) {
			navigate(routeTranslation, { replace: true });
		}
	}, []);

	return (
		<>
			<Helmet title={t('pages.not-found.title', 'Not found')!} />
			<div className="w-screen h-screen justify-center gap-3 flex flex-col items-center text-secondary-500">
				<div className="text-3xl font-bold">
					{t(
						'pages.not-found.heading',
						"Uh oh, we can't seem to find the page you're looking for."
					)}
				</div>
				<div className="text-xl font-bold">
					{t(
						'pages.not-found.description',
						'We have taken note of this, and will look into it. Contact us if you need help.'
					)}
				</div>
				<div>
					<Link to="/" className="text-primary-500">
						{t('pages.not-found.button-label', 'Go home')}
					</Link>
				</div>
			</div>
		</>
	);
}
