/* eslint-disable @typescript-eslint/no-redeclare */
import type {
	AddressDto,
	ConditionType,
	FileRequest,
	ShipmentLocationActionDto as LocationActionGET, // GET means from GET shipment
	ShipmentLocationActionDtoLocationActionType as LocationActionTypeGET, // GET means from GET shipment
	ShipmentLocationActionRequestDtoLocationActionType as LocationActionTypePOST, // POST means from POST shipment
	ShipmentLocationActionUpdateDtoLocationActionType as LocationActionTypePUT, // PUT means from PUT shipment
	ShippingLocationDto as ShippingLocationGET, // GET means from GET shipment
} from '@uturn/api/v1';
import type { ReviewResponse, Route } from '@uturn/api/v2';

export type TransportTypeCode =
	(typeof TransportTypeCode)[keyof typeof TransportTypeCode];

export const TransportTypeCode = {
	IMPORT: 'import',
	EXPORT: 'export',
	SHUNT: 'shunt',
} as const;

export type TypeOfShipment =
	(typeof TypeOfShipment)[keyof typeof TypeOfShipment];

export const TypeOfShipment = {
	SINGLE: 'single',
	GROUP: 'group',
} as const;

export type ContainerStatus =
	(typeof ContainerStatus)[keyof typeof ContainerStatus];

export const ContainerStatus = {
	EMPTY: 'empty',
	FULL: 'full',
} as const;

export type OrganizationDefaultValues =
	| {
			invoiceRequired: boolean;
			conditions: ConditionType;
	  }
	| undefined;

export type Document = {
	file: File;
	fileRequest: Partial<FileRequest>;
};

export const initFileRequest: Partial<FileRequest> = {
	fileName: '',
	fileType: undefined,
	description: '',
};

export type Documents = {
	files: File[];
	fileRequests: Partial<FileRequest[]>;
};

export const initDocuments: Documents = {
	files: [],
	fileRequests: [],
};

export type FileTypeOption = {
	label: string;
	value: string;
	order: number;
};

export const initFileTypeOptions = [] as FileTypeOption[];

export const allowedFileTypes = [
	'T1',
	'BOL',
	'SHIPMENT_DGD',
	'CUSTOMS',
	'RELEASE',
	'SHIPMENT_GENERAL', // Other
];

type RouteSelection = {
	isSelected: boolean;
	isValid: boolean;
	isDisabled: boolean;
	validationTitle?: string;
};

export type AvailableRoute = Route & RouteSelection;

export type CarrierReview = ReviewResponse | undefined;

/**
 * Combines the following types:
 * - ShippingLocationRequestDto: used for POST shipment
 * - ShippingLocationDto: used for GET shipment
 * - ShippingLocationRequestDto: used for PUT shipment
 */
export type AnyShippingLocation = Omit<
	Partial<ShippingLocationGET>,
	'address'
> & {
	address?: Partial<AddressDto>;
};

/**
 * Combines the following types:
 * - ShipmentLocationActionRequestDtoLocationActionType: used for POST shipment
 * - ShipmentLocationActionDtoLocationActionType: used for GET shipment
 * - ShipmentLocationActionUpdateDtoLocationActionType: used for PUT shipment
 */
export type AnyLocationActionType =
	| LocationActionTypePOST
	| LocationActionTypeGET
	| LocationActionTypePUT;

/**
 * Combines the following types:
 * - ShipmentLocationActionRequestDto: used for POST shipment
 * - ShipmentLocationActionDto: used for GET shipment
 * - ShipmentLocationActionUpdateDto: used for PUT shipment
 */
export type AnyLocationAction = Omit<
	Partial<LocationActionGET>,
	'location' | 'locationActionType'
> & {
	location: AnyShippingLocation;
	locationActionType: AnyLocationActionType;
	shippingLineId?: number;
};
