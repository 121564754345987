import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import type { RouteObject } from 'react-router-dom';
import { Navigate, Outlet } from 'react-router-dom';
import { LoadingSpan } from '../components';
import { LaneCreate } from '../modules/lanes/pages/create';
import { LaneDetails } from '../modules/lanes/pages/detail';
import { LanesOverview } from '../modules/lanes/pages/overview';
import { ProtectedPriceCalculator } from '../modules/price-calculator/pages/authenticated';
import { PriceCalculatorSubscriptionsOverview } from '../modules/price-calculator/pages/subscriptions';
import { SearchResultsOverview } from '../modules/search/pages/overview';
import { ShipmentDetailSkeletonLoader } from '../modules/shipments/components/shipment-details-skeleton';
import Bulk from '../modules/shipments/pages/shipment-create/bulk';
import { NotFound } from '../pages/not-found';
import { Redirect } from '../pages/redirect';
import { AccountLayout } from '@uturn/portal/modules/account/layout';
import { AccountNotifications } from '@uturn/portal/modules/account/pages/notifications';
import { AccountProfile } from '@uturn/portal/modules/account/pages/profile';
import { CarriersLayout } from '@uturn/portal/modules/carriers/layout';
import { CarriersInvite } from '@uturn/portal/modules/carriers/pages/invite';
import { CarriersOverview } from '@uturn/portal/modules/carriers/pages/overview';
import { InvoicesOverview } from '@uturn/portal/modules/invoices/pages/overview';
import { OrganizationLayout } from '@uturn/portal/modules/org/layout';
import { OrganizationDocumentsLayout } from '@uturn/portal/modules/org/layouts/documents-layout';
import OrganizationUsersLayout from '@uturn/portal/modules/org/layouts/users-layout';
import { OrganizationBilling } from '@uturn/portal/modules/org/pages/billing';
import OrganizationDocuments from '@uturn/portal/modules/org/pages/documents';
import { OrganizationProfile } from '@uturn/portal/modules/org/pages/profile';
import { OrganizationShipmentSettings } from '@uturn/portal/modules/org/pages/shipment-settings';
import { OrganizationSubscriptions } from '@uturn/portal/modules/org/pages/subscriptions';
import { OrganizationUsers } from '@uturn/portal/modules/org/pages/users';
import {
	AddShipment,
	ArchivedGroupShipments,
	ArchivedShipments,
	AssignedGroupShipments,
	AssignedShipments,
	DraftedGroupShipments,
	DraftedShipments,
	GroupedShipments,
	ManualShipment,
	PublishedGroupShipments,
	PublishedShipments,
	ShipmentDetail,
	ShipmentsGroupDetailLayout,
	ShipmentsOverviewLayout,
} from '@uturn/portal/modules/shipments';
import { ShipmentDetailProvider } from '@uturn/portal/modules/shipments/providers/shipment-detail';
import { ShipmentGroupDetailProvider } from '@uturn/portal/modules/shipments/providers/shipment-group-detail';

export const protectedRoutes: RouteObject[] = [
	{
		path: '*',
		Component: NotFound,
	},
	{
		path: '/redirect/*',
		Component: Redirect,
	},
	{
		index: true,
		Component: () => <Navigate to="/shipments " replace />,
	},
	{
		path: '/invoices',
		Component: InvoicesOverview,
	},
	{
		path: '/search',
		Component: SearchResultsOverview,
	},
	{
		path: '/carriers',
		Component: CarriersLayout,
		children: [
			{
				index: true,
				Component: CarriersOverview,
			},
			{
				path: 'add',
				Component: CarriersInvite,
			},
		],
	},
	{
		path: '/lanes',
		Component: () => {
			const { t } = useTranslation();
			return (
				<>
					<Helmet title={t('pages.lanes.overview.title', 'Lanes')!} />
					<Suspense fallback={<LoadingSpan />}>
						<Outlet />
					</Suspense>
				</>
			);
		},
		children: [
			{
				index: true,
				Component: LanesOverview,
			},
			{
				path: 'create',
				Component: LaneCreate,
			},
			{
				path: ':id',
				Component: LaneDetails,
			},
		],
	},
	{
		path: '/calculator/:id?',
		Component: () => {
			const { t } = useTranslation();
			return (
				<>
					<Helmet
						title={t('pages.price-calculator.title', 'Price Calculator')!}
					/>
					<Suspense fallback={<LoadingSpan />}>
						<ProtectedPriceCalculator />
					</Suspense>
				</>
			);
		},
	},
	{
		path: '/calculator/subscriptions',
		Component: () => {
			const { t } = useTranslation();
			return (
				<>
					<Helmet title={t('pages.subscriptions.title', 'Subscriptions')!} />
					<Suspense fallback={<LoadingSpan />}>
						<PriceCalculatorSubscriptionsOverview />
					</Suspense>
				</>
			);
		},
	},
	{
		path: '/account',
		Component: AccountLayout,
		children: [
			{
				index: true,
				Component: AccountProfile,
			},
			{
				path: 'notifications',
				index: true,
				Component: AccountNotifications,
			},
		],
	},
	{
		path: '/org',
		children: [
			{
				path: ':orgId/settings',
				Component: OrganizationLayout,
				children: [
					{
						index: true,
						Component: OrganizationProfile,
					},
					{
						path: 'billing',
						Component: OrganizationBilling,
					},
					{
						path: 'documents',
						Component: OrganizationDocumentsLayout,
						children: [
							{
								index: true,
								Component: OrganizationDocuments,
							},
						],
					},
					{
						path: 'users',
						Component: OrganizationUsersLayout,
						children: [
							{
								index: true,
								Component: OrganizationUsers,
							},
						],
					},
					{
						path: 'subscriptions',
						Component: OrganizationSubscriptions,
					},
					{
						path: 'shipment-settings',
						Component: OrganizationShipmentSettings,
					},
				],
			},
		],
	},
	{
		path: '/shipments',
		Component: () => {
			return (
				<ShipmentGroupDetailProvider>
					<Outlet />
				</ShipmentGroupDetailProvider>
			);
		},
		children: [
			{
				path: '',
				Component: ShipmentsOverviewLayout,
				children: [
					{
						index: true,
						Component: PublishedShipments,
					},
					{
						path: 'assigned',
						index: true,
						Component: AssignedShipments,
					},
					{
						path: 'drafted',
						index: true,
						Component: DraftedShipments,
					},
					{
						path: 'archived',
						index: true,
						Component: ArchivedShipments,
					},
					{
						path: 'groups',
						Component: GroupedShipments,
					},
				],
			},
			{
				path: 'groups/:groupId',
				Component: () => (
					<Suspense fallback={<LoadingSpan />}>
						<ShipmentsGroupDetailLayout />
					</Suspense>
				),
				children: [
					{
						path: '',
						index: true,
						Component: PublishedGroupShipments,
					},
					{
						path: 'assigned',
						index: true,
						Component: AssignedGroupShipments,
					},
					{
						path: 'drafted',
						index: true,
						Component: DraftedGroupShipments,
					},
					{
						path: 'archived',
						index: true,
						Component: ArchivedGroupShipments,
					},
				],
			},
			{
				path: 'add',
				Component: () => {
					return <Outlet />;
				},
				children: [
					{
						path: '',
						index: true,
						Component: AddShipment,
					},
					{
						path: 'manual',
						Component: ManualShipment,
					},
					{
						path: 'bulk',
						Component: Bulk,
					},
				],
			},
			{
				path: ':id',
				Component: () => (
					<Suspense fallback={<ShipmentDetailSkeletonLoader />}>
						<ShipmentDetailProvider>
							<ShipmentDetail />
						</ShipmentDetailProvider>
					</Suspense>
				),
			},
		],
	},
];
