export type UnpublishOptions =
	(typeof UnpublishOptions)[keyof typeof UnpublishOptions];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UnpublishOptions = {
	UNPUBLISH: 'unpublish',
	CANCEL: 'cancel',
} as const;

export type UnpublishOption = {
	value: UnpublishOptions;
	heading: string;
	recommended?: boolean;
	body: string;
};

export type Modal = 'unpublish' | 'archive-shipment' | 'cancel-shipment' | '-';
