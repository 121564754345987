import {
	AlertDialogCancel,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	Button,
} from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';

export const ShipmentPublishDeniedModal = ({
	copyShipment,
}: {
	copyShipment: () => void;
}) => {
	const { t } = useTranslation();

	return (
		<>
			<AlertDialogHeader>
				<AlertDialogTitle>
					{t(
						'pages.addshipment.modal.actions-denied.publish.title',
						'Waiting for approval'
					)}
				</AlertDialogTitle>
				<AlertDialogDescription>
					{t(
						'pages.addshipment.modal.actions-denied.publish.description',
						'Cannot publish your shipment while your organisation is pending approval by UTURN. Please save shipment as concept instead.'
					)}
				</AlertDialogDescription>
			</AlertDialogHeader>
			<AlertDialogFooter>
				<AlertDialogCancel asChild>
					<Button variant="outline" onClick={copyShipment}>
						{t('pages.addshipment.modal.actions.close', 'Close')}
					</Button>
				</AlertDialogCancel>
			</AlertDialogFooter>
		</>
	);
};
