/* eslint-disable no-nested-ternary */
import {
	GetShipmentsFilterCarrierAssignedSubStatusItem,
	GetShipmentsFilterStatusesItem,
} from '@uturn/api/v2';
import { AccordionContent, AccordionTrigger, Checkbox } from '@uturn/ui-kit';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterContext } from './context';

/**
 * @deprecated
 */
export function TsoStatusFilter() {
	const { filters, setFilters } = useContext(FilterContext);
	const { t } = useTranslation();
	const tsoStatusses = [
		...((filters['filter.statuses'] as unknown) ===
		GetShipmentsFilterStatusesItem.COST_DECLARED
			? [GetShipmentsFilterStatusesItem.COST_DECLARED]
			: []),

		...(typeof filters['filter.carrierAssignedSubStatus'] === 'string'
			? [filters['filter.carrierAssignedSubStatus']]
			: typeof filters['filter.carrierAssignedSubStatus'] === 'object'
			? filters['filter.carrierAssignedSubStatus']
			: []),
	];

	const handleOnChange = (
		selectedStatus:
			| GetShipmentsFilterCarrierAssignedSubStatusItem
			| GetShipmentsFilterStatusesItem,
		select: boolean
	) => {
		if (select) {
			if (selectedStatus === GetShipmentsFilterStatusesItem.COST_DECLARED) {
				setFilters({ 'filter.statuses': selectedStatus as any });
			} else {
				setFilters({
					'filter.carrierAssignedSubStatus': [
						...(tsoStatusses.filter((item) => item !== 'COST_DECLARED') as any),
						selectedStatus as GetShipmentsFilterCarrierAssignedSubStatusItem,
					],
				});
			}

			return;
		}

		if (selectedStatus === GetShipmentsFilterStatusesItem.COST_DECLARED) {
			setFilters({ 'filter.statuses': [] });
		} else {
			setFilters({
				'filter.carrierAssignedSubStatus': tsoStatusses.filter(
					(item) => item !== selectedStatus && item !== 'COST_DECLARED'
				) as any,
			});
		}
	};

	return (
		<>
			<AccordionTrigger>
				{t('pages.shipments.filters.tso-status.title', 'Status')}
			</AccordionTrigger>
			<AccordionContent>
				<div className="grid gap-1">
					<div className="flex items-center space-x-2">
						<Checkbox
							checked={
								tsoStatusses.filter((value) => value === 'OPEN').length > 0
							}
							onCheckedChange={(e: boolean) =>
								handleOnChange(
									GetShipmentsFilterCarrierAssignedSubStatusItem.OPEN,
									e
								)
							}
							id="open"
						/>
						<label
							htmlFor="open"
							className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer"
						>
							{t(`pages.shipments.filters.tso-status.open`, 'Open')}
						</label>
					</div>
					<div className="flex items-center space-x-2">
						<Checkbox
							checked={
								tsoStatusses.filter((value) => value === 'IN_PROGRESS').length >
								0
							}
							onCheckedChange={(e: boolean) =>
								handleOnChange(
									GetShipmentsFilterCarrierAssignedSubStatusItem.IN_PROGRESS,
									e
								)
							}
							id="inprogress"
						/>
						<label
							htmlFor="inprogress"
							className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer"
						>
							{t(
								`pages.shipments.filters.tso-status.in-progress`,
								'In progress'
							)}
						</label>
					</div>
					<div className="flex items-center space-x-2">
						<Checkbox
							checked={
								tsoStatusses.filter((value) => value === 'COST_DECLARED')
									.length > 0
							}
							onCheckedChange={(e: boolean) =>
								handleOnChange(GetShipmentsFilterStatusesItem.COST_DECLARED, e)
							}
							id="costdeclared"
						/>
						<label
							htmlFor="costdeclared"
							className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer"
						>
							{t(
								`pages.shipments.filters.tso-status.cost-declared`,
								'Cost declared'
							)}
						</label>
					</div>
				</div>
			</AccordionContent>
		</>
	);
}
