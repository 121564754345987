import type { TFunction } from 'i18next';
import type { TransportTypeCode } from '@uturn/portal/types/shipment';

export const transportTypeName = (
	t: TFunction<'translation', undefined>
): Record<TransportTypeCode, string> => ({
	import: t('general.transport_type.import', 'Import'),
	export: t('general.transport_type.export', 'Export'),
	shunt: t('general.transport_type.shunt', 'Shunt'),
});
