import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import {
	type ShipmentUnitDTO,
	type ShippingLineDTO,
	isContainerRepeated,
} from '@uturn/api/v1';
import { Icon } from '@uturn/ui';
import {
	Checkbox,
	FormControl,
	FormDescription,
	FormField,
	FormFieldReadOnly,
	FormItem,
	FormLabel,
	FormMessage,
	Input,
	PopoverSelect,
	RadioGroup,
	RadioGroupItem,
} from '@uturn/ui-kit';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useContainerNumberValidation } from '../../validation/container-number';
import { HiddenFieldLabelInfo } from '@uturn/portal/modules/shipments/components/hidden-field-label-info';
import { ShipmentDetailContext } from '@uturn/portal/modules/shipments/providers/shipment-detail';
import type { FormValues } from '@uturn/portal/modules/shipments/schema';
import {
	grossWeightUndefined,
	isFieldReadOnly,
	shouldRevalidate,
	shouldUpdate,
} from '@uturn/portal/modules/shipments/utils';
import {
	ContainerStatus,
	TransportTypeCode,
} from '@uturn/portal/types/shipment';
import { validationSuccess } from '@uturn/portal/types/validation';

export function ContainerDetails({
	containerTypesRaw,
	shippingLinesRaw,
	enableAddingGroup,
}: {
	containerTypesRaw: ShipmentUnitDTO[];
	shippingLinesRaw: ShippingLineDTO[];
	enableAddingGroup: boolean;
}) {
	const { t } = useTranslation();
	const { validateContainerNumber } = useContainerNumberValidation();
	const { readOnlyFields } = useContext(ShipmentDetailContext);
	const form = useFormContext<FormValues>();

	const transportType = form.getValues('transportType');
	const tareWeight = form.getValues('tareWeight.quantity');

	const watchContainerType = form.watch('containerType');
	const watchShippingLineCode = form.watch('shippingLine.code');
	const watchGrossWeight = form.watch('grossWeight.quantity');

	const [lastPositiveGrossWeight, setLastPositiveGrossWeight] =
		useState(watchGrossWeight);

	useEffect(() => {
		if (watchGrossWeight > 0) setLastPositiveGrossWeight(watchGrossWeight);
	}, [watchGrossWeight]);

	const ContainerStatusLabels: Record<ContainerStatus, string> = {
		[ContainerStatus.FULL]: t(
			`pages.addshipment.manual.container_status.${ContainerStatus.FULL}.label`,
			'Full'
		),
		[ContainerStatus.EMPTY]: t(
			`pages.addshipment.manual.container_status.${ContainerStatus.EMPTY}.label`,
			'Empty'
		),
	};

	const showGeneratorSet = () => {
		return !watchContainerType
			? false
			: containerTypesRaw.find((item) =>
					[item.code, item.description].includes(watchContainerType)
			  )?.generatorSetRequired || false;
	};

	const handleContainerNumberValidation = (
		containerNumber?: string,
		shippingLineCode?: string
	) => {
		if (!containerNumber) {
			return validationSuccess;
		}
		const result = validateContainerNumber(containerNumber, shippingLineCode);
		if (!result.valid) {
			const { path, message } = result;
			form.setError(path, {
				type: 'custom',
				message,
			});
		}
		return result;
	};

	const handleContainerNumberBlur = (value: string) => {
		form.clearErrors('equipment.containerNumber');

		if (!handleContainerNumberValidation(value, watchShippingLineCode).valid) {
			return;
		}

		isContainerRepeated(value, 0).then((response: any) => {
			if (response.data) {
				form.setError('equipment.containerNumber', {
					type: 'custom',
					message: t(
						'pages.addshipment.manual.container_in_use',
						'This container is already in use.'
					)!,
				});
			}
		});
	};

	const handleContainerStatusChange = (containerStatus: string) => {
		switch (containerStatus) {
			case ContainerStatus.EMPTY:
				form.setValue('grossWeight.quantity', 0, shouldUpdate);
				break;
			case ContainerStatus.FULL:
				form.setValue(
					'grossWeight.quantity',
					lastPositiveGrossWeight || grossWeightUndefined,
					shouldUpdate
				);
				break;
			default:
				break;
		}
	};

	return (
		<div className="grid grid-cols-1 sm:grid-cols-6 w-full gap-3">
			<div className="sm:col-span-full">
				<h3 className="text-lg font-heading font-medium tracking-tight">
					{t(
						'pages.create_shipment.shipment-details.container_details.heading',
						'Container details'
					)}
				</h3>
			</div>
			<div className="grid grid-cols-1 col-span-3 sm:grid-cols-6 gap-6">
				<div className="sm:col-span-4">
					<FormField
						control={form.control}
						name="containerType"
						render={({ field }) => (
							<FormItem>
								<FormLabel className="required-asterix">
									{
										t(
											'pages.shipment-details.container_details.container_type.label',
											'Container type'
										)!
									}
								</FormLabel>
								{isFieldReadOnly(readOnlyFields, 'containerType') ? (
									<FormFieldReadOnly value={field.value} />
								) : (
									<FormControl>
										<PopoverSelect
											ref={field.ref}
											onChange={field.onChange}
											selected={field.value ?? ''}
											options={containerTypesRaw.map((item) => {
												return {
													label: item.description!,
													value: item.code!,
													source: item.description!,
													raw: item,
												};
											})}
											onSelect={(item) => {
												const { id, portbaseCode } = item;
												form.setValue(
													'equipment.shipmentUnitId',
													id!,
													shouldUpdate
												);
												form.setValue(
													'equipment.isoType',
													portbaseCode!,
													shouldRevalidate
												);
											}}
											className="w-full"
											placeholder={
												t(
													'pages.shipment-details.container_details.container_type.placeholder',
													'Select type'
												)!
											}
											filter={{
												placeholder: t(
													'pages.shipment-details.container_details.container_type.search.placeholder',
													'Search container type...'
												)!,
												empty: t(
													'pages.shipment-details.container_details.container_type.search.empty',
													'No container type found.'
												)!,
											}}
										/>
									</FormControl>
								)}
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<div className="sm:col-span-2">
					<FormField
						control={form.control}
						name="equipment.isoType"
						render={({ field }) => (
							<FormItem>
								<FormLabel className="required-asterix">
									{t(
										'pages.shipment-details.container_details.iso_type.label',
										'ISO-type'
									)}
								</FormLabel>
								{isFieldReadOnly(readOnlyFields, 'equipment.isoType') ? (
									<FormFieldReadOnly value={field.value} />
								) : (
									<FormControl>
										<Input
											{...field}
											disabled={!watchContainerType}
											onBlur={
												(e) => form.trigger('equipment.isoType') // TODO: Fix this workaround
											}
										/>
									</FormControl>
								)}
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				{showGeneratorSet() && (
					<div className="sm:col-span-full">
						<FormField
							control={form.control}
							name="generatorSet"
							render={({ field }) => (
								<FormItem className="flex flex-row items-center space-x-3 space-y-0">
									<FormControl>
										<Checkbox
											checked={field.value}
											onCheckedChange={field.onChange}
											disabled={isFieldReadOnly(readOnlyFields, 'generatorSet')}
										/>
									</FormControl>
									<div className="space-y-1 leading-none">
										<FormLabel>
											{
												t(
													'pages.addshipment.manual.generator_set.label',
													'Generator set required'
												)!
											}
										</FormLabel>
									</div>
									<FormMessage />
								</FormItem>
							)}
						/>
					</div>
				)}
				{transportType === TransportTypeCode.SHUNT && (
					<div className="sm:col-span-full">
						<FormField
							control={form.control}
							name="containerStatus"
							render={({ field }) => (
								<FormItem>
									<FormLabel>
										{t(
											'pages.addshipment.manual.container_status.label',
											'Container status'
										)}
									</FormLabel>
									{isFieldReadOnly(readOnlyFields, 'containerStatus') ? (
										<FormFieldReadOnly
											value={ContainerStatusLabels[field.value]}
										/>
									) : (
										<FormControl>
											<RadioGroup
												{...field}
												onValueChange={(e) => {
													field.onChange(e);
													handleContainerStatusChange(e);
												}}
												value={field.value}
												className="flex flex-col space-y-1"
											>
												<FormItem className="flex items-center space-x-3 space-y-0">
													<FormControl>
														<RadioGroupItem value="full" />
													</FormControl>
													<FormLabel className="font-normal cursor-pointer">
														{t(
															'pages.addshipment.manual.container_status.full.label',
															'Full'
														)}
													</FormLabel>
												</FormItem>
												<FormItem className="flex items-center space-x-3 space-y-0">
													<FormControl>
														<RadioGroupItem value="empty" />
													</FormControl>
													<FormLabel className="font-normal cursor-pointer">
														{t(
															'pages.addshipment.manual.container_status.empty.label',
															'Empty'
														)}
													</FormLabel>
												</FormItem>
											</RadioGroup>
										</FormControl>
									)}
									<FormMessage />
								</FormItem>
							)}
						/>
					</div>
				)}
				<div className="sm:col-span-6">
					<FormField
						control={form.control}
						name="shippingLine.code"
						render={({ field }) => (
							<FormItem>
								<FormLabel>
									{t(
										'pages.shipment-details.container_details.shipping_line.label',
										'Shipping Line'
									)}
								</FormLabel>
								{isFieldReadOnly(readOnlyFields, 'shippingLine') ? (
									<FormFieldReadOnly value={field.value ?? ''} />
								) : (
									<FormControl>
										<PopoverSelect
											ref={field.ref}
											onChange={field.onChange}
											selected={field.value ?? ''}
											options={shippingLinesRaw.map((item) => {
												return {
													label: `${item.description!} (${item.code!})`,
													value: item.code!,
													raw: item,
												};
											})}
											onSelect={(item) => {
												const { id, code } = item;
												form.setValue('shippingLineId', id, shouldUpdate);
												form.setValue('shippingLine', item, shouldUpdate);
												handleContainerNumberValidation(
													form.getValues('equipment.containerNumber') as string,
													code
												);
											}}
											placeholder={
												t(
													'pages.shipment-details.container_details.shipping_line.placeholder',
													'Select shipping line'
												) as string
											}
											filter={{
												placeholder: t(
													'pages.shipment-details.container_details.shipping_line.search.placeholder',
													'Type to search shipping lines...'
												) as string,
												empty: t(
													'pages.shipment-details.container_details.shipping_line.search.empty',
													'No shipping line found.'
												) as string,
											}}
											nullable={true}
											position="top"
										/>
									</FormControl>
								)}
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<div className="sm:col-span-full">
					<FormField
						control={form.control}
						name="equipment.containerNumber"
						render={({ field }) => (
							<FormItem>
								<FormLabel className="flex items-center">
									{t(
										'pages.shipment-details.container_details.container_number.label',
										'Container number'
									)}
									<HiddenFieldLabelInfo />
								</FormLabel>
								{isFieldReadOnly(
									readOnlyFields,
									'equipment.containerNumber'
								) ? (
									<FormFieldReadOnly value={field.value ?? ''} />
								) : (
									<>
										<FormControl>
											<Input
												{...field}
												value={field.value}
												onChange={(e) =>
													field.onChange(e.target.value.toLocaleUpperCase())
												}
												onBlur={(e) => {
													form.clearErrors(field.name);

													if (
														form.formState.dirtyFields.equipment
															?.containerNumber
													) {
														handleContainerNumberBlur(
															e.target.value.toLocaleUpperCase()
														);
													}
												}}
												disabled={enableAddingGroup}
											/>
										</FormControl>
										{enableAddingGroup && (
											<FormDescription>
												<Icon icon={faInfoCircle} />{' '}
												{t(
													'pages.create_grouped_shipment.shipment_details.container_details.container_number.info',
													'Add container numbers after creation'
												)}
											</FormDescription>
										)}
									</>
								)}
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<div className="sm:col-span-full">
					<FormField
						control={form.control}
						name="seal"
						render={({ field }) => (
							<FormItem>
								<FormLabel className="flex items-center">
									{t(
										'pages.shipment-details.container_details.seal.label',
										'Seal'
									)}
									<HiddenFieldLabelInfo />
								</FormLabel>
								{isFieldReadOnly(readOnlyFields, 'seal') ? (
									<FormFieldReadOnly value={field.value ?? ''} />
								) : (
									<FormControl>
										<Input {...field} />
									</FormControl>
								)}
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				{!!tareWeight && (
					<div className="sm:col-span-full">
						<FormField
							control={form.control}
							name="tareWeight.quantity"
							render={({ field }) => (
								<FormItem className="relative">
									<FormLabel>
										{t(
											'pages.shipment-details.container_details.tare_weight.label',
											'Tare weight'
										)}
									</FormLabel>
									<FormFieldReadOnly
										value={`${field.value} ${t(
											'general.shorthands.units.kilogram',
											'kg'
										)}`}
									/>
								</FormItem>
							)}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
