import { ShipmentStatus } from '@uturn/api/v1';
import { Badge } from '@uturn/ui-kit';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ShipmentViewContext } from '../../providers/shipment-view';
import { QuotesSheet } from '../quotes-sheet/quotes-sheet';
import { type ShipmentProps } from './shipment-card.types';
import { CopyToClipboard } from '@uturn/portal/components';
import type { InfoRouteCardRouteProps } from '@uturn/portal/components/info-route-card';
import {
	InfoRouteCard,
	InfoRouteCardContent,
	InfoRouteCardHeader,
	InfoRouteCardLabel,
	InfoRouteCardRoutes,
} from '@uturn/portal/components/info-route-card';
import QuoteButton from '@uturn/portal/components/quote-button/quote-button';
import { ShipmentView } from '@uturn/portal/hooks/shipments/use-shipment-view';
import { getLocationCity } from '@uturn/portal/utils';
import { getRouteDate } from '@uturn/portal/utils/get-route-date';

export function ShipmentCard({
	shipmentNumber,
	shipmentId,
	shipmentStatus,
	shipmentReference,
	details,
	routes,
	quoteCount,
	parentQueryKey,
	parentLocation,
	hasPendingChange,
}: ShipmentProps) {
	const { t } = useTranslation();
	const view = useContext(ShipmentViewContext);
	const shipmentRoutes: InfoRouteCardRouteProps[] = routes.map(
		(route, index) => {
			let placement: 'end' | 'middle' | 'start' = 'middle';

			if (index === 0) {
				placement = 'start';
			} else if (index === routes.length - 1) {
				placement = 'end';
			}

			return {
				placement,
				name: getLocationCity(route.location),
				description:
					view !== ShipmentView.Compact && route.eta?.length ? (
						<div className="flex gap-2">
							{new Date(route.dateUntil!) < new Date(route.eta) ? (
								<Badge variant="destructive">
									{t('general.route-status.delayed', 'Delayed').toUpperCase()}
								</Badge>
							) : (
								<Badge>
									{t('general.route-status.on-time', 'On time').toUpperCase()}
								</Badge>
							)}
							<span>{getRouteDate(route.dateFrom, route.dateUntil)}</span>
						</div>
					) : (
						<div
							className={
								view === ShipmentView.Compact ? 'justify-self-end' : ''
							}
						>
							{getRouteDate(route.dateFrom, route.dateUntil)}
						</div>
					),
			};
		}
	) satisfies InfoRouteCardRouteProps[];

	const [openSheet, setOpenSheet] = useState(false);

	return (
		<>
			<InfoRouteCard>
				<InfoRouteCardHeader compact={view === ShipmentView.Compact}>
					<Link
						to={`/shipments/${shipmentId}`}
						state={{
							parentQueryKey,
							parentLocation,
						}}
						className='flex gap-3.5 font-light text-muted-foreground after:content-[" "] after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0'
					>
						<span className="z-10">
							{t('pages.shipments.details.title', 'Shipment ID - #{{ ID }}', {
								ID: shipmentNumber,
							})}
							<CopyToClipboard value={shipmentNumber.toString()} />
						</span>

						{view === ShipmentView.Compact && shipmentReference && (
							<span className="z-10">
								{t(
									'pages.shipments.details.reference',
									'Reference - #{{ ID }}',
									{
										ID: shipmentReference,
									}
								)}
								<CopyToClipboard value={shipmentReference.toString()} />
							</span>
						)}

						{shipmentStatus === ShipmentStatus.COMPLETED && (
							<Badge variant="default">
								{t(
									`general.shipment-status.${ShipmentStatus.COMPLETED}`,
									'Completed'
								)}
							</Badge>
						)}
						{shipmentStatus === ShipmentStatus.CANCELLED && (
							<Badge variant="secondary">
								{t(
									`general.shipment-status.${ShipmentStatus.CANCELLED}`,
									'Cancelled'
								)}
							</Badge>
						)}
						{hasPendingChange && (
							<Badge variant="destructive">
								{t('general.shipment-status.pending-change', 'Pending change')}
							</Badge>
						)}
						{shipmentStatus === ShipmentStatus.COST_DECLARED && (
							<Badge variant="destructive">
								{t(
									'general.shipment-status.pending-extra-cost',
									'Pending extra cost'
								)}
							</Badge>
						)}
					</Link>
					<span className="flex gap-3 z-10">
						{quoteCount !== undefined && (
							<QuoteButton
								parentQueryKey={parentQueryKey}
								quoteCount={quoteCount}
								shipmentNumber={shipmentNumber}
							/>
						)}
					</span>
				</InfoRouteCardHeader>
				<InfoRouteCardContent
					compact={view === ShipmentView.Compact}
					routes={
						<InfoRouteCardRoutes
							compact={view === ShipmentView.Compact}
							routes={shipmentRoutes}
						/>
					}
				>
					{details
						.filter(
							(detail) =>
								!(detail.hideOnCompact && view === ShipmentView.Compact)
						)
						.map((detail) => (
							<InfoRouteCardLabel
								key={detail.label}
								title={detail.label}
								clickable={detail.clickable ?? false}
							>
								{detail.value || '-'}
								{detail.enableCopyToClipboard && detail.value && (
									<CopyToClipboard value={detail.value?.toString() ?? ''} />
								)}
							</InfoRouteCardLabel>
						))}
				</InfoRouteCardContent>
			</InfoRouteCard>

			<QuotesSheet
				shipmentNumber={shipmentNumber}
				openSheet={openSheet}
				setOpenSheet={setOpenSheet}
			/>
		</>
	);
}
