import { useFetchLanes } from '@uturn/api/v1';
import { Lanes as LanesEmptyState } from '@uturn/design-tokens/empty-states';
import { EmptyState } from '@uturn/ui';
import { Button } from '@uturn/ui-kit';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
	InfoRouteCard,
	InfoRouteCardContent,
	InfoRouteCardLabel,
	InfoRouteCardRoutes,
} from '@uturn/portal/components/info-route-card';
import { MetaDataContext } from '@uturn/portal/context';
import { useLocationSuggestions } from '@uturn/portal/hooks/use-location-suggestions';
import { formatDate } from '@uturn/portal/utils';

export function LanesListEmptyState() {
	const { t } = useTranslation();
	return (
		<EmptyState>
			<LanesEmptyState className="md:h-[20rem]" />
			<h2 className="text-secondary-700 text-2xl font-medium leading-7">
				{t('pages.lanes.overview.empty-state.title', 'Lanes')}
			</h2>
			<p className="text-secondary-600 text-lg leading-6">
				{t(
					'pages.lanes.overview.empty-state.description',
					'Post lanes, get top carrier matches with rates from UTURN. We assist with shipment details as your start date approaches.'
				)}
			</p>
			<div>
				<Link to="./create">
					<Button>{t('pages.lanes.empty-state.action', 'Get started')}</Button>
				</Link>
			</div>
		</EmptyState>
	);
}

export function LanesList() {
	const { t } = useTranslation();
	const lanes = useFetchLanes({
		pageable: {},
	});
	const { shipmentUnits } = useContext(MetaDataContext);
	const { getLocationWithAddress } = useLocationSuggestions();

	return lanes.data?.data.totalElements === 0 ? (
		<LanesListEmptyState />
	) : (
		lanes.data?.data.data.map((lane) => (
			<Link key={lane.id} to={`./${lane.id}`}>
				<InfoRouteCard>
					<InfoRouteCardContent
						className="pt-6"
						routes={
							<InfoRouteCardRoutes
								routes={lane.route.map((route) => ({
									name: getLocationWithAddress(route.location),
								}))}
							/>
						}
					>
						<InfoRouteCardLabel
							title={t('pages.lanes.overview.card.lane-name', 'Lane name')}
						>
							{lane.reference || ''}
						</InfoRouteCardLabel>
						<InfoRouteCardLabel
							title={t(
								'pages.lanes.overview.card.container-type',
								'Container type'
							)}
						>
							{lane.shipmentUnit
								? shipmentUnits[lane.shipmentUnit].description
								: '-'}
						</InfoRouteCardLabel>
						<InfoRouteCardLabel
							title={t('pages.lanes.overview.card.lane-start', 'Lane start')}
						>
							{formatDate(lane.laneStartDate) ?? '-'}
						</InfoRouteCardLabel>
						<InfoRouteCardLabel
							title={t('pages.lanes.overview.card.lane-end', 'Lane end')}
						>
							{formatDate(lane.laneEndDate) ?? '-'}
						</InfoRouteCardLabel>
					</InfoRouteCardContent>
				</InfoRouteCard>
			</Link>
		))
	);
}
