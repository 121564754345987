import { theme } from '@uturn/ui';
import type { Variants } from 'framer-motion';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

const variants: Variants = {
	initial: {
		width: '1rem',
		border: `0.12rem solid ${theme.extend.colors.secondary[200]}`,
		transition: {
			duration: 0.2,
		},
	},
	selected: {
		width: '1rem',
		border: `0.33rem solid ${theme.extend.colors.primary[500]}`,
		transition: {
			duration: 0.2,
		},
	},
};

export const CardRadioButton = ({
	value,
	heading,
	body,
	recommended,
	selected,
	setSelected,
}: {
	heading: string;
	body: React.ReactNode;
	recommended?: boolean;
	value: string;
	selected: string;
	setSelected: (value: string) => void;
}) => {
	const { t } = useTranslation();
	const isSelected = selected === value;

	return (
		<li
			className={twMerge(
				'text-sm duration-400 w-full relative flex cursor-pointer items-start gap-2 p-4 font-medium transition-all focus:outline-none rounded-lg border bg-card text-card-foreground shadow-sm',
				isSelected && 'border-primary'
			)}
			id="unpublish"
			// eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
			role="radio"
			aria-checked={isSelected}
			onClick={() => {
				setSelected(value);
			}}
			onKeyDown={(event) =>
				event.code === 'Enter' ? setSelected('unpublish') : null
			}
		>
			<div className="mt-0.5 h-4 w-4 rounded-full">
				<motion.div
					className="h-full w-full rounded-full"
					variants={variants}
					initial="initial"
					animate={isSelected ? 'selected' : 'initial'}
				/>
			</div>
			<div className="flex flex-col text-left gap-1">
				<p>
					<span className="font-semibold">{heading}</span>
					{recommended && (
						<span className="text-xs text-muted-foreground ml-1.5">
							{t('general.recommended', '(recommended)')}
						</span>
					)}
				</p>
				{body}
			</div>
		</li>
	);
};
