import { type ShipmentListDTO, useGetGroupById } from '@uturn/api/v1';
import { type GetShipmentsParams, useGetShipments } from '@uturn/api/v2';
import { PaginationCompact } from '@uturn/ui-kit';
import { AnimatePresence, motion } from 'framer-motion';
import { Suspense, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ShipmentGroupDetailContext } from '../providers/shipment-group-detail';
import { ShipmentViewContext } from '../providers/shipment-view';
import { ShipmentFilter } from './filters';
// eslint-disable-next-line import/no-cycle
import { ShipmentCard } from './shipment-card';
import { ShipmentSkeletonLoader } from './shipment-skeleton';
import ShipmentsTable from './shipments-table';
import { ShipmentView } from '@uturn/portal/hooks/shipments/use-shipment-view';
import { useShipmentsSearchStore } from '@uturn/portal/store/shipments/shipments-search';
import { defaultStatusFilters } from '@uturn/portal/types/shipments';

function Shipments({
	shipmentsParams,
	details,
	EmptyState,
	hasQuotes,
}: {
	shipmentsParams: GetShipmentsParams;
	details: ShipmentPageProps['details'];
	EmptyState: React.FC;
	hasQuotes?: boolean;
}) {
	const location = useLocation();
	const { groupId, setGroupName } = useContext(ShipmentGroupDetailContext);
	const { setTotalShipments } = useShipmentsSearchStore();

	const [page, setPage] = useState(0);

	const { data: ungroupedShipments, queryKey: shipmentsQueryKey } =
		useGetShipments(
			{
				...shipmentsParams,
				page: page.toString(),
			},
			{
				query: {
					enabled: groupId === '',
				},
			}
		);

	const { data: groupedShipments, queryKey: groupedShipmentsQueryKey } =
		useGetGroupById(
			Number(groupId),
			{
				...shipmentsParams,
				page: page.toString(),
			},
			{
				query: {
					enabled: groupId !== '',
				},
			}
		);

	useEffect(() => {
		if (groupedShipments?.data) {
			setGroupName(groupedShipments.data.name ?? '');
		}
	}, [groupedShipments?.data]);

	const shipments =
		groupId !== ''
			? groupedShipments!.data.shipments
			: ungroupedShipments!.data;
	const queryKey =
		groupId !== '' ? groupedShipmentsQueryKey : shipmentsQueryKey;

	const totalPages = useMemo(() => {
		const total = (shipments?.totalElements ?? 0) || 1;
		const size = (shipments?.pageSize ?? 0) || 1;
		return Math.ceil(total / size);
	}, []);

	useEffect(() => {
		if (!shipments?.totalElements) {
			setTotalShipments(0);
			return;
		}
		setTotalShipments(shipments.totalElements);
	}, [shipments?.totalElements]);

	if (!shipments?.totalElements) {
		return <EmptyState />;
	}

	return (
		<>
			{shipments!.data?.map((shipment: ShipmentListDTO) => {
				return (
					<ShipmentCard
						key={shipment.shipmentNumber}
						shipmentNumber={shipment.shipmentNumber!}
						shipmentId={shipment.shipmentNumber!}
						shipmentStatus={shipment.status!}
						shipmentReference={shipment.shipperReference ?? undefined}
						details={details(shipment)}
						hasPendingChange={shipment.pendingChangeRequest ?? false}
						quoteCount={hasQuotes ? shipment.quoteCount ?? 0 : undefined}
						routes={
							shipment.locationActions?.sort(
								(a, b) => a.sequenceNumber - b.sequenceNumber
							) ?? []
						}
						parentQueryKey={queryKey}
						parentLocation={location as any}
					/>
				);
			})}
			<PaginationCompact
				page={page}
				setPage={setPage}
				totalPages={totalPages}
			/>
		</>
	);
}

export function useFilterParams() {
	const [searchParams, setSearchParams] = useSearchParams();

	const filters: Partial<GetShipmentsParams> = {};
	searchParams.forEach((value, key) => {
		filters[key] = value.indexOf(',') !== -1 ? value.split(',') : value;
	});

	return [filters, searchParams, setSearchParams];
}

export type ShipmentPageProps = {
	EmptyState: React.FC;
	details: (
		shipment: any
	) => React.ComponentProps<typeof ShipmentCard>['details'];
	hasQuotes?: boolean;
	enableGroupFilter: boolean;
	/**
	 * @deprecated
	 */
	enableTsoFilter?: boolean;
};

export function ShipmentPage({
	EmptyState,
	details,
	hasQuotes,
	enableGroupFilter,
	enableTsoFilter = false,
}: ShipmentPageProps) {
	const { t } = useTranslation();
	const [parsedSearchParams, , setSearchParams] = useFilterParams();
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const view = useContext(ShipmentViewContext);

	const { activeStatusTab } = useShipmentsSearchStore();
	const params: GetShipmentsParams = defaultStatusFilters(t, activeStatusTab);

	const [statusFilter, setStatusFilter] = useState<GetShipmentsParams>({
		...params,
		...parsedSearchParams,
	} as GetShipmentsParams);

	if (view === ShipmentView.Table) {
		return (
			<ShipmentsTable
				filterStatuses={params['filter.statuses']}
				/**
				 * In the shipments table we don't support the tab "groups"
				 * so we just set the status tab to undefined to let the component handle the default use case
				 */
				statusTab={activeStatusTab !== 'groups' ? activeStatusTab : undefined}
			/>
		);
	}

	return (
		<div className="grid grid-cols-1 @4xl/content:grid-cols-[minmax(0,_1fr)_minmax(0,_3fr)] gap-6 pt-2">
			<ShipmentFilter
				enableGroupFilter={enableGroupFilter}
				enableTsoFilter={enableTsoFilter}
				initialFilters={parsedSearchParams as Partial<GetShipmentsParams>}
				onChange={(filters) => {
					if (Object.keys(filters || {}).length === 0) {
						navigate(`/redirect${pathname}`);
					} else {
						const mappedSearchParams: [string, string | string[]][] = [];

						// eslint-disable-next-line no-restricted-syntax
						for (const [key, value] of Object.entries(filters || {})) {
							if (
								value === undefined ||
								value === null ||
								(Array.isArray(value) && value.length === 0)
							) {
								continue;
							}

							mappedSearchParams.push([key, value]);
						}

						setSearchParams(mappedSearchParams);

						const filterStatuses =
							!filters?.['filter.statuses'] ||
							filters['filter.statuses'].length === 0
								? params['filter.statuses']
								: filters['filter.statuses'];

						const newStatusFilter = {
							...params,
							...filters,
							'filter.statuses': filterStatuses,
						};

						setStatusFilter(newStatusFilter);
					}
				}}
			/>
			<AnimatePresence mode="wait">
				<motion.div
					initial={{ opacity: 0, translateY: 15 }}
					animate={{ opacity: 1, translateY: 0 }}
					exit={{ opacity: 0, translateY: 15 }}
					transition={{ duration: 0.3, ease: 'easeInOut' }}
					className="flex flex-col gap-3"
				>
					<Suspense fallback={<ShipmentSkeletonLoader />}>
						<Shipments
							details={details}
							shipmentsParams={statusFilter}
							EmptyState={EmptyState}
							hasQuotes={hasQuotes}
						/>
					</Suspense>
				</motion.div>
			</AnimatePresence>
		</div>
	);
}
