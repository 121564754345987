import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { type UserLogin, authenticate } from '@uturn/api';
import {
	getGetAllShipmentMetaDataQueryKey,
	getMetaDataQueryKey,
	metaData,
	UserRole,
} from '@uturn/api/v1';

import {
	Button,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Icon,
	Input,
	cn,
	useToast,
} from '@uturn/ui-kit';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { queryClient } from '@uturn/portal/api';

const FORBIDDEN_ROLES = [UserRole.G_CARRIER, UserRole.C_USER, UserRole.C_ADMIN, UserRole.U_ADMIN, UserRole.C_DRIVER] as unknown as UserRole[];

export function SignInForm() {
	const navigate = useNavigate();
	const { toast } = useToast();
	const location = useLocation();
	const { t } = useTranslation();
	const form = useForm<UserLogin>({
		defaultValues: {
			username: '',
			password: '',
		},
	});

	const onSubmit = async (data: UserLogin) => {
		try {
			await authenticate(data); 
			const metadata = await metaData();

			if (metadata.data.user?.role && FORBIDDEN_ROLES.includes(metadata.data.user.role)) {
				// Redirect to old shipper platform if the user has incorrect roles
				window.location.replace(import.meta.env.VITE_CARRIER_PORTAL_URL);
				return;
			} 

			if (location?.state?.referrer) {
				// Force refetch of useMetaData, useGetAllShipmentMetaData
				// after authentication in AppLayout.
				queryClient.invalidateQueries({
					queryKey: [
						getMetaDataQueryKey(),
						getGetAllShipmentMetaDataQueryKey(),
					],
				});
				navigate(location.state.referrer);
				return;
			}
			navigate('/shipments');
		} catch (e) {
			toast({
				title: t(
					'pages.sign-in.form.submit.error.title',
					'Uh oh! You are not who you say you are.'
				)!,
				description: t(
					'pages.sign-in.form.submit.error.description',
					'Please check your credentials and try again.'
				),
			});
		}
	};

	return (
		<div className={cn('grid gap-6')}>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)}>
					<div className="grid gap-2">
						<div className="grid gap-1">
							<FormField
								control={form.control}
								name="username"
								defaultValue={location?.state?.email}
								render={({ field }) => (
									<FormItem>
										<FormLabel className="sr-only">
											{t('pages.sign-in.form.username.label', 'Email')}
										</FormLabel>
										<FormControl>
											<Input
												type="email"
												autoComplete="email"
												autoCorrect="off"
												disabled={form.formState.isSubmitting}
												placeholder={
													t(
														'pages.sign-in.form.username.label',
														'john_doe@example.com'
													)!
												}
												{...field}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<div className="grid gap-1">
							<FormField
								control={form.control}
								name="password"
								render={({ field }) => (
									<FormItem>
										<FormLabel className="sr-only">
											{t('pages.sign-in.form.password.label', 'Password')}
										</FormLabel>
										<FormControl>
											<Input
												type="password"
												autoComplete="password"
												autoCorrect="off"
												disabled={form.formState.isSubmitting}
												placeholder={
													t(
														'pages.sign-in.form.password.placeholder',
														'Password'
													)!
												}
												{...field}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<Button type="submit" disabled={form.formState.isSubmitting}>
							{form.formState.isSubmitting && (
								<Icon spin className="mr-3" icon={faSpinnerThird} />
							)}
							{t(
								'pages.auth.sign-in.form.submit',
								t('pages.sign-in.sign-in', 'Sign In')
							)}
						</Button>
					</div>
				</form>
			</Form>
		</div>
	);
}
