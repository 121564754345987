import { pathIsShipmentNumber } from ".";

export function translateLegacyRoute(pathname: string): string | null {
	if (pathname === '/dashboard/manage-shipments') {
		return '/shipments';
	}

	if (pathname.includes('/dashboard/manage-shipments/')) {
		const id = pathname.split('/dashboard/manage-shipments/')[1];
		return `/shipments/${id.split('/')[0]}`;
	}

	if (pathname === '/dashboard/assigned-shipments') {
		return '/shipments/assigned';
	}

	if (pathname.includes('/dashboard/assigned-shipments/')) {
		const id = pathname.split('/dashboard/assigned-shipments/')[1];
		return `/shipments/${id.split('/')[0]}`;
	}

	if (pathname === '/dashboard/profile') {
		return '/settings/organisation';
	}

	if (pathIsShipmentNumber(pathname)) {
		return `/shipments/${pathname.replace('/', '')}`;
	}

	return null;
}
