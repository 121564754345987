/**
 *
 * Single validation result
 *
 */
export type ValidationResult = {
	valid: boolean;
	message: string;
	path: string;
};

export const validationError = (
	message: string,
	path: string
): ValidationResult => {
	return {
		valid: false,
		message,
		path,
	};
};

export const validationSuccess: ValidationResult = {
	valid: true,
	message: '',
	path: '',
};

/**
 *
 * Cummulative validations result
 *
 */
export type ValidationsResult = {
	valid: boolean;
	messages: string[];
	paths: string[];
};

export const validationsError = (
	validationResults: ValidationsResult,
	message: string,
	path: string
): ValidationsResult => {
	if (validationResults.valid) validationResults.valid = false;
	if (!validationResults.paths.includes(path)) {
		validationResults.messages.push(message);
		validationResults.paths.push(path);
	}
	return validationResults;
};

export const newValidationsResult = (): ValidationsResult => {
	return {
		valid: true,
		messages: [],
		paths: [],
	};
};
