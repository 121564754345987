import {
	type ShareShipmentEventDetails,
	ShareShipmentEventDetailsShareType,
	useRegisterShareShipmentEvent,
} from '@uturn/api/v1';
import {
	Button,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	Input,
	Sheet,
	SheetContent,
	SheetDescription,
	SheetHeader,
	SheetTitle,
	Textarea,
	useToast,
} from '@uturn/ui-kit';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from '@uturn/portal/components';

type ShareLinkFormInputs = {
	contactName: string;
	contactEmail: string;
	message: string;
};

export function ShareShipmentSheet({
	shipmentNumber = 0,
	openSheet,
	setOpenSheet,
}: {
	shipmentNumber: number;
	openSheet: boolean;
	setOpenSheet: (open: boolean) => void;
}) {
	const form = useForm<ShareLinkFormInputs>();
	const formRef = useRef<HTMLFormElement>(null);
	const { t } = useTranslation();
	const shipmentUrl = `${window.location.origin}/shipments/${shipmentNumber}`;

	const shareShipment = useRegisterShareShipmentEvent();

	const { toast } = useToast();

	const onSubmit = (data: any) => {
		const toSendData: ShareShipmentEventDetails = {
			contactName: data.contactName,
			contactEmail: data.contactEmail,
			message: data.message,
			shipmentNumber,
			shareType: ShareShipmentEventDetailsShareType.EMAIL,
		};

		shareShipment.mutate(
			{ data: toSendData },
			{
				onSuccess: () => {
					setOpenSheet(false);
				},
				onError: () => {
					toast({
						title: t('toast.error-title', 'Uh oh! Something went wrong.')!,
						description: t(
							'pages.shipment-detail.modal.share-shipment.submit.error.description',
							'We were unable to share your shipment. Please try again later.'
						),
					});
				},
			}
		);
	};

	const handleShareShipment = () => {
		if (formRef.current === null) return;

		formRef.current.dispatchEvent(
			new Event('submit', { cancelable: true, bubbles: true })
		);
	};

	const forwardSave = (data: any) => {
		onSubmit(data);
	};

	const handleSubmitWithoutPropagation = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		form.handleSubmit(forwardSave)(e);
	};

	return (
		<Sheet open={openSheet} onOpenChange={setOpenSheet}>
			<SheetContent>
				<SheetHeader>
					<SheetTitle>
						{t(
							'pages.shipment-detail.modal.share-shipment.heading',
							'Share shipment'
						)}
					</SheetTitle>
					<SheetDescription>
						{t(
							'pages.shipment-detail.modal.share-shipment.subheading',
							'You can share your shipment internally with colleagues or externally with your network of shippers or carriers.'
						)}
					</SheetDescription>
				</SheetHeader>
				<div className="grid gap-5 pt-5">
					<div className="flex flex-col">
						<h3 className="text font-heading font-medium tracking-tight">
							{t(
								'pages.shipment-detail.modal.share-shipment.copy-url.heading',
								'Copy url'
							)}
						</h3>
						<span className="text-sm">
							{shipmentUrl}
							<CopyToClipboard value={shipmentUrl} />
						</span>
					</div>
					<div className="flex flex-col gap-3">
						<h3 className="text font-heading font-medium tracking-tight">
							{t(
								'pages.shipment-detail.modal.share-shipment.send-link.heading',
								'Share via email'
							)}
						</h3>
						<Form {...form}>
							<form
								onSubmit={handleSubmitWithoutPropagation}
								className="space-y-4 grid grid-cols-1 col-span-3 sm:grid-cols-6"
								ref={formRef}
							>
								<FormField
									control={form.control}
									name="contactName"
									render={({ field }) => (
										<FormItem className="sm:col-span-full">
											<FormLabel>
												{t(
													'pages.shipment-detail.modal.share-shipment.send-link.first-name',
													'Contact first name'
												)}
											</FormLabel>
											<FormControl>
												<Input {...field} required />
											</FormControl>
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="contactEmail"
									render={({ field }) => (
										<FormItem className="sm:col-span-full">
											<FormLabel>
												{t(
													'pages.shipment-detail.modal.share-shipment.send-link.email',
													'Contact email'
												)}
											</FormLabel>
											<FormControl>
												<Input {...field} required />
											</FormControl>
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="message"
									render={({ field }) => (
										<FormItem className="sm:col-span-full">
											<FormLabel>
												{t(
													'pages.shipment-detail.modal.share-shipment.send-link.message',
													'Your message'
												)}
											</FormLabel>
											<FormControl>
												<Textarea {...field} required />
											</FormControl>
										</FormItem>
									)}
								/>

								<div className="sm:col-span-full flex justify-end">
									<Button onClick={handleShareShipment} size="sm">
										{t(
											'pages.shipment-detail.modal.share-shipment.send-link.submit',
											'Send email'
										)}
									</Button>
								</div>
							</form>
						</Form>
					</div>
				</div>
			</SheetContent>
		</Sheet>
	);
}
