import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@uturn/ui-kit';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Financials } from './financials';
import { LoadingSpan } from '@uturn/portal/components';
import { ErrorBoundary } from 'react-error-boundary';

export function FinancialsSheet({
	shipmentNumber,
	openSheet,
	setOpenSheet,
}: {
	openSheet: boolean;
	setOpenSheet: (open: boolean) => void;
	shipmentNumber: string;
}) {
	const { t } = useTranslation();

	return (
		<Sheet open={openSheet} onOpenChange={setOpenSheet}>
			<SheetContent>
				<SheetHeader>
					<SheetTitle>
						<h2 className="text-2xl font-bold text-secondary-800 mb-2">
							{t('pages.shipment-detail.sheet.financials.title', 'Financials')}
						</h2>
					</SheetTitle>
				</SheetHeader>
				<Financials shipmentId={shipmentNumber} />
			</SheetContent>
		</Sheet>
	);
}
