import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import {
	type DeleteGroupByIdParams,
	type ShipmentStatusCount,
	ShipmentStatusCountShipmentStatus,
} from '@uturn/api/v1';
import type { GetShipmentsFilterStatusesItem } from '@uturn/api/v2';
import {
	Button,
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
	Icon,
} from '@uturn/ui-kit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
	CancelShipmentsException,
	ShipmentsCancelDropdownMenuItem,
	ShipmentsCancelModal,
} from '../shipments-cancel';
import {
	ShipmentsExportDropdownMenuItem,
	ShipmentsExportModal,
} from '../shipments-export';
import { RenameShipmentGroupModal } from './rename-shipment-group-modal';
import type { ShipmentGroupProps } from './shipment-group-card.types';
import { ShipmentGroupStatusTile } from './shipment-group-status-tile';

export function ShipmentGroupCard({
	id,
	name,
	shipmentStatusCount,
	groupsQueryKey,
	onClick,
}: ShipmentGroupProps) {
	const { t } = useTranslation();
	const shipmentStatuses = [
		ShipmentStatusCountShipmentStatus.CONCEPT,
		ShipmentStatusCountShipmentStatus.PUBLISHED,
		ShipmentStatusCountShipmentStatus.QUOTED,
		ShipmentStatusCountShipmentStatus.CARRIER_ASSIGNED,
		'Archived',
	];

	const shipmentStatusFilter = {
		[ShipmentStatusCountShipmentStatus.CONCEPT]: '/drafted',
		[ShipmentStatusCountShipmentStatus.PUBLISHED]: '',
		[ShipmentStatusCountShipmentStatus.QUOTED]: '?filter.statuses=QUOTED',
		[ShipmentStatusCountShipmentStatus.CARRIER_ASSIGNED]: '/assigned',
		Archived: '/archived',
	};

	const totalShipments = structuredClone(shipmentStatusCount).reduce(
		(accumulator, shipmentStatus) => accumulator + (shipmentStatus.count ?? 0),
		0
	);

	const [openRenameModal, setOpenRenameModal] = useState(false);
	const [openExportModal, setOpenExportModal] = useState(false);
	const [openCancelModal, setOpenCancelModal] = useState(false);

	const navigate = useNavigate();

	const handleCancelShipmentsExceptions = ():
		| CancelShipmentsException
		| undefined => {
		if ((totalShipments ?? 0) === 0) {
			return CancelShipmentsException.NO_SHIPMENTS;
		}
		return undefined;
	};

	return (
		<>
			<Card className="relative flex flex-col h-min cursor-pointer hover:shadow-md transition-shadow duration-300 dark:hover:bg-muted/20">
				<CardHeader className="flex flex-row justify-between items-start space-y-0">
					<Link
						to={`/shipments/groups/${id}`}
						className='flex flex-col gap-1 after:content-[" "] after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0'
						onClick={(e) => {
							const link = `/shipments/groups/${id}`;
							e.preventDefault();
							onClick?.();

							if (e.ctrlKey || e.metaKey) {
								window.open(link, '_blank');
								return;
							}

							navigate(link);
						}}
					>
						<CardTitle>{name}</CardTitle>
						<CardDescription>
							{t('pages.shipments.groups.overview.card.total-shipments', {
								defaultValue: '{{count}} shipments',
								count: totalShipments ?? 0,
							})}
						</CardDescription>
					</Link>

					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button variant="secondary" size="icon" className="z-10">
								<Icon icon={faEllipsisVertical} />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="end">
							<DropdownMenuItem onClick={() => setOpenRenameModal(true)}>
								{t(
									'pages.shipments.groups.overview.card.menu.rename-group',
									'Rename'
								)}
							</DropdownMenuItem>
							<ShipmentsExportDropdownMenuItem
								setOpenModal={setOpenExportModal}
							/>
							<ShipmentsCancelDropdownMenuItem
								setOpenModal={setOpenCancelModal}
								cancelShipmentsException={handleCancelShipmentsExceptions()}
							/>
						</DropdownMenuContent>
					</DropdownMenu>
				</CardHeader>

				<CardContent className="flex flex-wrap gap-2">
					{shipmentStatuses.map((shipmentStatus, index) => {
						let status: ShipmentStatusCount = {
							shipmentStatus,
							count: 0,
						};

						if (shipmentStatus === 'Archived') {
							let cancelledStatusCount = 0;
							let executedStatusCount = 0;
							let completedStatusCount = 0;

							shipmentStatusCount.forEach(
								(statusCount: ShipmentStatusCount) => {
									switch (statusCount.shipmentStatus) {
										case ShipmentStatusCountShipmentStatus.CANCELLED:
											cancelledStatusCount = statusCount.count;
											break;
										case ShipmentStatusCountShipmentStatus.EXECUTED:
											executedStatusCount = statusCount.count;
											break;
										case ShipmentStatusCountShipmentStatus.COMPLETED:
											completedStatusCount = statusCount.count;
											break;
										default:
											break;
									}
								}
							);

							status = {
								shipmentStatus: 'Archived',
								count:
									cancelledStatusCount +
									executedStatusCount +
									completedStatusCount,
							};
						} else {
							status =
								shipmentStatusCount.find(
									(statusCount) => shipmentStatus === statusCount.shipmentStatus
								) ?? status;
						}

						return (
							<Link
								className="z-10"
								to={`/shipments/groups/${id}${
									shipmentStatusFilter[status.shipmentStatus]
								}`}
								key={index}
								onClick={(e) => {
									const link = `/shipments/groups/${id}${
										shipmentStatusFilter[status.shipmentStatus]
									}`;
									e.preventDefault();
									onClick?.();

									if (e.ctrlKey || e.metaKey) {
										window.open(link, '_blank');
										return;
									}

									navigate(link);
								}}
							>
								<ShipmentGroupStatusTile shipmentStatusCount={status} />
							</Link>
						);
					})}
				</CardContent>
			</Card>
			<RenameShipmentGroupModal
				group={{ id, name }}
				groupsQueryKey={groupsQueryKey}
				openModal={openRenameModal}
				setOpenModal={setOpenRenameModal}
			/>
			<ShipmentsExportModal
				exportFilters={{ 'filter.groups': [id] }}
				isOpenModal={openExportModal}
				setOpenModal={setOpenExportModal}
				totalShipments={totalShipments}
			/>
			<ShipmentsCancelModal
				groupId={id}
				exportFilters={{} as DeleteGroupByIdParams}
				statuses={[] as GetShipmentsFilterStatusesItem[]}
				isOpenModal={openCancelModal}
				setOpenModal={setOpenCancelModal}
				totalGroupShipments={totalShipments ?? 0}
			/>
		</>
	);
}
