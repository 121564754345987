import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import type { GetShipmentsFilterStatusesItem as ShipmentStatus } from '@uturn/api/v2';
import {
	AccordionContent,
	AccordionTrigger,
	Badge,
	Checkbox,
	Icon,
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@uturn/ui-kit';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ShipmentGroupDetailContext } from '../../providers/shipment-group-detail';
import { FilterContext } from './context';
import { useFacetSearch } from '@uturn/portal/hooks/queries/meilisearch';
import { useShipmentsSearchStore } from '@uturn/portal/store/shipments/shipments-search';
import { statusFilters } from '@uturn/portal/types/shipments';
import { isSelected } from '@uturn/portal/utils';

function StatusCount({ count }: { count?: number }) {
	if (count === undefined) {
		return (
			<Icon
				className="ml-2 h-4 w-4 text-muted-foreground"
				icon={faSpinnerThird}
				spin
			/>
		);
	}
	if (count === 0) {
		return null;
	}
	return (
		<Badge variant="outline" className="ml-2 text-[10px] align-middle">
			{count < 100 ? count : '99+'}
		</Badge>
	);
}

export function StatusFilter() {
	const { t } = useTranslation();
	const { filters, setFilters } = useContext(FilterContext);
	const { groupId } = useContext(ShipmentGroupDetailContext);
	const { activeStatusTab } = useShipmentsSearchStore();

	let tabStatusFilters = statusFilters(t).filter(
		(item) => item.navTab === activeStatusTab
	);

	const facetSearch = useFacetSearch('shipmentStatus', groupId);
	tabStatusFilters = tabStatusFilters.map((item) => ({
		...item,
		count:
			facetSearch?.data?.facetHits.find((e) => e.value === String(item.value))
				?.count ?? 0,
	}));

	const statuses =
		typeof filters['filter.statuses'] === 'string'
			? [filters['filter.statuses']]
			: filters['filter.statuses'] ?? [];

	const handleOnChange = (selectedStatus: ShipmentStatus, select: boolean) => {
		if (select) {
			setFilters({ 'filter.statuses': [...statuses, selectedStatus] });
		} else {
			setFilters({
				'filter.statuses': statuses.filter((s) => s !== selectedStatus),
			});
		}
	};

	if (!tabStatusFilters.length) {
		return null;
	}

	return (
		<>
			<AccordionTrigger>
				{t('pages.shipments.filters.status.title', 'Status')}
			</AccordionTrigger>
			<AccordionContent>
				<div className="grid gap-1">
					{tabStatusFilters.map((item, index) => (
						<TooltipProvider key={`${item.value}-${index}`}>
							<Tooltip>
								<TooltipTrigger>
									<div key={item.value} className="flex items-center space-x-2">
										<Checkbox
											id={item.value}
											checked={isSelected(item.value, statuses)}
											onCheckedChange={(e: boolean) =>
												handleOnChange(item.value, e)
											}
										/>
										<label
											htmlFor={item.value}
											className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer"
										>
											{
												item.label // NOTE: Already translated in statusFilters
											}
											<StatusCount count={item.count} />
										</label>
									</div>
								</TooltipTrigger>
								<TooltipContent>
									<p>{`${t('general.total', 'Total')}: ${
										item.count ??
										t('general.loading', 'Loading...').toLowerCase()
									}`}</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					))}
				</div>
			</AccordionContent>
		</>
	);
}
