import {
	AdditionalRequirement,
	type AddressDTO,
	ContainerSizeEnum,
	PriceCalculatorTransportType,
	type PriceRequestDto,
	useSavePriceRequest,
} from '@uturn/api/v1';
import { Logo } from '@uturn/ui';

import { AnimatePresence } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Page } from '@uturn/portal/components';
import {
	PriceCalculatorResultById,
	PriceRequestForm,
	PriceRequestLeadForm,
	RouteMap,
} from '@uturn/portal/modules/price-calculator/components';
import { PriceCalculatorContext } from '@uturn/portal/modules/price-calculator/context/price-calculator-context';

const defaultValues: PriceRequestDto = {
	transportType: PriceCalculatorTransportType.IMPORT,
	containerSize: ContainerSizeEnum.UNKNOWN,
	additionalRequirement: AdditionalRequirement.NONE,
	locations: [{}, {}, {}],
	email: '',
};

export function PublicPriceCalculator() {
	const { t } = useTranslation();
	const { id } = useParams();
	const { mutate, isSuccess } = useSavePriceRequest();

	useEffect(() => {
		if (isSuccess) {
			window.location.replace(
				'https://uturn-now.com/en/price-calculator-thank-you'
			);
		}
	}, [isSuccess]);

	const [locations, setLocations] = useState<AddressDTO[]>([]);

	const priceRequest = useRef<PriceRequestDto>(defaultValues);

	const [openLeadModal, setOpenLeadModal] = useState(false);

	const onEmailSubmit = (email: string) => {
		const data: PriceRequestDto = {
			...priceRequest.current,
			locations: locations.map((location: any) => ({
				id: location.id,
				...(location.googlePlaceId && {
					googlePlaceId: location.googlePlaceId,
				}),
			})),
			email,
		};

		mutate({ data });
	};

	const onFormSubmit = (request: PriceRequestDto) => {
		priceRequest.current = request;

		setOpenLeadModal(true);
	};

	// const onRequestReset = () => {
	// 	priceRequest.current = defaultValues;
	// 	setLocations([]);
	// };

	return (
		<>
			<Helmet title={t('pages.price-calculator.title', 'Price Calculator')!} />
			<div className="2xl:max-w-[90%] mx-auto py-8 sm:py-10 px-4 sm:px-6 lg:p-10">
				<Logo className="h-10 mb-7" />
				<Page title={t('pages.price-calculator.title', 'Price Calculator')!}>
					<PriceCalculatorContext.Provider
						value={{
							locations,
							setLocations: (locations) => {
								setLocations(locations);
							},
						}}
					>
						<div className="grid grid-cols-[minmax(0,3fr),_minmax(0,2fr)] gap-2.5 relative">
							<div className="flex w-full flex-col gap-8">
								<AnimatePresence mode="wait">
									{id === undefined ? (
										<PriceRequestForm
											defaultValues={defaultValues}
											onSubmit={onFormSubmit}
											onTripChange={setLocations}
										/>
									) : (
										<PriceCalculatorResultById />
									)}
								</AnimatePresence>
							</div>
							<div className="relative h-full">
								<RouteMap
									locations={locations.map((location) => ({
										lat: location.latitude!,
										lng: location.longitude!,
									}))}
								/>
							</div>
						</div>
					</PriceCalculatorContext.Provider>
					<PriceRequestLeadForm
						openModal={openLeadModal}
						setOpenModal={setOpenLeadModal}
						onSubmit={onEmailSubmit}
					/>
				</Page>
			</div>
		</>
	);
}
