/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
import { TransportTypeEnum } from '@uturn/api/v1';
import {
	AutoComplete,
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	RadioGroup,
	RadioGroupItem,
} from '@uturn/ui-kit';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocationSuggestions } from '@uturn/portal/hooks/use-location-suggestions';

export function RouteForm({ disabled }: { disabled?: boolean }) {
	const form = useFormContext();
	const { fields, remove, insert } = useFieldArray({
		name: 'route',
	});
	const { locations, getLocations, getAddress } = useLocationSuggestions();
	const { t } = useTranslation();

	return (
		<Card>
			<CardHeader>
				<CardTitle>
					{t('pages.lanes.create.form.route.title', 'Route')}
				</CardTitle>
			</CardHeader>
			<CardContent className="space-y-3">
				<FormField
					control={form.control}
					name="transportType"
					render={({ field }) => (
						<FormItem>
							<FormControl>
								<RadioGroup
									onValueChange={(newValue) => {
										remove();

										if (newValue === TransportTypeEnum.IMPORT) {
											insert(0, [
												{
													locationType: 'PICKUP',
												},
												{
													locationType: 'DELIVER',
												},
												{
													locationType: 'DROP',
												},
											]);
										} else if (newValue === TransportTypeEnum.EXPORT) {
											insert(0, [
												{
													locationType: 'PICKUP',
												},
												{
													locationType: 'LOAD',
												},
												{
													locationType: 'DROP',
												},
											]);
										} else {
											insert(0, [
												{
													locationType: 'PICKUP',
												},
												{
													locationType: 'DROP',
												},
											]);
										}

										field.onChange(newValue);
									}}
									value={field.value}
									className="grid grid-cols-3 gap-4 capitalize"
								>
									{Object.keys(TransportTypeEnum).map((type: any) => (
										<FormItem key={type}>
											<FormControl>
												<RadioGroupItem
													value={type}
													id={type}
													className="peer sr-only"
												/>
											</FormControl>
											<FormLabel
												htmlFor={type}
												className="cursor-pointer flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover px-4 py-3 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary peer-data-[state=checked]:text-primary [&:has([data-state=checked])]:text-primary"
											>
												{
													// TODO: check if this works
													t(
														`general.transport_type.${type.toLowerCase()}`,
														type.toLowerCase()
													).toString()
												}
											</FormLabel>
										</FormItem>
									))}
								</RadioGroup>
							</FormControl>
						</FormItem>
					)}
				/>
				{fields.map((fields, index) => (
					<FormField
						key={fields.id}
						control={form.control}
						name={`route.${index}.locationId`}
						disabled={disabled}
						render={({ field }) => (
							<FormItem>
								<FormLabel>
									{/* TODO: check if this works */}
									{t(
										`general.route-locations.${form
											.getValues(`route.${index}.locationType`)
											.toLowerCase()}`,
										form.getValues(`route.${index}.locationType`).toLowerCase()
									).toString()}
								</FormLabel>
								<AutoComplete
									debouncerDelay={1000}
									disabled={field.disabled}
									onInputChange={(e) => {
										if (e === '') {
											field.value = undefined;
											return;
										}
										getLocations(e);
									}}
									setSelected={(selectIndex) => {
										field.onChange(locations[selectIndex].uuid);
									}}
									emptyMessage={t(
										'pages.lanes.create.form.route.location.dropdown.no-results',
										'No location found'
									)}
									options={locations.map((location) => {
										return {
											label: `${location.name} (${getAddress(location)})`,
											value: location.uuid,
										};
									})}
									forceUniqueLabelsEnabled={true}
									placeholder={t(
										'pages.lanes.create.form.route.location.dropdown.placeholder',
										'Search for a location'
									).toString()}
								/>
								<FormMessage />
							</FormItem>
						)}
					/>
				))}
			</CardContent>
		</Card>
	);
}
