import {
	CreateLaneDtoRequirementsItem,
	CurrencyType,
	Frequency,
	ShipmentLocationActionRequestDtoLocationActionType,
	ShipmentUnitEnum,
	TransportTypeEnum,
	WeightUnit,
} from '@uturn/api/v1';
import { locales } from '@uturn/portal/i18n';
import { z } from '@uturn/portal/zod';

// TODO: All Default zod errors should be translated aswell (See https://github.com/aiji42/zod-i18n)
const partialFormSchema = z.object({
	numberOfContainer: z.number().positive().optional(),
	shippingLineId: z.coerce.number().positive().optional(),
	remarks: z.string().max(200).optional(),
	reference: z.string().max(50).optional(),
	title: z.string().max(50).optional(),
	pricePerContainer: z
		.object({
			quantity: z
				.number({
					invalid_type_error: locales
						.t(
							'pages.shipment-details.validation.target-price.required',
							'A target price is required'
						)
						.toString(),
					coerce: true,
				})
				.nonnegative()
				.optional(),
			currencyCode: z.nativeEnum(CurrencyType).default('EUR'),
		})
		.optional()
		.transform((value) => {
			// If the quantity is not set, we don't want to send the price object
			return value?.quantity ? value : undefined;
		}),

	cargoWeight: z
		.object({
			quantity: z.coerce
				.number({
					invalid_type_error: locales
						.t(
							'pages.shipment-details.validation.weight.required',
							'A weight is required'
						)
						.toString(),
				})
				.positive(),
			unit: z.nativeEnum(WeightUnit).default('kilogram'),
		})
		.optional(),

	containerFrequency: z.object({
		frequency: z.nativeEnum(Frequency),
		value: z.coerce.number().positive(),
	}),

	shipmentUnit: z.nativeEnum(ShipmentUnitEnum),
	requirements: z.array(z.nativeEnum(CreateLaneDtoRequirementsItem)).optional(),
	laneStartDate: z.date().transform((date) => {
		return date?.toISOString();
	}),
	laneEndDate: z.date().transform((date) => {
		return date?.toISOString();
	}),
});

const routeForm = z
	.object({
		transportType: z.nativeEnum(TransportTypeEnum).default('IMPORT'),
		route: z.array(
			z.object({
				locationType: z.nativeEnum(
					ShipmentLocationActionRequestDtoLocationActionType
				),
				locationId: z.string().uuid().optional(),
				shippingLineId: z.coerce.number().positive().optional(),
				remarks: z.string().max(400).optional(),
			})
		),
	})
	.superRefine((data, ctx) => {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		const required = requiredLocations[data.transportType];

		required.forEach((isRequired, index) => {
			if (isRequired && data.route?.[index].locationId === undefined) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: `${locales.t(`pages.lanes.form.errors.location_required`, {
						// TOOD: double check if this works
						locationType: locales.t(
							`general.route-locations.${data.route[
								index
							].locationType.toLowerCase()}`,
							data.route[index].locationType.toLowerCase()
						),
						transportType: locales
							.t(
								`general.transport_type.${data.transportType.toLowerCase()}`,
								data.transportType.toLowerCase()
							)
							// TODO: check if this works
							.toLowerCase(),
					})}`,
					path: ['route', index, 'locationId'],
				});
			}
		});
	})
	// Removes empty routes from the form
	.transform((value) => ({
		...value,
		route: value.route.filter((route) => route.locationId !== undefined),
	}));

export const formSchema = z.intersection(partialFormSchema, routeForm);
export const requiredLocations = {
	[TransportTypeEnum.IMPORT]: [true, true, false],
	[TransportTypeEnum.EXPORT]: [false, true, true],
	[TransportTypeEnum.SHUNT]: [true, true],
};
