import { faCloudUpload } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from '@uturn/ui-kit';
import type { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

export type FileDropzoneProps = {
	getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
	getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
};

export function FileDropzone({
	getRootProps,
	getInputProps,
}: FileDropzoneProps) {
	const { t } = useTranslation();

	return (
		<section className="max-w-xl">
			<div
				{...getRootProps()}
				className="flex justify-center w-full h-40 px-4 transition bg-secondary-50/20 border-2 border-secondary-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-secondary-400 focus:outline-none"
			>
				<input {...getInputProps()} />
				<span className="flex justify-center items-center gap-2">
					<Icon icon={faCloudUpload} className="w-5 h-5 text-secondary-600" />
					<span className="font-medium text-sm text-secondary-600">
						{t(
							'pages.shipment-detail.modal.add-document.form.add',
							'Drop file to Attach, or'
						)}{' '}
						<span className="text-primary-600 underline">
							{t(
								'pages.shipment-detail.modal.add-document.form.browse',
								'browse'
							)}
						</span>
					</span>
				</span>
			</div>
		</section>
	);
}
