import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { useQueryClient } from '@tanstack/react-query';
import type { UturnFileDTO } from '@uturn/api/v1';
import { useDeleteDocument, useGetAllDocuments } from '@uturn/api/v1';

import {
	Button,
	Card,
	CardDescription,
	CardHeader,
	CardTitle,
	Icon,
	useToast,
} from '@uturn/ui-kit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function OrganizationDocuments() {
	const [triggerDeleteDocument, setTriggerDeleteDocument] = useState(false);
	const [documentId, setDocumentId] = useState<string>();
	const { t } = useTranslation();
	const { toast } = useToast();

	const { data, queryKey: documentsQueryKey } = useGetAllDocuments();

	const queryClient = useQueryClient();

	useDeleteDocument(documentId!, {
		query: {
			enabled: triggerDeleteDocument,
			onSuccess: () => {
				toast({
					title: t(
						'pages.account.organisation.documents.submit.success.title',
						'Document deleted'
					)!,
					description: t(
						'pages.account.organisation.documents.submit.success.description',
						'The document has been deleted'
					),
				});
				setTriggerDeleteDocument(false);
				queryClient.invalidateQueries(documentsQueryKey);
			},
		},
	});

	const handleDelete = (id: string) => {
		setDocumentId(id);
		setTriggerDeleteDocument(true);
	};

	return data?.data.map((document: UturnFileDTO, index: number) => (
		<Card key={index}>
			<CardHeader className="relative">
				<CardTitle>{document.fileTypeDescription}</CardTitle>
				<CardDescription>{document.description}</CardDescription>
				<div className="absolute right-6">
					<Button variant="ghost" onClick={() => handleDelete(document.uuid!)}>
						<Icon icon={faTrashCan} className="w-4 h-4" />
					</Button>
				</div>
			</CardHeader>
		</Card>
	));
}
