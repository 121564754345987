import { WeightUnit } from '@uturn/api/v1';
import { z } from '@uturn/portal/zod';
import { formValidationMessage } from '@uturn/portal/zod/utils';

/**
 * containerNumberSchema
 *
 * - Must be a string.
 * - Must be 11 characters long.
 * - First 3 characters should be letters.
 * - Last 7 characters should be numbers.
 */
export const containerNumberSchema = z
	.string()
	.length(11)
	.refine(
		(value: string) => value.substring(4).match('[0-9]{7}'),
		formValidationMessage('last_7_numbers')
	)
	.refine(
		(value: string) => /^[aA-zZ]{3}/.test(value),
		formValidationMessage('first_3_alpha')
	);

/**
 * containerNumberOrEmptySchema
 *
 * Inherits from containerNumberSchema with:
 * - Allows empty string.
 */
export const containerNumberOrEmptySchema = z.union([
	z.literal(''),
	containerNumberSchema,
]);

/**
 * containerNumberNonSOWNSchema
 *
 * Inherits from containerNumberSchema with:
 * - 4th character should be U, J or Z.
 */
export const containerNumberNonSOWNSchema = containerNumberSchema.refine(
	(value: string) => value.substring(3, 4).match('[uUjJzZ]'),
	formValidationMessage('container_number_4th_char')
);

export const equipmentSchema = z.object({
	/**
	 * Validation for equipment.containerNumber done by
	 * apps/portal/src/modules/shipments/validation/container-number.ts
	 * - useContainerNumberValidation
	 *   - validateContainerNumber
	 */
	containerNumber: containerNumberOrEmptySchema.optional(),
	/**
	 * isoType <= containerType.portbaseCode
	 */
	isoType: z.string().length(4),
	/**
	 * shipmentUnitId <= containerType.id
	 */
	shipmentUnitId: z.number().optional(),
});
// { required_error: 'ISO type is required' }

/**
 * generatorSet: show if showContainerGenSetCheckbox
 */
export const generatorSetSchema = z.boolean();

/**
 * shippingLineId <= shippingLine.id
 */
export const shippingLineIdSchema = z.number();

/**
 * Needs to be parsed into shippingLineId in update form
 * and then decomissioned here.
 *
 * ShippingLineDto from GET Shipment
 *
 * NOTE: applies to shipment.shippingLine and
 * shipment.locationActions[i].shippingLine.
 */
export const shippingLineSchema = z.object({
	id: z.number().optional(),
	code: z.string().optional(),
	description: z.string().optional(),
});

export const sealSchema = z.string();

/**
 * tareWeight: show if tareWeight.quantity > 0
 */
export const tareWeightSchema = z.object({
	quantity: z.number().nonnegative(),
	unit: z.nativeEnum(WeightUnit).default('kilogram'),
});
// { message: 'Weight must be greater than 0' }
