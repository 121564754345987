import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { useGetCarrierContactByShipmentNumber } from '@uturn/api/v1';

import {
	Icon,
	Sheet,
	SheetContent,
	SheetDescription,
	SheetHeader,
	SheetTitle,
} from '@uturn/ui-kit';

import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

function CarrierInformation({ shipmentNumber }: { shipmentNumber: number }) {
	const { data } = useGetCarrierContactByShipmentNumber(shipmentNumber);
	const carrier = data?.data;
	const { t } = useTranslation();
	return (
		<>
			<SheetHeader>
				<SheetTitle>
					{t('pages.shipments.assigned.carrier.title', "{{ name }}'s profile", {
						name: carrier?.partyName,
					})}
				</SheetTitle>
				<SheetDescription>
					{t(
						'pages.shipments.assigned.carrier.description',
						'Contact the carrier of the organisation via email or phone'
					)}
				</SheetDescription>
			</SheetHeader>
			<div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:max-w-xl sm:grid-cols-6 pt-4">
				<div className="col-span-full flex flex-col gap-1">
					<h3 className="text font-heading font-medium tracking-tight">
						{t(
							'pages.shipments.assigned.carrier.contact.title',
							'Contact details'
						)}
					</h3>
					<div className="flex flex-col gap-1">
						<h6 className="block text-sm font-medium leading-none">
							{carrier?.contactName}
						</h6>
						<ul className="flex flex-col gap-1">
							{carrier?.contactPhone && (
								<li className="flex gap-2 items-center">
									<Icon
										icon={faPhone}
										className="text-secondary-600 w-3.5 h-3.5"
									/>
									<a
										href={`tel:${carrier?.contactPhone}`}
										className="text-sm text-primary-600"
									>
										{carrier?.contactPhone}
									</a>
								</li>
							)}
							{carrier?.contactEmail && (
								<li className="flex gap-2 items-center">
									<Icon
										icon={faEnvelope}
										className="text-secondary-600 w-3.5 h-3.5"
									/>
									<a
										href={`mailto::${carrier?.contactEmail}`}
										className="text-sm text-primary-600"
									>
										{carrier?.contactEmail}
									</a>
								</li>
							)}
						</ul>
						{carrier?.languages?.length && (
							<>
								<div>
									{t(
										'pages.shipments.assigned.carrier.contact.languages',
										'Language(s)'
									)}
								</div>
								<div>
									{carrier?.languages
										?.map((language: any) => {
											const languageCode = language.code!;

											return (
												languageCode.charAt(0) +
												languageCode.slice(1).toLowerCase()
											);
										})
										.join(', ')}
								</div>
							</>
						)}
					</div>
				</div>
				<div className="col-span-full flex flex-col gap-1">
					<h3 className="text font-heading font-medium tracking-tight">
						{t(
							'pages.shipments.assigned.carrier.organization.title',
							'Organisation details'
						)}
					</h3>
					<div className="flex flex-col gap-1.5">
						<h6 className="block text-sm font-medium leading-none">
							{carrier?.partyName}
						</h6>
						<ul className="flex gap-1 flex-col">
							{carrier?.organisationPhone !== undefined && (
								<li className="flex gap-2 items-center">
									<Icon
										icon={faPhone}
										className="text-secondary-600 w-3.5 h-3.5"
									/>
									<a
										href={`tel:${carrier?.organisationPhone}`}
										className="text-sm text-primary-600"
									>
										{carrier?.organisationPhone}
									</a>
								</li>
							)}
							{carrier?.organisationOutOfOfficePhone !== undefined && (
								<li className="flex gap-2 items-center">
									<Icon
										icon={faPhone}
										className="text-secondary-600 w-3.5 h-3.5"
									/>
									<a
										href={`tel:${carrier?.organisationOutOfOfficePhone}e}`}
										className="text-sm text-primary-600"
									>
										{carrier?.organisationOutOfOfficePhone}
									</a>
								</li>
							)}
							{carrier?.organisationEmail && (
								<li className="flex gap-2 items-center">
									<Icon
										icon={faEnvelope}
										className="text-secondary-600 w-3.5 h-3.5"
									/>
									<a
										href={`mailto::${carrier?.organisationEmail}`}
										className="text-sm text-primary-600"
									>
										{carrier?.organisationEmail}
									</a>
								</li>
							)}
						</ul>
					</div>
				</div>
			</div>
		</>
	);
}

export function CarrierInformationSheet({
	shipmentNumber,
	openSheet,
	setOpenSheet,
}: {
	shipmentNumber: number;
	openSheet: boolean;
	setOpenSheet: (open: boolean) => void;
}) {
	const { t } = useTranslation();
	return (
		<Sheet open={openSheet} onOpenChange={setOpenSheet}>
			<SheetContent>
				<Suspense
					fallback={
						<span>
							{t(
								'pages.shipments.assigned.carrier.loading',
								'Loading profile...'
							)}
						</span>
					}
				>
					{openSheet && <CarrierInformation shipmentNumber={shipmentNumber} />}
				</Suspense>
			</SheetContent>
		</Sheet>
	);
}
