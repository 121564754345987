import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { useQueryClient } from '@tanstack/react-query';
import { useGetExtraCostByShipment } from '@uturn/api/v1';
import {
	type Comment,
	type ShipmentInvoiceDto,
	type ShipmentInvoiceLine,
	useGetInvoiceOfShipment,
} from '@uturn/api/v2';
import {
	Button,
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
	Icon,
	ScrollArea,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TableHeader,
	TableRow,
} from '@uturn/ui-kit';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdditionalCost } from './additional-cost';
import { useAddShipmentStore } from '@uturn/portal/store/shipments/add-shipment';
import { formatPrice } from '@uturn/portal/utils';
import { LoadingSpan } from '@uturn/portal/components';

export function Financials({ shipmentId }: { shipmentId: string }) {
	const shipmentIdNumber = Number(shipmentId);
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const [hasNonApprovedCosts, setHasNonApprovedCosts] = useState(false);

	const { currencyCode } = useAddShipmentStore();
	const {
		data: extraCost,
		queryKey: extraCostQueryKey,
		isLoading: isExtraCostLoading,
	} = useGetExtraCostByShipment(shipmentIdNumber, {
		query: { suspense: false, retry: 1, select: (data) => data.data },
	});

	const {
		data: invoice,
		queryKey: invoiceQueryKey,
		isLoading: isInvoiceLoading,
	} = useGetInvoiceOfShipment(shipmentIdNumber, {
		query: { suspense: false, retry: 1, select: (data) => data.data },
	});

	useEffect(() => {
		if (extraCost && extraCost.length > 0) {
			setHasNonApprovedCosts(
				extraCost?.some((cost) => cost.status?.code !== 'APPROVED'),
			);
		}
	}, [extraCost]);

	if (
		extraCost &&
		extraCost.length === 0 &&
		invoice?.invoiceLines?.length === 0
	) {
		return <div className="p-3 grid gap-7">No financials available</div>;
	}

	const invalidateQueries = () => {
		queryClient.invalidateQueries(extraCostQueryKey);
		queryClient.invalidateQueries(invoiceQueryKey);
	};

	if (isInvoiceLoading || isExtraCostLoading) {
		return <LoadingSpan />;
	}

	return (
		<ScrollArea className="w-full h-full">
			<div className="grid gap-5 pt-2">
				{extraCost && extraCost.length > 0 && hasNonApprovedCosts && (
					<div className="grid gap-4">
						<h3 className="text-lg font-heading font-medium tracking-tight">
							{t(
								'pages.shipment-detail.sheet.financials.additional-costs.title',
								'Additional costs',
							)}
						</h3>

						{extraCost.map((cost) => {
							if (cost.status?.code === 'APPROVED') return null;
							return (
								<AdditionalCost
									key={cost.id}
									cost={cost}
									currencyCode={currencyCode}
									invalidateQueries={invalidateQueries}
								/>
							);
						})}
					</div>
				)}

				{invoice?.invoiceLines && invoice.invoiceLines.length > 0 && (
					<div className="flex flex-col gap-4 pb-10">
						<div>
							<h3 className="text-lg font-heading font-medium tracking-tight">
								{t(
									'pages.shipment-detail.sheet.financials.invoice-lines.title',
									'Invoice lines',
								)}
							</h3>
							<p className="text-sm text-muted-foreground">
								{/* TODO: Add translation */}
								Invoice number -{' '}
								{invoice.invoiceNumber !== 0
									? invoice.invoiceNumber
									: t('general.invoice-to-be-invoiced', 'To be invoiced')}
							</p>
							<p className="text-sm text-muted-foreground">
								{/* TODO: Add translation */}
								Tax code - {invoice.invoiceLines[0].tax.rate ?? '-'}%{' '}
								{t(`general.tax-codes.${invoice.invoiceLines[0].tax.code}`, '')}
							</p>
						</div>

						<Table>
							<TableHeader>
								<TableRow>
									<TableHead className="w-5/12">
										{t(
											'pages.shipment-detail.sheet.financials.invoice-lines.description',
											'Description',
										)}
									</TableHead>
									<TableHead>
										{t(
											'pages.shipment-detail.sheet.financials.invoice-lines.status',
											'Status',
										)}
									</TableHead>
									<TableHead className="text-right">
										{t(
											'pages.shipment-detail.sheet.financials.invoice-lines.total',
											'Total',
										)}
									</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								{invoice.invoiceLines?.map((line: ShipmentInvoiceLine) => (
									<Collapsible key={line.reference} asChild>
										<>
											<TableRow>
												<TableCell className="font-medium flex">
													{line.description}
													{line.comments.length > 0 && (
														<CollapsibleTrigger asChild>
															<Button
																variant="ghost"
																size="sm"
																className="h-5 px-1 cursor-pointer"
																asChild
															>
																<Icon
																	icon={faChevronDown}
																	className="w-3 h-3"
																/>
															</Button>
														</CollapsibleTrigger>
													)}
												</TableCell>
												<TableCell>
													{t(`general.invoice-status.${line.lineStatus}`)}
												</TableCell>
												<TableCell>
													<div className="flex justify-end items-center h-full ">
														{formatPrice(
															Math.round(
																(line.amount.subtotal.quantity +
																	line.amount.vat.quantity +
																	Number.EPSILON) *
																	100,
															) / 100,
															currencyCode,
														) ?? '-'}
													</div>
												</TableCell>
											</TableRow>
											<CollapsibleContent asChild>
												<>
													{line.comments.length > 0 &&
														line.comments.map(
															(comment: Comment, index: number) => (
																<TableRow key={index} className="py-4">
																	<TableCell
																		colSpan={1}
																		className="font-medium"
																	>
																		{/* TODO: add translation query */}
																		{t(
																			`general.actor-type.${
																				comment.commentedBy ?? ''
																			}`,
																		)}{' '}
																		comment
																	</TableCell>
																	<TableCell colSpan={3} className="text-right">
																		{comment.text ?? '-'}
																	</TableCell>
																</TableRow>
															),
														)}
												</>
											</CollapsibleContent>
										</>
									</Collapsible>
								))}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TableCell colSpan={2}>
										{t(
											'pages.shipment-detail.sheet.financials.invoice-lines.subtotal',
											'Subtotal',
										)}
									</TableCell>
									<TableCell className="text-right">
										{formatPrice(
											invoice.amount?.subtotal.quantity ?? 0,
											currencyCode,
										) ?? '-'}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell colSpan={2}>
										{t(
											'pages.shipment-detail.sheet.financials.invoice-lines.vat',
											'VAT',
										)}
									</TableCell>
									<TableCell className="text-right">
										{formatPrice(
											invoice.amount?.vat.quantity ?? 0,
											currencyCode,
										) ?? '-'}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell colSpan={2}>
										{t(
											'pages.shipment-detail.sheet.financials.invoice-lines.total-incl-vat',
											'Total incl. VAT',
										)}
									</TableCell>
									<TableCell className="text-right">
										{formatPrice(
											(invoice.amount?.subtotal.quantity ?? 0) +
												(invoice.amount?.vat.quantity ?? 0),
											currencyCode,
										) ?? '-'}
									</TableCell>
								</TableRow>
							</TableFooter>
						</Table>
					</div>
				)}
			</div>
		</ScrollArea>
	);
}
