import {
	faChevronLeft,
	faEllipsisVertical,
} from '@fortawesome/pro-regular-svg-icons';
import type { ButtonProps } from '@uturn/ui-kit';
import {
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
	Icon,
} from '@uturn/ui-kit';

import { type ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import type { TabType } from './page/tabs';
import { Tabs } from './page/tabs';
import type { PageStickyBarProps } from './page-sticky-bar';
import { PageStickyBar } from './page-sticky-bar';

type ContextAction = {
	label: ReactNode;
	onClick: () => void;
	className?: string;
	renderCondition?: boolean;
	variant?: ButtonProps['variant'];
	override?: ReactNode;
};

function ContextActions({
	actions,
	secondaryAction,
}: {
	actions?: ContextAction[];
	secondaryAction?: ContextAction;
}) {
	const { t } = useTranslation();

	if (!actions?.length && !secondaryAction) return null;

	const contextActions =
		actions?.filter(({ renderCondition }) => renderCondition !== false) || [];

	return (
		<div className={!contextActions.length ? 'block md:hidden' : ''}>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button variant="outline">
						<Icon icon={faEllipsisVertical} />
						<span className="sr-only">{t('TODO', 'Actions')}</span>
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent side="bottom">
					{secondaryAction && secondaryAction.renderCondition !== false && (
						<>
							<DropdownMenuItem
								className="block md:hidden"
								onClick={secondaryAction.onClick}
							>
								{secondaryAction.label}
							</DropdownMenuItem>
							{!!contextActions.length && (
								<DropdownMenuSeparator className="block md:hidden" />
							)}
						</>
					)}
					{contextActions?.map((action, index) => (
						<DropdownMenuItem
							className={action.className}
							key={index}
							onClick={action.onClick}
						>
							{action.label}
						</DropdownMenuItem>
					))}
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	);
}

export function Page({
	title,
	children,
	backButton,
	actions,
	primaryAction,
	secondaryAction,
	contextActions,
	className,
	stickyBar = undefined,
	tabsStart,
	tabsEnd,
}: {
	title?: string;
	children: ReactNode;
	backButton?: {
		label: string;
		href: string;
	};
	actions?: ReactNode;
	contextActions?: ContextAction[];
	primaryAction?: ContextAction;
	secondaryAction?: ContextAction;
	className?: string;
	stickyBar?: PageStickyBarProps;
	tabsStart?: TabType[];
	tabsEnd?: TabType[];
}) {
	const location = useLocation();
	const navigate = useNavigate();

	return (
		<div
			className={`flex-1 grid space-y-4 py-8 pt-6 @container/content ${className}`}
		>
			<div className="flex items-start justify-between flex-wrap-reverse gap-y-2">
				<div className="flex flex-col">
					{backButton && (
						<nav aria-label="Back" className="print:hidden">
							<div className="mb-1 flex items-center space-x-1 text-sm text-muted-foreground">
								<Link
									className="flex gap-1 items-center"
									to={location.state !== null ? '' : backButton.href}
									onClick={() => {
										if (location.state !== null) {
											navigate(-1);
										}
									}}
								>
									<Icon icon={faChevronLeft} />
									<span>{backButton.label}</span>
								</Link>
							</div>
						</nav>
					)}
					<h2 className="text-3xl font-bold tracking-tight">{title}</h2>
				</div>

				<div className="flex space-x-2 ml-auto">
					{actions}
					{secondaryAction &&
						secondaryAction.renderCondition !== false &&
						secondaryAction.override}
					{secondaryAction &&
						secondaryAction.renderCondition !== false &&
						!secondaryAction.override && (
							<Button
								variant={secondaryAction.variant || 'outline'}
								className="hidden md:block"
								onClick={secondaryAction.onClick}
							>
								{secondaryAction.label}
							</Button>
						)}
					{primaryAction &&
						primaryAction.renderCondition !== false &&
						primaryAction.override}
					{primaryAction &&
						!primaryAction.override &&
						primaryAction.renderCondition !== false && (
							<Button
								onClick={primaryAction.onClick}
								variant={primaryAction.variant || 'default'}
							>
								{primaryAction.label}
							</Button>
						)}
					<ContextActions
						actions={contextActions}
						secondaryAction={secondaryAction}
					/>
				</div>
			</div>
			<Tabs start={tabsStart} end={tabsEnd} />
			{children}
			{stickyBar && (
				<PageStickyBar position={stickyBar.position}>
					{stickyBar.children}
				</PageStickyBar>
			)}
		</div>
	);
}
