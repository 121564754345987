import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { PricingTierCard } from './pricing-tier-cards';
import type { PricingTiersProps } from './pricing-tiers.types';
import { ConditionDTO, ConditionDTOType, TierDTOType } from '@uturn/api/v1';

function getTierButtonLabel(
	t: TFunction<'translation', undefined>,
	onCurrentTier: boolean,
	onLowerTier: boolean,
): string {
	if (onCurrentTier) {
		return t(
			'pages.subscriptions.submit.label.current',
			'Current subscription',
		);
	}
	if (onLowerTier) {
		return t('pages.subscriptions.submit.label.contact', 'Contact UTURN');
	}
	return t('pages.subscriptions.submit.label.default', 'Subscribe');
}

function getFeatures(
	t: TFunction<'translation', undefined>,
	conditions: ConditionDTO[] | undefined,
) {
	if (!conditions?.length) {
		return [];
	}

	const applicableConditions = conditions.filter(
		(condition) =>
			!(
				condition.type === ConditionDTOType.BOOLEAN &&
				condition.value === 'false'
			),
	);

	if (!applicableConditions.length) {
		return [];
	}

	return applicableConditions.map((condition) => {
		const tierConditionKey = condition.key.toLowerCase();

		if (condition.type === ConditionDTOType.BOOLEAN) {
			return t(
				`pages.subscriptions.tier.condition.${tierConditionKey}`,
				condition.key,
			);
		}

		if (!condition.value) {
			return t(
				`pages.subscriptions.tier.condition.${tierConditionKey}_valueless`,
				condition.key,
			);
		}

		return t(
			`pages.subscriptions.tier.condition.${tierConditionKey}`,
			`${condition.key} {{ conditionValue }}`,
			{ conditionValue: condition.value },
		);
	});
}

export function PricingTiers({
	subscription,
	tiers,
	onSelect,
}: PricingTiersProps) {
	const { t } = useTranslation();

	if (!tiers?.length) {
		return null;
	}

	const indexCurrentTier =
		tiers.findIndex(
			(item) => subscription && item.name === subscription.tierName,
		) ?? -1;

	const currentTierId =
		indexCurrentTier === -1 ? -1 : (tiers[indexCurrentTier].id ?? -1);

	const lowerTierIds =
		indexCurrentTier === -1
			? []
			: (tiers
					.filter((item, index) => !!item.id && index < indexCurrentTier)
					.map((item) => item.id!) ?? []);

	return (
		<div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
			{tiers
				.filter((tier) => tier.type !== TierDTOType.TRIAL && !!tier.id)
				.map((tier) => {
					const tierId = tier.id ?? -1; // Above filter guarantees defined value
					const tierNameKey = tier.name.toLowerCase();

					const onCurrentTier = tierId === currentTierId;
					const onLowerTier = lowerTierIds.includes(tierId);

					return (
						<PricingTierCard
							key={tierId.toString()}
							id={tierId.toString()}
							mostPopular={true}
							name={t(
								`pages.subscriptions.tier.name.${tierNameKey}`,
								tier.name,
							)}
							features={getFeatures(t, tier.conditions)}
							price={
								tier.price ? `€ ${tier.price}` : t('general.custom', 'Custom')
							}
							billingFrequency={tier.price ? tier.paymentPeriod : undefined}
							description={''}
							action={{
								label: getTierButtonLabel(t, onCurrentTier, onLowerTier),
								handleOnClick:
									onCurrentTier || onLowerTier
										? undefined
										: () => onSelect(tier),
							}}
						/>
					);
				})}
		</div>
	);
}
