import {
	ShipmentLocationActionDtoLocationActionType,
	useGetMyCarriersList,
} from '@uturn/api/v1';
import { useContext, useMemo } from 'react';
import { useAbac } from 'react-abac';
import { Permission } from '@uturn/portal/abac';
import { MetaDataContext } from '@uturn/portal/context';

const useShipmentTableOptions = () => {
	const { userHasPermissions } = useAbac();
	const { data: carriersResponse } = useGetMyCarriersList({
		query: {
			enabled: userHasPermissions(Permission.INVITE_CARRIER),
		},
	});
	const { shipmentMetaData } = useContext(MetaDataContext);
	const shippingLines = useMemo(
		() =>
			shipmentMetaData?.shippingLines?.map((shippingLine) => ({
				value: (shippingLine.id ?? 0).toString(),
				label: `${shippingLine.description!} (${shippingLine.code!})`,
				scacCode: shippingLine.code,
			})) ?? [],
		[shipmentMetaData?.shippingLines]
	);
	const containerTypes = useMemo(
		() =>
			shipmentMetaData?.shipmentUnits?.map((shipmentUnit) => ({
				value: (shipmentUnit.id ?? 0).toString(),
				label: shipmentUnit.description ?? '',
				isoCode: shipmentUnit.portbaseCode ?? '',
			})) ?? [],
		[shipmentMetaData?.shipmentUnits]
	);
	const requirements = useMemo(
		() =>
			shipmentMetaData?.shipmentRequirements?.map((shipmentRequirement) => ({
				value: (shipmentRequirement.id ?? 0).toString(),
				label: shipmentRequirement.description ?? '',
			})) ?? [],
		[shipmentMetaData?.shipmentRequirements]
	);
	const locationTypes = useMemo(
		() =>
			Object.keys(ShipmentLocationActionDtoLocationActionType)
				.filter((locationType) => !!locationType)
				.map((locationType) => ({
					value: locationType.toLowerCase(),
					label: locationType,
				})) ?? [],
		[shipmentMetaData?.transportTypeList]
	);
	const termsAndConditions = [
		{
			value: 'avc',
			label: 'AVC',
		},
		{
			value: 'cmr',
			label: 'CMR',
		},
		{
			value: 'rha',
			label: 'RHA',
		},
	];
	const carriers = useMemo(
		() =>
			carriersResponse?.data
				?.filter(
					(carrier) =>
						carrier.relationStatus === 'Contracted' ||
						carrier.relationStatus === 'Dedicated'
				)
				.map((carrier) => ({
					value: (carrier.carrierPartyId ?? 0).toString(),
					label: carrier.carrierParty ?? '',
				})) ?? [],
		[carriersResponse]
	);

	return {
		containerTypes,
		shippingLines,
		requirements,
		locationTypes,
		termsAndConditions,
		carriers,
	};
};

export default useShipmentTableOptions;
