import type { GetShipmentsFilterStatusesItem } from '@uturn/api/v2';
import { AgGridReact } from '@uturn/ui-kit';
import type { ColumnEvent, GridOptions } from '@uturn/ui-kit';
import { useCallback, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ShipmentGroupDetailContext } from '../../providers/shipment-group-detail';
import { isColDef, isColGroupDef } from './typeguard';
import type { SearchShipment } from './types';
import useColumnDefs from '@uturn/portal/hooks/shipments/use-column-defs';
import { useServerSideDatasource } from '@uturn/portal/hooks/shipments/use-data-source';
import useDefaultColumns from '@uturn/portal/hooks/shipments/use-default-columns';
import type { StatusTab } from '@uturn/portal/types/shipments';

interface Props {
	filterStatuses?: GetShipmentsFilterStatusesItem[];
	statusTab?: Exclude<StatusTab, 'groups'>;
}

/**
 * Known issues:
 *
 * Component is currently missing an empty state check, need to check how we distuingish empty results from no results
 */
export default function ShipmentsTable({
	filterStatuses,
	statusTab = 'published',
}: Props) {
	// TODO: use this from metadata instead
	const MAX_ROUTE_LOCATIONS = 5;
	const PAGE_SIZE = 50;

	const { t } = useTranslation();
	const gridRef = useRef<AgGridReact<SearchShipment>>(null);
	const { groupId } = useContext(ShipmentGroupDetailContext);

	const serverSideDatasource = useServerSideDatasource(
		PAGE_SIZE,
		groupId,
		filterStatuses
	);

	// gridRef.current.api.getGridOption

	/**
	 * This function returns the default columns for every view
	 *
	 * When we actually save the settings, we should fallback to the default columns if the API has not stored any settings
	 * regarding the table view
	 */
	const columns = useDefaultColumns(
		statusTab,
		// Add the following location_[1-8] to the columns
		Array(MAX_ROUTE_LOCATIONS)
			.fill(0)
			.map((_, i) => `location_${i + 1}`)
	);

	const initialColumnDefs = useColumnDefs(columns, statusTab, gridRef);

	const saveColumnState = useCallback((event: ColumnEvent) => {
		const viewColumnsStorage = localStorage.getItem('viewColumns');
		const parsedColumns = viewColumnsStorage
			? JSON.parse(viewColumnsStorage)
			: null;

		let obj = {};
		event.api.getColumnDefs()?.forEach((colDef) => {
			if (!isColGroupDef(colDef)) {
				return;
			}

			obj = {
				...obj,
				[colDef.groupId as string]: colDef.children.map((column) => {
					if (!isColDef(column)) {
						return {};
					}

					return {
						key: column.colId?.replace(/locations.location_[0-9]*./, ''),
						hidden: !!column.hide,
					};
				}),
			};
		});

		const objectsToSave = {
			...parsedColumns,
			[statusTab]: obj,
		};

		localStorage.setItem('viewColumns', JSON.stringify(objectsToSave));
	}, []);

	const gridOptions: GridOptions<SearchShipment> = {
		rowModelType: 'serverSide',
		getRowId: (row) => row.data.id.toString(),
		serverSideDatasource,
		pagination: true,
		paginationPageSize: PAGE_SIZE,
		paginationPageSizeSelector: false,
		cacheBlockSize: PAGE_SIZE,
		columnDefs: initialColumnDefs,
		sideBar: {
			toolPanels: [
				{
					id: 'columns',
					labelDefault: t('shipments.table.sidebar.columns', {
						defaultValue: 'Columns',
					}),
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressColumnFilter: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true,
					},
				},
				{
					id: 'filters',
					labelDefault: t('shipments.table.sidebar.filters', {
						defaultValue: 'Filters',
					}),
					labelKey: 'filters',
					iconKey: 'filter',
					toolPanel: 'agFiltersToolPanel',
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressColumnFilter: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true,
					},
				},
			],
		},
		suppressDragLeaveHidesColumns: true,
		onColumnVisible: (event) => saveColumnState(event),
		onColumnMoved: (event) => {
			if (!event.finished) {
				return;
			}

			saveColumnState(event);
		},
	};

	return (
		/**
		 * Instead of calculating height here, we should really use flex to auto-fill spaces
		 * But this requires quite alot of refactoring in the HTML elements
		 */
		<div className="w-full h-[calc(100dvh_-_235px)]">
			<AgGridReact
				className="ag-theme-quartz align-baseline"
				ref={gridRef}
				{...gridOptions}
			/>
		</div>
	);
}
