import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import {
	faBackward,
	faBellOn,
	faCircleCheck,
	faCirclePlus,
	faCircleStop,
	faComment,
	faCommentPen,
	faCommentsDollar,
	faContainerStorage,
	faFile,
	faFileCheck,
	faFileCirclePlus,
	faFileCircleXmark,
	faFileExport,
	faFilePen,
	faFilterCircleXmark,
	faHandshake,
	faMoneyCheckDollarPen,
	faMoneyCheckPen,
	faPersonSeatReclined,
	faSquareCheck,
	faSquarePen,
	faSquarePlus,
	faStopCircle,
	faThumbsDown,
	faThumbsUp,
	faTrashCan,
	faTrashCanXmark,
	faTruckClock,
	faTruckContainer,
} from '@fortawesome/pro-regular-svg-icons';
import type { EventHistoryDTO } from '@uturn/api/v1';
import { type EventGroup, EventType } from './events-feed.types';

const eventColor = {
	green: {
		iconColor: 'text-success-foreground',
		backgroundColor: 'bg-success',
	},
	red: {
		iconColor: 'text-destructive-foreground',
		backgroundColor: 'bg-destructive',
	},
	blue: {
		iconColor: 'text-primary-foreground',
		backgroundColor: 'bg-primary',
	},
};

export const eventIcons: {
	[key in EventType]?: {
		name: IconDefinition;
		eventColor: keyof typeof eventColor;
	};
} = {
	[EventType.Accepted]: {
		name: faHandshake,
		eventColor: 'green',
	},
	[EventType.Assigned]: {
		name: faFileCheck,
		eventColor: 'green',
	},
	[EventType.Cancelled]: {
		name: faStopCircle,
		eventColor: 'red',
	},

	[EventType.Created]: {
		name: faCirclePlus,
		eventColor: 'green',
	},

	[EventType.Executed]: {
		name: faSquareCheck,
		eventColor: 'blue',
	},

	[EventType.Published]: {
		name: faSquarePlus,
		eventColor: 'green',
	},

	[EventType.Updated]: {
		name: faMoneyCheckPen,
		eventColor: 'blue',
	},

	[EventType.IssueReported]: {
		name: faBellOn,
		eventColor: 'red',
	},

	[EventType.Newquote]: {
		name: faCommentsDollar,
		eventColor: 'blue',
	},

	[EventType.Cancelquote]: {
		name: faTrashCan,
		eventColor: 'red',
	},

	[EventType.Contractdeclined]: {
		name: faFilterCircleXmark,
		eventColor: 'red',
	},

	[EventType.UpdateContainer]: {
		name: faContainerStorage,
		eventColor: 'blue',
	},

	[EventType.FinancialUpdate]: {
		name: faFilePen,
		eventColor: 'blue',
	},

	[EventType.Withdrawn]: {
		name: faBackward,
		eventColor: 'blue',
	},

	[EventType.Started]: {
		name: faTruckContainer,
		eventColor: 'blue',
	},

	[EventType.ApproveCost]: {
		name: faThumbsUp,
		eventColor: 'green',
	},

	[EventType.DeclineCost]: {
		name: faThumbsDown,
		eventColor: 'red',
	},

	[EventType.UpdatedCost]: {
		name: faMoneyCheckDollarPen,
		eventColor: 'blue',
	},

	[EventType.Completed]: {
		name: faCircleCheck,
		eventColor: 'green',
	},

	[EventType.UpdateRequested]: {
		name: faCommentPen,
		eventColor: 'blue',
	},

	[EventType.UpdateAccepted]: {
		name: faThumbsUp,
		eventColor: 'green',
	},

	[EventType.UpdateDeclined]: {
		name: faThumbsDown,
		eventColor: 'red',
	},

	[EventType.Stopped]: {
		name: faCircleStop,
		eventColor: 'red',
	},

	[EventType.AssignDriver]: {
		name: faPersonSeatReclined,
		eventColor: 'blue',
	},

	[EventType.ETAUpdate]: {
		name: faTruckClock,
		eventColor: 'blue',
	},

	[EventType.Exported]: {
		name: faFileExport,
		eventColor: 'blue',
	},

	[EventType.ApprovedCancellation]: {
		name: faThumbsUp,
		eventColor: 'blue',
	},

	[EventType.UpdateRequestCancelled]: {
		name: faTrashCanXmark,
		eventColor: 'red',
	},

	[EventType.ShipperNote]: {
		name: faSquarePen,
		eventColor: 'blue',
	},

	[EventType.DocumentRegistered]: {
		name: faFileCirclePlus,
		eventColor: 'green',
	},

	[EventType.DocumentRemoved]: {
		name: faFileCircleXmark,
		eventColor: 'red',
	},

	[EventType.ShipperMessage]: {
		name: faComment,
		eventColor: 'blue',
	},

	[EventType.CarrierMessage]: {
		name: faComment,
		eventColor: 'blue',
	},

	[EventType.OrgDocumentRegistered]: {
		name: faFileCirclePlus,
		eventColor: 'green',
	},

	[EventType.OrgDocumentRemoved]: {
		name: faFileCircleXmark,
		eventColor: 'red',
	},

	[EventType.DeclinedQuote]: {
		name: faThumbsDown,
		eventColor: 'red',
	},
};

export function getIconProps(eventType: EventType) {
	const iconProps = eventIcons[eventType];

	if (!iconProps) {
		return {
			name: faFile,
			eventColor: { iconColor: '', backgroundColor: 'bg-primary' },
		};
	}

	return {
		name: iconProps.name,
		eventColor: eventColor[iconProps.eventColor],
	};
}

export function groupedEventsByDate(events: EventHistoryDTO[]) {
	const groupedEvents: EventGroup[] = [];

	events.forEach((event: EventHistoryDTO) => {
		const createdOnDate = new Date(event.createdOn!);

		if (createdOnDate.toString() === 'Invalid Date') {
			return;
		}

		const indexOfGroup = groupedEvents.findIndex(
			(item) =>
				`${item.date.getDate()}${item.date.getMonth()}${item.date.getFullYear()}` ===
				`${createdOnDate.getDate()}${createdOnDate.getMonth()}${createdOnDate.getFullYear()}`
		);

		if (indexOfGroup === -1) {
			groupedEvents.push({ date: createdOnDate as Date, events: [event] });
		} else {
			groupedEvents[indexOfGroup].events.push(event);
		}
	});

	return groupedEvents;
}
