import { GetShipmentsFilterTransportTypesItem } from '@uturn/api/v2';

import { AccordionContent, AccordionTrigger, Checkbox } from '@uturn/ui-kit';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterContext } from './context';

export function TransportTypeFilter() {
	const { filters, setFilters } = useContext(FilterContext);
	const { t } = useTranslation();
	const options = [
		{
			label: t('general.transport_type.import', 'Import'),
			value: GetShipmentsFilterTransportTypesItem.IMPORT,
		},
		{
			label: t('general.transport_type.export', 'Export'),
			value: GetShipmentsFilterTransportTypesItem.EXPORT,
		},
		{
			label: t('general.transport_type.shunt', 'Shunt'),
			value: GetShipmentsFilterTransportTypesItem.SHUNT,
		},
	];

	const types =
		typeof filters['filter.transportTypes'] === 'string'
			? [filters['filter.transportTypes']]
			: filters['filter.transportTypes'] ?? [];

	const handleOnChange = (
		selectedStatus: GetShipmentsFilterTransportTypesItem,
		select: boolean
	) => {
		if (select) {
			setFilters({ 'filter.transportTypes': [...types, selectedStatus] });
		} else {
			setFilters({
				'filter.transportTypes': types.filter((s) => s !== selectedStatus),
			});
		}
	};

	return (
		<>
			<AccordionTrigger>
				{t('pages.shipments.filters.transportType.title', 'Transport type')}
			</AccordionTrigger>
			<AccordionContent>
				<div className="grid gap-1">
					{options.map((s) => (
						<div key={s.value} className="flex items-center space-x-2">
							<Checkbox
								checked={types.findIndex((value) => value === s.value) !== -1}
								onCheckedChange={(e: boolean) => handleOnChange(s.value, e)}
								id={s.value}
							/>
							<label
								htmlFor={s.value}
								className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
							>
								{s.label}
							</label>
						</div>
					))}
				</div>
			</AccordionContent>
		</>
	);
}
