import { faDownload, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { type UturnFileDTO, useDownloadDocument } from '@uturn/api/v1';
import { Button, Card, CardContent, Icon } from '@uturn/ui-kit';
import { queryDownloadFile } from '@uturn/portal/utils';
import type { Document } from '@uturn/portal/types/shipment';

export function ShipmentDocumentTile({
	document,
	removeFile,
}: {
	document: UturnFileDTO | Document;
	removeFile: () => void;
}) {
	const fileType =
		'fileTypeDescription' in document
			? document.fileTypeDescription
			: (document as Document).fileRequest.fileType;
	const fileDesc =
		'description' in document
			? document.description
			: (document as Document).fileRequest.description;
	const fileName =
		'description' in document
			? ''
			: (document as Document).fileRequest.fileName;
	const deleteFileEnabled =
		'canBeDeleted' in document ? document.canBeDeleted : true;

	const documentUUID = 'uuid' in document ? document.uuid : '';

	const { refetch } = useDownloadDocument(documentUUID!, {
		query: {
			enabled: false,
			onSuccess: (data: any) => {
				queryDownloadFile(data, `${fileType}-${fileDesc}`);
			},
		},
		request: {
			responseType: 'blob',
		},
	});

	return (
		<Card>
			<CardContent className="flex items-center justify-between py-4">
				<span className="flex text-sm text-secondary-700 flex-col">
					<span className="font-bold">{fileType}</span>
					<span>{fileDesc}</span>
					<span>{fileName}</span>
				</span>
				<div className="flex">
					{documentUUID && (
						<Button
							variant="ghost"
							onClick={(e) => {
								e.preventDefault();
								refetch();
							}}
						>
							<Icon icon={faDownload} className="w-4 h-4" />
						</Button>
					)}
					{deleteFileEnabled && (
						<Button
							variant="ghost"
							onClick={(e) => {
								e.preventDefault();
								removeFile();
							}}
						>
							<Icon icon={faTrashCan} className="w-4 h-4" />
						</Button>
					)}
				</div>
			</CardContent>
		</Card>
	);
}
