import {
	AlertDialogCancel,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	Button,
} from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';

export const ShipmentGroupPublishedModal = ({
	numberOfShipments,
	shipmentGroupName,
	navigate,
	copyShipment,
}: {
	numberOfShipments: number;
	shipmentGroupName: string;
	navigate: any;
	copyShipment: () => void;
}) => {
	const { t } = useTranslation();
	return (
		<>
			<AlertDialogHeader>
				<AlertDialogTitle>
					{t(
						'pages.shipment.modal.group-published.title',
						'Shipment group {{shipmentGroupName}} published',
						{ shipmentGroupName }
					)}
				</AlertDialogTitle>
				<AlertDialogDescription>
					{t(
						'pages.shipment.modal.group-published.body',
						'You have successfully published {{numberOfShipments}} shipments in group: {{shipmentGroupName}}.What would you like to do next?',
						{ numberOfShipments, shipmentGroupName }
					)}
				</AlertDialogDescription>
			</AlertDialogHeader>
			<AlertDialogFooter>
				<div className="flex gap-2">
					<Button
						variant="outline"
						onClick={() => {
							navigate(`/shipments/groups`);
						}}
					>
						{t(
							'pages.addshipment.modal.actions.back-to-group-overview',
							'Groups overview'
						)}
					</Button>
				</div>
				<AlertDialogCancel asChild>
					<Button variant="outline" onClick={copyShipment}>
						{t('pages.addshipment.modal.actions.copy-group', 'Copy group')}
					</Button>
				</AlertDialogCancel>
				<Button
					onClick={() => {
						navigate(`/shipments/add`);
					}}
				>
					{t('pages.addshipment.modal.actions.new', 'Create new')}
				</Button>
			</AlertDialogFooter>
		</>
	);
};
