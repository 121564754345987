import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import {
	Button,
	Callout,
	CalloutIcon,
	CalloutTitle,
	Icon,
} from '@uturn/ui-kit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FinancialsSheet } from '../financials-sheet';
import { useGetExtraCostByShipment } from '@uturn/api/v1';

export function CostDeclaredBanner({
	shipmentNumber,
}: {
	shipmentNumber: string;
}) {
	const { t } = useTranslation();
	const [openFinancialsSheet, setOpenFinancialsSheet] = useState(false);

	const { data: extraCost } = useGetExtraCostByShipment(
		Number(shipmentNumber),
		{
			query: {
				select: (data) => data.data,
			},
		},
	);

	const hasNewCosts = extraCost
		? extraCost.some((cost) => cost.status?.code === 'NEW')
		: false;

	return (
		<>
			<Callout variant={hasNewCosts ? 'default' : 'destructive'}>
				<CalloutIcon>
					<Icon icon={faInfoCircle} />
				</CalloutIcon>
				<CalloutTitle>
					{hasNewCosts
						? t(
								'pages.shipment-detail.banner.cost-declared-new.description',
								'Extra costs have been declared by the carrier. These costs can be processed once the shipment has been completed.',
							)
						: t(
								'pages.shipment-detail.banner.cost-declared.description',
								'Extra cost were declared by the carrier. Please review pending cost.',
							)}
				</CalloutTitle>

				<Button
					variant={hasNewCosts ? 'default' : 'destructive'}
					size="sm"
					className="ml-auto flex gap-3 items-center"
					onClick={(e) => {
						e.preventDefault();
						setOpenFinancialsSheet(true);
					}}
				>
					{hasNewCosts
						? t(
								'pages.shipment-detail.banner.cost-declared-new.review',
								'View cost',
							)
						: t(
								'pages.shipment-detail.banner.cost-declared.review',
								'Review cost',
							)}
				</Button>
			</Callout>
			<FinancialsSheet
				openSheet={openFinancialsSheet}
				setOpenSheet={setOpenFinancialsSheet}
				shipmentNumber={shipmentNumber}
			/>
		</>
	);
}
