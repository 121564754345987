import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { type QueryKey, useQueryClient } from '@tanstack/react-query';
import {
	FetchCancellationReasons200Item as CancelReason,
	type FetchCancellationReasonsStatus as CancelShipmentStatus,
	useCancelShipment,
	useFetchCancellationReasons,
	useWithdrawShipment,
} from '@uturn/api/v1';
import { ShipmentDtoShipmentStatus as ShipmentStatus } from '@uturn/api/v2';
import {
	Button,
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Icon,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
	Textarea,
	sonner,
} from '@uturn/ui-kit';
import { type AxiosError } from 'axios';
import { useId, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shouldUpdate } from '../../utils';
import { modals, unpublishOptions } from './shipment-cancel.constants';
import { UnpublishOptions } from './shipment-cancel.types';
import {
	type FormValues,
	formSchema,
	requiredFields,
} from './shipment-cancel.zod';
import { CardRadioButton } from './unpublish-card-radio-button';
import { getApiErrorMessage } from '@uturn/portal/utils';

export type CancelOrUnpublishShipmentModalProps = {
	shipmentNumber: number;
	shipmentStatus: ShipmentStatus | undefined;
	shipmentQueryKey: QueryKey;
	eventsQueryKey: QueryKey;
	openModal: boolean;
	setOpenModal: (open: boolean) => void;
};

export function CancelOrUnpublishShipmentModal({
	shipmentNumber,
	shipmentStatus = ShipmentStatus.UNKNOWN,
	shipmentQueryKey,
	eventsQueryKey,
	openModal,
	setOpenModal,
}: CancelOrUnpublishShipmentModalProps) {
	const queryClient = useQueryClient();
	const { t } = useTranslation();

	const { data: cancelReasons } = useFetchCancellationReasons(
		{
			status: shipmentStatus as CancelShipmentStatus,
			isMultiCancel: false,
			isGroupCancel: false,
		},
		{ query: { enabled: shipmentStatus !== ShipmentStatus.UNKNOWN } }
	);

	const labelId = useId();
	const ulAriaLabelledBy = `radio-label-${labelId}`;
	const [triggerUnpublish, setTriggerUnpublish] = useState(false);

	const ref = useRef<HTMLFormElement>(null);

	const form = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			reasonSelect: '',
			reasonOtherExplanation: '',
			type:
				modals[shipmentStatus] === 'unpublish'
					? UnpublishOptions.UNPUBLISH
					: UnpublishOptions.CANCEL,
		},
	});

	const watchReasonSelect = form.watch('reasonSelect');
	const watchType = form.watch('type');

	const onSuccess = () => {
		queryClient.invalidateQueries({ queryKey: shipmentQueryKey });
		queryClient.invalidateQueries({ queryKey: eventsQueryKey });
		setOpenModal(false);
		sonner.success(
			t(
				`pages.shipment-detail.modal.${modals[shipmentStatus]}.message.success`,
				'Shipment has been cancelled'
			)
		);
	};

	const onError = (error: AxiosError | any) => {
		sonner.error(
			t(
				`pages.shipment-detail.modal.${modals[shipmentStatus]}.message.error`,
				'Cancellation failed'
			),
			{ description: getApiErrorMessage(error) }
		);
	};

	const cancelShipment = useCancelShipment({
		mutation: { onSuccess, onError },
	});

	useWithdrawShipment(shipmentNumber, {
		query: {
			enabled: triggerUnpublish,
			onSuccess,
			onError,
			refetchOnWindowFocus: false,
			suspense: false,
		},
	});

	const handleSubmit = (event: React.FormEvent) => {
		if (ref.current === null) return;

		event.preventDefault();
		ref.current.dispatchEvent(
			new Event('submit', { cancelable: true, bubbles: true })
		);
	};

	const onSubmit = async (data: FormValues) => {
		if (data.type === UnpublishOptions.UNPUBLISH) {
			setTriggerUnpublish(true);
			return;
		}
		// data.type === UnpublishOptions.CANCEL
		const reason =
			data.reasonSelect === CancelReason.OTHER
				? data.reasonOtherExplanation
				: t(
						`general.shipments.cancel-reason.${data.reasonSelect}`,
						data.reasonSelect!
				  );
		cancelShipment.mutate({
			shipmentNumber,
			data: reason!,
		});
	};

	if (modals[shipmentStatus] === '-') {
		return <></>;
	}

	return (
		<Dialog open={openModal} onOpenChange={setOpenModal}>
			<DialogContent
				className={
					modals[shipmentStatus] === 'unpublish'
						? 'flex flex-col gap-8 text-left w-[25rem] md:w-[40rem] lg:w-[50rem]'
						: ''
				}
			>
				<DialogHeader>
					<DialogTitle>
						{t(
							`pages.shipment-detail.modal.${modals[shipmentStatus]}.heading`,
							'Cancel shipment {{shipmentNumber}}',
							{ shipmentNumber }
						)}
					</DialogTitle>
					{modals[shipmentStatus] === 'unpublish' && (
						<DialogDescription>
							{t(
								`pages.shipment-detail.modal.${modals[shipmentStatus]}.description`,
								'Select the option you want to perform.'
							)}
						</DialogDescription>
					)}
				</DialogHeader>
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmit)}
						ref={ref}
						// className="space-y-4"
						className="flex flex-col gap-2.5"
					>
						{modals[shipmentStatus] === 'unpublish' && (
							<FormField
								control={form.control}
								name="type"
								render={({ field }) => (
									<FormItem>
										<ul
											className="width-full flex list-none flex-col space-y-2 @md/input:flex-row @md/input:space-x-4 @md/input:space-y-0 "
											tabIndex={0}
											role="radiogroup"
											aria-labelledby={ulAriaLabelledBy}
											aria-activedescendant={watchType}
										>
											{unpublishOptions(t).map((option) => (
												<CardRadioButton
													key={option.value}
													value={option.value}
													heading={option.heading}
													recommended={option.recommended ?? false}
													body={
														<p className="text-muted-foreground">
															{option.body}
														</p>
													}
													setSelected={(value) => {
														field.onChange(value);
														form.setValue('reasonSelect', '', shouldUpdate);
													}}
													selected={watchType}
												/>
											))}
										</ul>
									</FormItem>
								)}
							/>
						)}
						{watchType === UnpublishOptions.CANCEL && (
							<>
								<FormField
									control={form.control}
									name="reasonSelect"
									render={({ field }) => (
										<FormItem>
											<FormLabel
												className={
													requiredFields.reasonSelect ? 'required-asterix' : ''
												}
											>
												{t(
													`pages.shipment-detail.modal.${modals[shipmentStatus]}.subheading`,
													'Why do you want to cancel your shipment?'
												)}
											</FormLabel>
											<Select
												{...field}
												onValueChange={field.onChange}
												disabled={cancelShipment.isLoading}
											>
												<FormControl>
													<SelectTrigger>
														<SelectValue
															placeholder={
																<span>
																	{t(
																		`pages.shipment-detail.modal.${modals[shipmentStatus]}.placeholder`,
																		'Select a reason'
																	)}
																</span>
															}
														/>
													</SelectTrigger>
												</FormControl>
												<SelectContent>
													{cancelReasons?.data?.map(
														(reasonCode: CancelReason, index: number) => (
															<SelectItem key={index} value={reasonCode}>
																{t(
																	`general.shipments.cancel-reason.${reasonCode}`,
																	reasonCode as string
																)}
															</SelectItem>
														)
													)}
												</SelectContent>
											</Select>
											<FormMessage />
										</FormItem>
									)}
								/>
								{watchReasonSelect === CancelReason.OTHER && (
									<FormField
										control={form.control}
										name="reasonOtherExplanation"
										render={({ field }) => (
											<FormItem>
												<FormControl>
													<Textarea
														{...field}
														onChange={field.onChange}
														disabled={cancelShipment.isLoading}
													/>
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
								)}
							</>
						)}
					</form>
				</Form>
				<DialogFooter>
					<DialogClose asChild>
						<Button
							disabled={cancelShipment.isLoading}
							variant="outline"
							onClick={() => form.reset()}
						>
							{t(
								`pages.shipment-detail.modal.${modals[shipmentStatus]}.cancel`,
								'Cancel'
							)}
						</Button>
					</DialogClose>
					<Button disabled={cancelShipment.isLoading} onClick={handleSubmit}>
						{cancelShipment.isLoading ? (
							<>
								<Icon className="mr-2 h-4 w-4" icon={faSpinnerThird} spin />
								{t(
									`pages.shipment-detail.modal.${modals[shipmentStatus]}.loading`,
									'Cancelling...'
								)}
							</>
						) : (
							t(
								`pages.shipment-detail.modal.${modals[shipmentStatus]}.confirm`,
								'Confirm'
							)
						)}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
