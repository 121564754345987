import type { InstantMeiliSearchInstance } from '@meilisearch/instant-meilisearch';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import { getGetKeyQueryKey, useGetKey, useMetaData } from '@uturn/api/v1';
import { useEffect, useState } from 'react';
import { InstantSearch } from 'react-instantsearch';

export const SearchProvider = ({ children }: { children: React.ReactNode }) => {
	const { data } = useMetaData();
	const [searchClient, setSearchClient] =
		useState<InstantMeiliSearchInstance>();

	const initialQueryKey = getGetKeyQueryKey();
	const { data: result } = useGetKey({
		query: {
			staleTime: 600000, // 10 min.
			queryKey: [...initialQueryKey, data?.data.user?.id],
		},
	});

	useEffect(() => {
		if (result) {
			setSearchClient(
				instantMeiliSearch(import.meta.env.VITE_MEILISEARCH_HOST, result.data)
			);
		}
	}, [result]);

	return (
		searchClient && (
			<InstantSearch
				indexName={`${import.meta.env.VITE_MEILISEARCH_INDEX}`}
				searchClient={searchClient}
			>
				{children}
			</InstantSearch>
		)
	);
};
