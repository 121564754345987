import { useFeatureFlagEnabled } from 'posthog-js/react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import BulkOption from './bulk-option';
import ManualOption from './manual-option';
import { Page } from '@uturn/portal/components/page';

const FEATURE_FLAG_KEY = 'shipment_bulk_create';

export function AddShipment() {
	const { t } = useTranslation();
	const featureFlagEnabled = useFeatureFlagEnabled(FEATURE_FLAG_KEY);
	return (
		<>
			<Helmet title={t('pages.addshipment.title', 'Add new shipment')!} />
			<Page
				title={t('pages.addshipment.title', 'Add new shipment')!}
				backButton={{
					label: 'Shipments',
					href: '/shipments',
				}}
				className="m-auto md:max-w-2xl"
			>
				<div className="flex flex-col gap-8">
					<ManualOption />
				</div>
				{featureFlagEnabled && (
					<>
						<div className="max-w-2xl flex flex-row gap-4 items-center">
							<div className="flex-1 border-t border-secondary-300/60" />
							<span className="text-md font-medium text-secondary-400">OR</span>
							<div className="flex-1 border-t border-secondary-300/60" />
						</div>
						<div className="max-w-2xl">
							<BulkOption />
						</div>
					</>
				)}
			</Page>
		</>
	);
}
