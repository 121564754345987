import {
	faPlus,
	faSpinnerThird,
	faTrashCan,
} from '@fortawesome/pro-solid-svg-icons';
import { useQueryClient } from '@tanstack/react-query';
import type { UturnUserRequest, UturnUserResponse } from '@uturn/api/v1';
import { useFetchUturnUserById, useUpdateUserDetails } from '@uturn/api/v1';
import {
	Button,
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormFieldReadOnly,
	FormItem,
	FormLabel,
	FormMessage,
	Icon,
	Input,
	Separator,
	Switch,
	cn,
	useToast,
} from '@uturn/ui-kit';
import { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MetaDataContext } from '@uturn/portal/context';

export function AccountNotifications() {
	const { t } = useTranslation();
	const { toast } = useToast();

	const { metadata } = useContext(MetaDataContext);
	const { data: accountSettings, queryKey } = useFetchUturnUserById(
		metadata!.user?.id as number,
		{ query: { refetchOnWindowFocus: false } }
	);

	const queryClient = useQueryClient();

	const form = useForm<UturnUserRequest>({ defaultValues: {} });

	const formRef = useRef<HTMLFormElement>(null);

	/**
	 * Treat the main email differently.
	 *
	 * i.e.: As an email that is always present in
	 *       notificationEmails and is read-only.
	 *
	 * See fromResponse and toRequest functions for
	 * detailed logic.
	 */
	const [email, setEmail] = useState('');
	const [emails, setEmails] = useState<string[]>([]);

	const fromResponse = (values: UturnUserResponse) => {
		const { email: defaultEmail, notificationEmails } = values;
		setEmail(defaultEmail);
		setEmails(notificationEmails.filter((item) => item !== defaultEmail));
	};

	const toRequest = () => {
		const newEmails = emails.filter((item) => !!item);
		form.setValue('notificationEmails', [email, ...newEmails]);
	};

	useEffect(() => {
		if (!accountSettings) return;

		form.reset(accountSettings.data);
		fromResponse(accountSettings.data);
	}, [accountSettings]);

	const watchNotifications = form.watch('notifications');

	const handleEmailAdd = () => {
		setEmails((current) => [...current, '']);
	};

	const handleEmailDelete = (index: number) => {
		setEmails((current) => current.filter((_, i) => i !== index));
	};

	const handleEmailChange = (index: number, value: string) => {
		setEmails((current) =>
			current.map((item, i) => (i === index ? value : item))
		);
	};

	const handleFormSubmit = () => {
		if (formRef.current === null) return;

		toRequest();

		formRef.current.dispatchEvent(
			new Event('submit', { cancelable: true, bubbles: true })
		);
	};

	const handleNotificationUpdate = (index: number, enabled: boolean) => {
		form.setValue(`notifications.${index}.enabled`, enabled);

		handleFormSubmit();
	};

	const { mutate, isLoading } = useUpdateUserDetails({
		mutation: {
			onSuccess: () => {
				queryClient.invalidateQueries(queryKey);
				toast({
					title: t('toast.success-title', 'Success!')!,
					description: t(
						'pages.user.settings.notifications.save.success',
						'User has been updated!'
					),
				});
			},
			onError: () => {
				toast({
					title: t('toast.error-title', 'Uh oh! Something went wrong.')!,
					description: t('general.try-again-later', 'Please try again later.'),
				});
			},
		},
	});

	const onSubmit = (formData: UturnUserRequest) => {
		mutate({
			id: metadata!.user?.id as number,
			data: formData,
		});
	};

	return (
		<>
			<Helmet
				title={
					t(
						'pages.user.settings.notifications.title',
						'Account - Notifications'
					)!
				}
			/>
			<div className="space-y-6">
				<div>
					<h3 className="text-lg font-medium">
						{t('pages.user.settings.notifications.title', 'Notifications')}
					</h3>
					<p className="text-sm text-muted-foreground">
						{t(
							'pages.user.settings.notifications.description',
							'Configure how you receive notifications.'
						)}
					</p>
				</div>
				<Separator />
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(onSubmit)}
						ref={formRef}
						className="space-y-8"
					>
						<div>
							<FormLabel>
								{t(
									'pages.user.settings.notifications.emails.title',
									'Notification emails'
								)}
							</FormLabel>
							<FormDescription>
								{t(
									'pages.user.settings.notifications.emails.description',
									'Add emails on which you want to receive notifications.'
								)}
							</FormDescription>
							<FormFieldReadOnly className="mt-2" value={email} />
							{emails.map((value, index) => {
								return (
									<FormItem key={`notificationEmails.${index}`}>
										<FormControl>
											<div className="flex space-x-2">
												<Input
													className="mt-2"
													type="email"
													defaultValue={value}
													onChange={(event) =>
														handleEmailChange(index, event.target.value)
													}
												/>
												{index !== 0 && (
													<Button
														type="button"
														className="mt-2"
														variant="destructive"
														size="icon"
														onClick={() => handleEmailDelete(index)}
													>
														<Icon icon={faTrashCan} />
													</Button>
												)}
											</div>
										</FormControl>
										<FormMessage />
									</FormItem>
								);
							})}
							<Button
								type="button"
								className="mt-2 flex gap-2 p-0"
								variant="link"
								onClick={handleEmailAdd}
							>
								<Icon icon={faPlus} />
								<span>
									{t(
										'pages.user.settings.notifications.emails.add_email',
										'Add email'
									)}
								</span>
							</Button>
						</div>
						<Button
							type="button"
							disabled={isLoading}
							onClick={handleFormSubmit}
						>
							{isLoading && (
								<Icon spin className="mr-3" icon={faSpinnerThird} />
							)}
							{t(
								'pages.user.settings.notifications.actions.save',
								'Update notification emails'
							)}
						</Button>
						<div className="pt-4">
							{watchNotifications?.map((notificationOption, index) => (
								<FormField
									key={notificationOption.code}
									control={form.control}
									name={`notifications.${index}`}
									render={() => (
										<FormItem>
											<FormLabel className={cn(index !== 0 && 'sr-only')}>
												{t(
													'pages.user.settings.notifications.title',
													'Notifications'
												)}
											</FormLabel>
											<FormDescription className={cn(index !== 0 && 'sr-only')}>
												{t(
													'pages.user.settings.notifications.description',
													'Configure which notifications you want to receive.'
												)}
											</FormDescription>
											<FormControl>
												<div className="flex flex-row items-center justify-between rounded-lg border p-4">
													<div className="space-y-0.5">
														<FormLabel>
															{t(
																`pages.user.settings.notifications.options.${notificationOption.code}.title`,
																`${notificationOption.code}`
															)}
														</FormLabel>
														<FormDescription>
															{t(
																`pages.user.settings.notifications.options.${notificationOption.code}.description`,
																`	Receive emails for friend requests, follows, and
															more.`
															)}
														</FormDescription>
													</div>
													<Switch
														checked={notificationOption.enabled}
														onCheckedChange={(checked) =>
															handleNotificationUpdate(index, checked)
														}
													/>
												</div>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							))}
						</div>
					</form>
				</Form>
			</div>
		</>
	);
}
