import { createPortal } from 'react-dom';
import { twJoin } from 'tailwind-merge';
import { type PageStickyBarProps } from './page-sticky-bar.types';

export function PageStickyBar({ children, position }: PageStickyBarProps) {
	return createPortal(
		<div
			className={twJoin(
				'print:hidden py-4 sticky border bg-card/95 backdrop-blur supports-[backdrop-filter]:bg-card/60',
				position === 'bottom' ? 'bottom-0' : 'top-0'
			)}
		>
			<div className="px-4 lg:px-8 3xl:px-0 3xl:w-2/3 m-auto">{children}</div>
		</div>,
		document.body
	);
}
