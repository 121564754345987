import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import type { Carrier } from '@uturn/api/v2';
import {
	Button,
	Callout,
	CalloutIcon,
	CalloutTitle,
	Icon,
	Sheet,
	SheetContent,
	SheetTrigger,
} from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';
import { ReviewCarrierSheet } from '@uturn/portal/modules/shipments/components/shipment-sheet';
import type { CarrierReview } from '@uturn/portal/types/shipment';

export function CarrierReviewBanner({
	shipmentNumber,
	carrier,
	carrierReview,
}: {
	shipmentNumber: string;
	carrier: Carrier;
	carrierReview: CarrierReview;
}) {
	const { t } = useTranslation();

	return (
		<Callout>
			<CalloutIcon>
				<Icon icon={faInfoCircle} />
			</CalloutIcon>
			<CalloutTitle>
				{t(
					'pages.shipment-detail.banner.carrier-review.description-start',
					`You haven't reviewed the carrier`
				)}{' '}
				{/* 
				// TODO: Improve this:
				         'pages.shipment-detail.banner.carrier-review.description' Tolgee key
						 was split into 'start' and 'end' because carrier.name is required to be 
						 bold within the translation.
						 <strong>{{carrierName}}</strong> does not seem to be supported within Tolgee
						 even though it says it does.
						 Could not find a better way to do this for now.
				 */}
				<strong>{carrier.name}</strong>{' '}
				{t(
					'pages.shipment-detail.banner.carrier-review.description-end',
					`for this shipment yet. Your feedback helps us improve and ensures better carrier matches for your shipments.`
				)}
			</CalloutTitle>
			<Sheet>
				<SheetTrigger asChild>
					<Button size="sm" className="flex gap-2 items-center">
						{t(
							'pages.shipment-detail.banner.carrier-review.button',
							'Review carrier'
						)}
					</Button>
				</SheetTrigger>
				<SheetContent>
					<ReviewCarrierSheet
						shipmentNumber={Number(shipmentNumber)}
						carrier={carrier}
						carrierReview={carrierReview}
					/>
				</SheetContent>
			</Sheet>
		</Callout>
	);
}
