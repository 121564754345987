import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { type ShipmentsExportParams, shipmentsExport } from '@uturn/api/v1';
import {
	Button,
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DropdownMenuItem,
	Icon,
	sonner,
} from '@uturn/ui-kit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadFile } from '@uturn/portal/utils';

export function ShipmentsExportDropdownMenuItem({
	setOpenModal,
}: {
	setOpenModal: (open: boolean) => void;
}) {
	const { t } = useTranslation();

	return (
		<DropdownMenuItem onSelect={() => setOpenModal(true)}>
			{t('pages.shippers.actions.export', 'Export')}
		</DropdownMenuItem>
	);
}

export function ShipmentsExportModal({
	exportFilters,
	isOpenModal,
	setOpenModal,
	totalShipments = 0,
}: {
	exportFilters: ShipmentsExportParams;
	isOpenModal: boolean;
	setOpenModal: (open: boolean) => void;
	totalShipments: number | undefined;
}) {
	const { t } = useTranslation();
	const [exporting, setExporting] = useState(false);

	return (
		<Dialog onOpenChange={setOpenModal} open={isOpenModal}>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle>
						{t('pages.shipments_export.dialog.heading', 'Export shipments')}
					</DialogTitle>
					<DialogDescription>
						{t(
							'pages.shipments_export.dialog.description',
							'Change the shipment filters to modify the export'
						)}
					</DialogDescription>
				</DialogHeader>
				<DialogFooter>
					<Button
						disabled={exporting}
						onClick={() => {
							if (!totalShipments) {
								sonner.warning(
									t(
										'pages.shipments_export.dialog.button.empty',
										'Nothing to export'
									)
								);
								return;
							}
							setExporting(true);
							downloadFile(
								shipmentsExport(exportFilters),
								`shipments-export${new Date()
									.toISOString()
									.replace(/[^0-9]/g, '')
									.slice(0, -3)}.xlsx`
							).finally(() => {
								setOpenModal(false);
								setExporting(false);
							});
						}}
					>
						{exporting ? (
							<>
								<Icon className="mr-2 h-4 w-4" icon={faSpinnerThird} spin />
								{t(
									'pages.shipments_export.dialog.button.loading',
									'Exporting...'
								)}
							</>
						) : (
							t('pages.shipments_export.dialog.button.submit', 'Export')
						)}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
