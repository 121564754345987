import {
	FormControl,
	FormField,
	FormFieldReadOnly,
	FormItem,
	FormLabel,
	FormMessage,
	Input,
	InputAddon,
	Textarea,
} from '@uturn/ui-kit';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiddenFieldLabelInfo } from '@uturn/portal/modules/shipments/components/hidden-field-label-info';
import { ShipmentDetailContext } from '@uturn/portal/modules/shipments/providers/shipment-detail';
import type { FormValues } from '@uturn/portal/modules/shipments/schema';
import { isFieldReadOnly } from '@uturn/portal/modules/shipments/utils';
import { ContainerStatus } from '@uturn/portal/types/shipment';

export function CargoDetails() {
	const { t } = useTranslation();
	const form = useFormContext<FormValues>();

	const { readOnlyFields } = useContext(ShipmentDetailContext);

	const watchContainerStatus = form.watch('containerStatus');

	return (
		<div className="grid grid-cols-1 sm:grid-cols-6 w-full gap-3">
			<div className="sm:col-span-full">
				<h3 className="text-lg font-heading font-medium tracking-tight">
					{t(
						'pages.create_shipment.shipment_details.cargo_details.heading',
						'Cargo details'
					)}
				</h3>
			</div>
			<div className="grid grid-cols-1 gap-6 w-full sm:grid-cols-6 sm:col-span-full">
				<div className="sm:col-span-3">
					<FormField
						control={form.control}
						name="grossWeight.quantity"
						render={({ field }) => (
							<FormItem className="relative">
								<FormLabel
									className={
										watchContainerStatus === ContainerStatus.FULL
											? 'required-asterix'
											: ''
									}
								>
									{t(
										'pages.shipment-details.cargo_details.gross_weight.label',
										'Cargo gross weight'
									)}
								</FormLabel>
								{isFieldReadOnly(readOnlyFields, 'grossWeight.quantity') ? (
									<FormFieldReadOnly
										value={`${field.value} ${t(
											'general.shorthands.units.kilogram',
											'kg'
										)}`}
									/>
								) : (
									<div className="relative">
										<FormControl>
											<Input
												{...field}
												className="pr-9"
												type="number"
												onChange={(e) => field.onChange(e.target.valueAsNumber)}
												disabled={
													watchContainerStatus === ContainerStatus.EMPTY
												}
											/>
										</FormControl>
										<InputAddon type="trailing">
											<span className="text-secondary-500 sm:text-sm">
												{t('general.shorthands.units.kilogram', 'kg')}
											</span>
										</InputAddon>
									</div>
								)}
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<div className="sm:col-span-3">
					<FormField
						control={form.control}
						name="unCode"
						render={({ field }) => (
							<FormItem>
								<FormLabel>
									{t(
										'pages.shipment-details.cargo_details.un_code.label',
										'UN code'
									)}
								</FormLabel>
								{isFieldReadOnly(readOnlyFields, 'unCode') ? (
									<FormFieldReadOnly value={field.value ?? ''} />
								) : (
									<FormControl>
										<Input {...field} />
									</FormControl>
								)}
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<div className="sm:col-span-full">
					<FormField
						control={form.control}
						name="cargoDescription"
						render={({ field }) => (
							<FormItem>
								<FormLabel className="flex items-center">
									{t(
										'pages.shipment-details.cargo_details.description.label',
										'Description'
									)}
									<HiddenFieldLabelInfo />
								</FormLabel>
								{isFieldReadOnly(readOnlyFields, 'cargoDescription') ? (
									<FormFieldReadOnly value={field.value ?? ''} />
								) : (
									<FormControl>
										<Textarea {...field} rows={2} />
									</FormControl>
								)}
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
			</div>
		</div>
	);
}
