import type { CurrencyType, ExtraCostResponse } from '@uturn/api/v1';

import {
	Card,
	CardContent,
	CardFooter,
	FormFieldReadOnly,
	Label,
} from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';
import { AdditionalCostAction } from './additional-cost-action';
import { formatPrice } from '@uturn/portal/utils';

export function AdditionalCost({
	cost,
	currencyCode,
	invalidateQueries,
}: {
	cost: ExtraCostResponse;
	currencyCode: CurrencyType;
	invalidateQueries: () => void;
}) {
	const { t } = useTranslation();
	return (
		<Card className="py-4">
			<CardContent className="grid gap-3">
				<div>
					<Label>
						{t(
							'pages.shipment-detail.sheet.financials.additional-costs.status',
							'Status'
						)}
					</Label>
					<FormFieldReadOnly value={cost.status?.name ?? '-'} />
				</div>
				<div>
					<Label>
						{t(
							'pages.shipment-detail.sheet.financials.additional-costs.cost-type',
							'Cost type'
						)}
					</Label>
					<FormFieldReadOnly value={cost.typeName ?? '-'} />
				</div>

				<div className="flex justify-between">
					<div>
						<Label>
							{t(
								'pages.shipment-detail.sheet.financials.additional-costs.amount',
								'Amount'
							)}
						</Label>
						<FormFieldReadOnly
							value={formatPrice(cost.amount ?? 0, currencyCode)}
						/>
					</div>
					<div>
						<Label>
							{t(
								'pages.shipment-detail.sheet.financials.additional-costs.fee',
								'Fee'
							)}
						</Label>
						<FormFieldReadOnly
							value={formatPrice(cost.fee ?? 0, currencyCode)}
						/>
					</div>
					<div>
						<Label>
							{t(
								'pages.shipment-detail.sheet.financials.additional-costs.total',
								'Total'
							)}
						</Label>
						<FormFieldReadOnly
							value={formatPrice(
								(cost.amount ?? 0) + (cost?.fee ?? 0),
								currencyCode
							)}
						/>
					</div>
				</div>
				<div>
					<Label>
						{t(
							'pages.shipment-detail.sheet.financials.additional-costs.carrier-remark',
							'Carrier remark'
						)}
					</Label>
					<FormFieldReadOnly value={cost.carrierComment ?? '-'} />
				</div>
				<div>
					<Label>
						{t(
							'pages.shipment-detail.sheet.financials.additional-costs.uturn-remark',
							'UTURN remark'
						)}
					</Label>
					<FormFieldReadOnly value={cost.uturnComment ?? '-'} />
				</div>
				{cost.shipperComment && (
					<div>
						<Label>
							{t(
								'pages.shipment-detail.sheet.financials.additional-costs.shipper-comment',
								'Shipper comment'
							)}
						</Label>
						<FormFieldReadOnly value={cost.shipperComment ?? '-'} />
					</div>
				)}
			</CardContent>
			{cost.status?.code === 'WAITING_SHIPPER' && (
				<CardFooter>
					<AdditionalCostAction
						invalidateQueries={invalidateQueries}
						additionalCostId={cost.id!}
					/>
				</CardFooter>
			)}
		</Card>
	);
}
