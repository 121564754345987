import type { ShipmentStatusCount } from '@uturn/api/v1';
import { useTranslation } from 'react-i18next';

export function ShipmentGroupStatusTile({
	shipmentStatusCount,
}: {
	shipmentStatusCount: ShipmentStatusCount;
}) {
	const { t } = useTranslation();

	return (
		<div className="px-3 py-2 bg-secondary rounded w-24 text-left">
			<h4 className="flex flex-col">
				<span className="text-muted-foreground text-sm">
					{t(
						`pages.shipments.groups.overview.card.status-tile.${shipmentStatusCount.shipmentStatus}`,
						'Archived'
					)}
				</span>
				<span className="font-bold text-3xl tracking-tight leading-8">
					{shipmentStatusCount.count}
				</span>
			</h4>
		</div>
	);
}
