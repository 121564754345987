import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { CreateLaneDtoRequirementsItem, Frequency } from '@uturn/api/v1';
import {
	Button,
	Calendar,
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Icon,
	Input,
	InputAddon,
	MultiSelect,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
	Textarea,
	cn,
} from '@uturn/ui-kit';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MetaDataContext } from '@uturn/portal/context';
import { getCurrencySign } from '@uturn/portal/utils';

export function ShipmentForm({ disabled }: { disabled?: boolean }) {
	const { shipmentMetaData } = useContext(MetaDataContext);
	const form = useFormContext();
	const watchCurrencyCode = form.watch('pricePerContainer.currencyCode');
	const { t } = useTranslation();
	return (
		<Card>
			<CardHeader>
				<CardTitle>
					{t('pages.lanes.create.form.shipment.title', 'Shipment')}
				</CardTitle>
			</CardHeader>
			<CardContent className="space-y-3">
				<FormField
					control={form.control}
					name="reference"
					disabled={disabled}
					render={({ field }) => (
						<FormItem>
							<FormLabel>
								{t('pages.lanes.create.form.shipment.lane-name', 'Lane name')}
							</FormLabel>
							<FormControl>
								<Input {...field} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>

				<div className="flex gap-3">
					<FormField
						control={form.control}
						name="containerFrequency.value"
						disabled={disabled}
						render={({ field }) => (
							<FormItem>
								<FormLabel>
									{t(
										'pages.lanes.create.form.shipment.frequency.label',
										'Frequency'
									)}
									*
								</FormLabel>
								<FormControl>
									<Input
										placeholder={t(
											'pages.lanes.create.form.shipment.frequency.amount',
											'How many?'
										).toString()}
										type="number"
										{...field}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="containerFrequency.frequency"
						disabled={disabled}
						render={({ field }) => (
							<FormItem className="grow">
								<FormLabel>&nbsp;</FormLabel>
								<Select
									onValueChange={field.onChange}
									defaultValue={field.value}
									disabled={field.disabled}
								>
									<FormControl>
										<SelectTrigger>
											<SelectValue
												placeholder={
													<span>
														{t(
															'pages.lanes.create.form.shipment.frequency.recurrence',
															'And how often?'
														)}
													</span>
												}
											/>
										</SelectTrigger>
									</FormControl>
									<SelectContent>
										{Object.keys(Frequency).map((key) => (
											<SelectItem key={key} value={key}>
												{
													t(
														`general.recurrence.${key.toLowerCase()}`,
														key.toLowerCase()
													)!
												}
											</SelectItem>
										))}
									</SelectContent>
								</Select>
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<FormField
					control={form.control}
					name="pricePerContainer.quantity"
					disabled={disabled}
					render={({ field }) => (
						<FormItem>
							<FormLabel>
								{t(
									'pages.lanes.create.form.shipment.shipment-target-price',
									'Shipment target price (excl. UTURN Fee)'
								)}
							</FormLabel>
							<FormControl>
								<div className="relative">
									<Input type="number" className="pl-6" {...field} />
									<InputAddon type="leading">
										<span className="text-secondary-500 sm:text-sm">
											{getCurrencySign(
												watchCurrencyCode,
												shipmentMetaData?.currencies
											)}
										</span>
									</InputAddon>
								</div>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>

				<div className="grid grid-cols-2 gap-3">
					<FormField
						control={form.control}
						name="laneStartDate"
						disabled={disabled}
						render={({ field }) => (
							<FormItem className="flex flex-col">
								<FormLabel>
									{t(
										'pages.lanes.create.form.shipment.lane-starts',
										'Lane starts'
									)}
									*
								</FormLabel>
								<Popover>
									<PopoverTrigger asChild>
										<FormControl>
											<Button
												disabled={field.disabled}
												variant={'outline'}
												className={cn(
													'pl-3 text-left font-normal',
													!field.value && 'text-muted-foreground'
												)}
											>
												{field.value ? (
													Intl.DateTimeFormat().format(field.value)
												) : (
													<span>
														{t(
															'component.date-picker.pick-a-date',
															'Pick a date'
														)}
													</span>
												)}
												<Icon
													icon={faCalendar}
													className="ml-auto h-4 w-4 opacity-50"
												/>
											</Button>
										</FormControl>
									</PopoverTrigger>
									<PopoverContent className="w-auto p-0" align="start">
										<Calendar
											mode="single"
											selected={field.value}
											onSelect={field.onChange}
											disabled={(date) => {
												const endDate = form.getValues('laneEndDate');
												if (endDate) {
													const maximumStartDate = new Date(endDate);
													maximumStartDate.setDate(
														maximumStartDate.getDate() - 1
													);
													return date > maximumStartDate || date < new Date();
													// return date < minimumEndDate;
												}
												return date < new Date();
											}}
											initialFocus
										/>
									</PopoverContent>
								</Popover>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="laneEndDate"
						disabled={disabled}
						render={({ field }) => (
							<FormItem className="flex flex-col">
								<FormLabel>
									{t('pages.lanes.create.form.shipment.lane-ends', 'Lane ends')}
									*
								</FormLabel>
								<Popover>
									<PopoverTrigger asChild>
										<FormControl>
											<Button
												disabled={field.disabled}
												variant={'outline'}
												className={cn(
													'pl-3 text-left font-normal',
													!field.value && 'text-muted-foreground'
												)}
											>
												{field.value ? (
													Intl.DateTimeFormat().format(field.value)
												) : (
													<span>
														{t(
															'component.date-picker.pick-a-date',
															'Pick a date'
														)}
													</span>
												)}
												<Icon
													icon={faCalendar}
													className="ml-auto h-4 w-4 opacity-50"
												/>
											</Button>
										</FormControl>
									</PopoverTrigger>
									<PopoverContent className="w-auto p-0" align="start">
										<Calendar
											mode="single"
											selected={field.value}
											onSelect={field.onChange}
											disabled={(date) => {
												const startDate = form.getValues('laneStartDate');
												if (startDate) {
													const minimumEndDate = new Date(startDate);
													minimumEndDate.setDate(minimumEndDate.getDate() + 1);
													return date < minimumEndDate;
												}
												return date < new Date();
											}}
											initialFocus
										/>
									</PopoverContent>
								</Popover>
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<FormField
					control={form.control}
					name="remarks"
					disabled={disabled}
					render={({ field }) => (
						<FormItem>
							<FormLabel>
								{t(
									'pages.lanes.create.form.shipment.general-remarks.label',
									'General remarks'
								)}
							</FormLabel>
							<FormControl>
								<Textarea
									{...field}
									placeholder={
										t(
											'pages.lanes.create.form.shipment.general-remarks.placeholder',
											'Remarks'
										)!
									}
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="requirements"
					render={({ field }) => (
						<FormItem>
							<FormLabel>
								{t(
									'pages.lanes.create.form.shipment.requirements.label',
									'Requirements'
								)}
							</FormLabel>
							<MultiSelect
								onChange={field.onChange}
								placeholder={
									t(
										'pages.lanes.create.form.shipment.requirements.placeholder',
										'Select requirements'
									)!
								}
								selected={field.value ?? []}
								options={Object.keys(CreateLaneDtoRequirementsItem).map(
									(key) => {
										return {
											label:
												shipmentMetaData?.shipmentRequirements?.find(
													(requirement) => requirement.code === key
												)?.description ?? key,
											value: key,
										};
									}
								)}
							/>
							<FormMessage />
						</FormItem>
					)}
				/>
			</CardContent>
		</Card>
	);
}
