import { faMoneyBill } from '@fortawesome/pro-regular-svg-icons';
import {
	ShipmentDtoTsoStatus as OrderStatus,
	type ShipmentDtoShipmentStatus as ShipmentStatus,
} from '@uturn/api/v2';
import { Button, FormFieldReadOnly, Icon, Label } from '@uturn/ui-kit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FinancialsSheet } from '../financials-sheet';
import {
	shipmentStatusName,
	shipmentStatusTsoName,
} from '@uturn/portal/modules/shipments/utils';

export type ShipmentStatusInfoProps = {
	shipmentStatus?: ShipmentStatus;
	orderStatus?: OrderStatus;
};

export function ShipmentStatusInfo({
	shipmentStatus,
	orderStatus,
}: ShipmentStatusInfoProps) {
	const { id } = useParams();
	const { t } = useTranslation();

	const [openFinancialsSheet, setOpenFinancialsSheet] = useState(false);

	const showOrderStatus =
		orderStatus !== OrderStatus.CANCELLED &&
		orderStatus !== OrderStatus.OPEN &&
		orderStatus !== undefined;

	const showFinancials =
		orderStatus === OrderStatus.INVOICED ||
		orderStatus === OrderStatus.COST_DECLARED;

	return (
		<>
			<div className="grid grid-cols-1 gap-4 w-full sm:grid-cols-6">
				<div className="sm:col-span-full">
					<Label>
						{t('pages.order.shipment-details.status.heading', 'Status')}
					</Label>
					<FormFieldReadOnly value={shipmentStatusName(t)[shipmentStatus!]} />
				</div>
				{showOrderStatus && (
					<div className="sm:col-span-full">
						<Label>
							{t(
								'pages.order.shipment-details.tso-status.heading',
								'Order status'
							)}
						</Label>
						<FormFieldReadOnly value={shipmentStatusTsoName(t)[orderStatus!]} />
						<div className="flex gap-4 mt-2">
							{showFinancials && (
								<div className="sm:col-span-4">
									<Button
										variant="secondary"
										size="sm"
										className="flex gap-2 items-center"
										onClick={(e) => {
											e.preventDefault();
											setOpenFinancialsSheet(true);
										}}
									>
										<Icon
											icon={faMoneyBill}
											className="text-secondary-600 w-3.5 h-3.5"
										/>
										{t(
											'pages.order.shipment-details.actions.financials',
											'Financials'
										)}
									</Button>
								</div>
							)}
						</div>
					</div>
				)}
			</div>
			<FinancialsSheet
				openSheet={openFinancialsSheet}
				setOpenSheet={setOpenFinancialsSheet}
				shipmentNumber={id!}
			/>
		</>
	);
}
