import {
	type ShipmentLocationActionDto as LocationActionGET, // GET means from GET shipment
	ShipmentLocationActionDtoLocationActionType as LocationActionTypeGET, // GET means from GET shipment
	ShipmentLocationActionRequestDtoLocationActionType as LocationActionTypePOST, // POST means from POST shipment
	ShipmentLocationActionUpdateDtoLocationActionType as LocationActionTypePUT, // PUT means from PUT shipment
} from '@uturn/api/v1';
import type {
	ZodLocation,
	ZodLocationAction,
	ZodLocationActionLocation,
} from '@uturn/portal/modules/shipments/schema';
import type { AnyLocationAction } from '@uturn/portal/types/shipment';
import { TransportTypeCode } from '@uturn/portal/types/shipment';
import { getLocationQueryString } from '@uturn/portal/utils';
import { convertToIsoDateWithTimezone } from '@uturn/portal/utils/convert-to-iso-date-with-timezone';

type DefaultTransportTypeLocationActions = Record<
	TransportTypeCode,
	{ action: Partial<ZodLocationAction> }[]
>;

export const defaultTransportTypeLocationActions: DefaultTransportTypeLocationActions =
	{
		[TransportTypeCode.IMPORT]: [
			{
				action: {
					id: '1',
					sequenceNumber: 1,
					locationActionType: LocationActionTypePOST.PICKUP,
				},
			},
			{
				action: {
					id: '2',
					sequenceNumber: 2,
					locationActionType: LocationActionTypePOST.DELIVER,
				},
			},
			{
				action: {
					id: '3',
					sequenceNumber: 3,
					locationActionType: LocationActionTypePOST.DROP,
				},
			},
		],
		[TransportTypeCode.EXPORT]: [
			{
				action: {
					id: '1',
					sequenceNumber: 1,
					locationActionType: LocationActionTypePOST.PICKUP,
				},
			},
			{
				action: {
					id: '2',
					sequenceNumber: 2,
					locationActionType: LocationActionTypePOST.LOAD,
				},
			},
			{
				action: {
					id: '3',
					sequenceNumber: 3,
					locationActionType: LocationActionTypePOST.DROP,
				},
			},
		],
		[TransportTypeCode.SHUNT]: [
			{
				action: {
					id: '1',
					sequenceNumber: 1,
					locationActionType: LocationActionTypePOST.PICKUP,
				},
			},
			{
				action: {
					id: '2',
					sequenceNumber: 2,
					locationActionType: LocationActionTypePOST.DROP,
				},
			},
		],
	};

const locationActionTypeMappings: Record<
	LocationActionTypeGET,
	LocationActionTypePUT
> = {
	[LocationActionTypeGET['Pick-up']]: LocationActionTypePUT.PICKUP,
	[LocationActionTypeGET.Deliver]: LocationActionTypePUT.DELIVER,
	[LocationActionTypeGET.Load]: LocationActionTypePUT.LOAD,
	[LocationActionTypeGET.Drop]: LocationActionTypePUT.DROP,
	[LocationActionTypeGET.Stop]: LocationActionTypePUT.STOP,
	[LocationActionTypeGET['']]: LocationActionTypePUT.STOP, // TODO: STOP is the default value?
};

export const toLocationActionType = (
	actionType: string
): LocationActionTypePUT => {
	if (actionType in LocationActionTypeGET) {
		return locationActionTypeMappings[<LocationActionTypeGET>actionType];
	}
	return actionType as LocationActionTypePUT;
};

export const toZodLocations = (
	locationActions: Partial<LocationActionGET>[]
) => {
	return (
		locationActions.map(
			(item) =>
				({
					countryCode: item?.location?.address?.countryCode,
					action: {
						...item,
						dateFrom: item?.dateFrom
							? convertToIsoDateWithTimezone(new Date(item?.dateFrom))
							: undefined,
						dateUntil: item?.dateUntil
							? convertToIsoDateWithTimezone(new Date(item?.dateUntil))
							: undefined,
						shippingLineId: item.shippingLine?.id ?? 0,
					},
				} as ZodLocation)
		) ?? []
	);
};

/**
 * For the returned locationActions data, the code is similar to the zod
 * transformation in apps/portal/src/modules/shipments/schema/form.ts.
 *
 * NOTE: Except for:
 * - the additional "locationActionType" data transformation.
 * - the missing "location" data transformation.
 */
export const fromZodLocations = (locations: Partial<ZodLocation>[]) => {
	const locationActions =
		locations.map((item, index) => {
			const { action } = item;
			const {
				id: locationActionId,
				location,
				shippingLine,
				shippingLineId,
				...rest
			} = action ?? {};
			const locationAction: AnyLocationAction = {
				...rest,
				id: typeof locationActionId === 'number' ? locationActionId : undefined,
				location: { ...location },
				locationActionType: toLocationActionType(
					action?.locationActionType ?? ''
				),
				shippingLineId: shippingLineId === -1 ? undefined : shippingLineId,
				sequenceNumber: index + 1,
			};
			return locationAction;
		}) ?? [];
	const countryCodes = locations.map((item) => item.countryCode) ?? [];
	return { locationActions, countryCodes };
};

export const renderLocation = (data: ZodLocationActionLocation): string => {
	if (data) {
		const { name, address } = data;
		if (address && name) {
			return `${name} ${getLocationQueryString(address)}`;
		}
	}
	return '';
};
