import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { Button, Card, CardContent, CardHeader, Icon } from '@uturn/ui-kit';
import { type PricingTier } from './pricing-tiers.types';

export function PricingTierCard({
	id,
	mostPopular,
	name,
	features,
	price,
	billingFrequency,
	description,
	action,
}: PricingTier) {
	return (
		<Card>
			<CardHeader>
				<h3 id={id} className={'text-lg font-semibold leading-8'}>
					{name}
				</h3>
				{description && description !== '' && (
					<p className="mt-4 text-sm leading-6 text-muted-foreground">
						{description}
					</p>
				)}
			</CardHeader>
			<CardContent>
				<p className="flex flex-row items-baseline gap-x-1">
					<span className="text-4xl font-bold tracking-tight">{price}</span>
					<span className="text-xs font-semibold ">{billingFrequency}</span>
				</p>
				<div className="flex mt-6 flex-col">
					<Button
						disabled={action.handleOnClick === undefined}
						onClick={action.handleOnClick}
						variant={mostPopular ? 'default' : 'secondary'}
						aria-describedby={id}
					>
						{action.label}
					</Button>
				</div>
				<ul className="mt-8 space-y-3 text-sm leading-6 ">
					{features.map((feature) => (
						<li key={feature} className="flex gap-x-3">
							<Icon
								icon={faCheck}
								className="h-6 w-5 flex-none text-primary"
								aria-hidden="true"
							/>
							{feature}
						</li>
					))}
				</ul>
			</CardContent>
		</Card>
	);
}
