import type { QueryKey } from '@tanstack/react-query';
import type {
	GroupDto,
	PageableResponseGroupDto,
	ShipmentStatusCount,
} from '@uturn/api/v1';
import { ShipmentStatusCountShipmentStatus } from '@uturn/api/v1';
import { GroupedShipments as GroupedShipmentEmptyState } from '@uturn/design-tokens/empty-states';
import { EmptyState } from '@uturn/ui';

import { Button, PaginationCompact } from '@uturn/ui-kit';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { ShipmentGroupCard } from '../components';
import { FullStory } from '@uturn/portal/services';

function GroupedShipmentsEmptyState() {
	const { t } = useTranslation();
	return (
		<EmptyState>
			<GroupedShipmentEmptyState className="md:h-[20rem]" />
			<h2 className="text text-2xl font-bold leading-7">
				{t('pages.shipments.groups.emptyState.title', 'Let’s group')}
			</h2>
			<p className="text-muted-foreground text leading-6">
				{t(
					'pages.shipments.groups.emptyState.description',
					"Easily manage and monitor identical shipments as a group by creating 'grouped shipments'. Streamline your process for greater efficiency."
				)}
			</p>
			<div>
				<Link to="/shipments/add">
					<Button>{t('pages.shipments.actions.start', 'Get started')}</Button>
				</Link>
			</div>
		</EmptyState>
	);
}

function findShipmentStatusCount(
	shipmentStatusCount: ShipmentStatusCount[],
	shipmentStatus: ShipmentStatusCountShipmentStatus
) {
	const status = shipmentStatusCount.find(
		(statusCount: ShipmentStatusCount) =>
			statusCount.shipmentStatus === shipmentStatus
	);

	return status?.count ?? 0;
}

export function ShipmentGroupsPage({
	data: { data: groups, totalElements, pageSize },
	page,
	groupsQueryKey,
	setPage,
}: {
	data: PageableResponseGroupDto;
	page: number;
	groupsQueryKey: QueryKey;
	setPage: (page: number) => void;
}) {
	const totalPages = useMemo(() => {
		return Math.ceil((totalElements ?? 1) / (pageSize ?? 1));
	}, []);

	return groups! && groups.length > 0 ? (
		<div className="grid grid-cols-1 gap-6 pt-2">
			<div className="grid grid-cols-1 md:grid-cols-2 gap-3">
				{groups.map((group: GroupDto, index: number) => (
					<ShipmentGroupCard
						key={index}
						groupsQueryKey={groupsQueryKey}
						onClick={() => {
							FullStory.trackEvent({
								name: 'Group Card Clicked',
								properties: {
									name: 'Group Card',
									group_name: group.name,
									group_id: group.id,
									shipments: {
										drafted: findShipmentStatusCount(
											group.shipmentStatusCount,
											ShipmentStatusCountShipmentStatus.CONCEPT
										),
										published: findShipmentStatusCount(
											group.shipmentStatusCount,
											ShipmentStatusCountShipmentStatus.PUBLISHED
										),
										quoted: findShipmentStatusCount(
											group.shipmentStatusCount,
											ShipmentStatusCountShipmentStatus.QUOTED
										),
										assigned: findShipmentStatusCount(
											group.shipmentStatusCount,
											ShipmentStatusCountShipmentStatus.CARRIER_ASSIGNED
										),
									},
								},
								schema: {
									name: 'str',
									group_name: 'str',
									group_id: 'int',
									shipments: {
										drafted: 'int',
										published: 'int',
										quoted: 'int',
										assigned: 'int',
									},
								},
							});
						}}
						{...group}
					/>
				))}
			</div>

			<PaginationCompact
				page={page}
				setPage={setPage}
				totalPages={totalPages}
				pageLimit={8}
			/>
		</div>
	) : (
		<GroupedShipmentsEmptyState />
	);
}
