import { type RouteDetails } from '@uturn/api/v1';
import { Card } from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';
import {
	formatCo2Emission,
	formatDistance,
	formatDuration,
} from '@uturn/portal/utils';

export function RouteInformation({ data }: { data: RouteDetails }) {
	const { distance, duration, co2Emission } = data;
	const { t } = useTranslation();
	return (
		<div className="absolute top-0 z-10 w-full px-5 pt-5">
			<Card className="flex justify-between px-10 py-5">
				<div className="flex flex-col items-center">
					<span className="text-secondary-400 text-sm">
						{t(
							'pages.price-calculator.route-info-headings.distance',
							'Distance'
						)}
					</span>
					<span className="text-secondary-800 text-2xl font-bold">
						{formatDistance(distance.quantity ?? 0, distance.unit!)}
					</span>
				</div>
				<div className="flex flex-col items-center">
					<span className="text-secondary-400 text-sm">
						{t(
							'pages.price-calculator.route-info-headings.duration',
							'Duration'
						)}
					</span>
					<span className="text-secondary-800 text-2xl font-bold">
						{formatDuration(duration.quantity ?? 0, duration.unit!)}
					</span>
				</div>
				<div className="flex flex-col items-center">
					<span className="text-secondary-400 text-sm">
						{t(
							'pages.price-calculator.route-info-headings.emission',
							'CO₂ emission'
						)}
					</span>
					<span className="text-secondary-800 text-2xl  font-bold">
						{formatCo2Emission(co2Emission.quantity ?? 0, co2Emission.unit!)}
					</span>
				</div>
			</Card>
		</div>
	);
}
