import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import {
	type ValidationsResult,
	newValidationsResult,
	validationsError,
} from '@uturn/portal/types';
import { TypeOfShipment } from '@uturn/portal/types/shipment';

export function useGroupedShipmentValidation() {
	const { t } = useTranslation();
	/**
	 * Grouped shipments validation.
	 *
	 * @param newGroupName
	 * @param numberOfCopiesToBeCreated
	 * @param typeOfShipment
	 * @param validationsResult
	 * @returns
	 */
	const validateGroupedShipment = (
		newGroupName: string,
		numberOfCopiesToBeCreated: number,
		typeOfShipment: TypeOfShipment,
		validationsResult?: ValidationsResult
	): ValidationsResult => {
		let results = validationsResult ?? newValidationsResult();

		if (typeOfShipment !== TypeOfShipment.GROUP) {
			return results;
		}

		const newGroupNameSchema = z
			.string({
				required_error: t(
					'general.errors.form_validation.required',
					'Required.'
				)!,
			})
			.min(2);

		try {
			newGroupNameSchema.parse(newGroupName);
		} catch (err) {
			if (err instanceof z.ZodError) {
				results = validationsError(
					results,
					err.issues[0].message,
					'newGroup.name'
				);
			}
		}

		const numberOfCopiesToBeCreatedSchema = z.number().min(2);

		try {
			numberOfCopiesToBeCreatedSchema.parse(numberOfCopiesToBeCreated);
		} catch (err) {
			if (err instanceof z.ZodError) {
				results = validationsError(
					results,
					err.issues[0].message,
					'numberOfCopiesToBeCreated'
				);
			}
		}
		return results;
	};
	return { validateGroupedShipment };
}
