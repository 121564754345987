import {
	DevTools,
	FormatSimple,
	I18nextPlugin,
	Tolgee,
	withTolgee,
} from '@tolgee/i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export const availableLanguages = [
	{ code: 'en', country: 'gb' },
	{ code: 'de', country: 'de' },
	{ code: 'nl', country: 'nl' },
	{ code: 'fr', country: 'fr' },
	{ code: 'es', country: 'es' },
];

const tolgee = Tolgee()
	.use(DevTools())
	.use(I18nextPlugin())
	.use(FormatSimple())
	.init({
		apiUrl: import.meta.env.VITE_APP_TOLGEE_API_URL,
		apiKey: import.meta.env.VITE_APP_TOLGEE_API_KEY,
		defaultLanguage: 'en',
		defaultNs: 'translation',
		availableLanguages: availableLanguages.map(({ code }) => code),
		staticData: {
			'en:translation': () => import('./translation/en.json'),
			'en:zod': () => import('zod-i18n-map/locales/en/zod.json'),
			'en:formValidation': () => import('./formValidation/en.json'),

			'de:translation': () => import('./translation/de.json'),
			'de:zod': () => import('zod-i18n-map/locales/de/zod.json'),
			'de:formValidation': () => import('./formValidation/de.json'),

			'fr:translation': () => import('./translation/fr.json'),
			'fr:zod': () => import('zod-i18n-map/locales/fr/zod.json'),
			'fr:formValidation': () => import('./formValidation/fr.json'),

			'nl:translation': () => import('./translation/nl.json'),
			'nl:zod': () => import('zod-i18n-map/locales/nl/zod.json'),
			'nl:formValidation': () => import('./formValidation/nl.json'),

			'es:translation': () => import('./translation/es.json'),
			'es:zod': () => import('zod-i18n-map/locales/es/zod.json'),
			'es:formValidation': () => import('./formValidation/es.json'),
		},
	});

withTolgee(i18n, tolgee)
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		ns: ['translation', 'zod', 'formValidation'],
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false,
		},
		detection: {
			convertDetectedLanguage: (lng) => lng.split('-')[0],
		},
	});

export default i18n;
export const locales = i18n;
