import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { Logo } from '@uturn/ui';
import { Icon } from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';

export function LoadingSpan() {
	const { t } = useTranslation();

	return <span>{t('general.loading', 'Loading...')}</span>;
}

export function LoadingSpinnerWithLogo() {
	const { t } = useTranslation();

	return (
		<div className="w-screen h-screen justify-center gap-3 flex flex-col items-center text-3xl font-bold text-secondary-500">
			<div>
				<Logo height="50" />
			</div>
			<div>{t('general.loading', 'Loading...')}</div>
			<div>
				<Icon icon={faCircleNotch} spin />
			</div>
		</div>
	);
}
