import {
	type AddressLookupResponse,
	type ShipmentLocationActionRequestDtoLocationActionType as LocationActionType,
	lookUpShippingLocation,
} from '@uturn/api/v1';
import { ShipmentDtoShipmentStatus as ShipmentStatus } from '@uturn/api/v2';
import {
	Badge,
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	useToast,
} from '@uturn/ui-kit';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddShipmentLocation } from './add-shipment-location';
import { ShipmentLocation } from './shipment-location';
import { MetaDataContext } from '@uturn/portal/context';
import { ShipmentDetailContext } from '@uturn/portal/modules/shipments/providers/shipment-detail';
import { type FormValues } from '@uturn/portal/modules/shipments/schema';
import {
	isShipmentMatched,
	transportTypeName,
} from '@uturn/portal/modules/shipments/utils';
import { FullStory } from '@uturn/portal/services';
import { type TransportTypeCode } from '@uturn/portal/types/shipment';
import { formatSuggestions } from '@uturn/portal/utils/format-address-lookup-suggestions';

export type RouteSectionProps = {
	shipmentNumber?: number;
	shipmentStatus?: ShipmentStatus;
	isReadOnly?: boolean;
	transportType?: TransportTypeCode;
};

export function RouteSection({
	shipmentNumber,
	shipmentStatus = ShipmentStatus.UNKNOWN,
	isReadOnly = false,
	transportType,
}: RouteSectionProps) {
	const { t } = useTranslation();
	const { toast } = useToast();
	const { readOnlyFields } = useContext(ShipmentDetailContext);
	const { shipmentMetaData, shipmentMaxRoutes } = useContext(MetaDataContext);
	const form = useFormContext<FormValues>();
	const { fields, remove, insert } = useFieldArray({
		control: form.control,
		name: 'locations',
	});

	const [suggestions, setSuggestions] = useState<string[]>([]);
	const [autoCompleteValue, setAutoCompleteValue] = useState('');
	const [autoCompleteData, setAutoCompleteData] = useState<
		AddressLookupResponse[]
	>([]);

	const fetchAndRenderLocations = useCallback(async () => {
		if (!autoCompleteValue || autoCompleteValue === '') {
			setSuggestions([]);
			return;
		}

		const { data: shippingLocation } = await lookUpShippingLocation({
			searchText: autoCompleteValue,
		});

		setAutoCompleteData(shippingLocation);
		setSuggestions(formatSuggestions(shippingLocation));
	}, [autoCompleteValue, lookUpShippingLocation]);

	useEffect(() => {
		fetchAndRenderLocations();
	}, [fetchAndRenderLocations]);

	const insertLocation = (
		index: number,
		locationActionType: LocationActionType,
		isUpdatingShipment: boolean
	) => {
		/**
		 * // TODO: Fix BE before removing this.
		 * See related issue: https://uturn-now.atlassian.net/browse/NEX-960.
		 */
		if (shipmentStatus === ShipmentStatus.CARRIER_ASSIGNED) {
			FullStory.trackEvent({
				name: 'Add Location Button Clicked',
				properties: {
					name: 'Action Denied',
					shipment_number: shipmentNumber,
					transport_type: transportType,
					shipment_status: shipmentStatus,
					type_of_shipment: form.getValues('typeOfShipment'),
					target_price: form.getValues('price.quantity') ?? 0,
				},
				schema: {
					shipment_number: 'int',
					transport_type: 'str',
					shipment_status: 'str',
					type_of_shipment: 'str',
					target_price: 'int',
				},
			});
			toast({
				variant: 'destructive',
				title: t('toast.error-title', 'Uh oh! Something went wrong.')!,
				description: t(
					'pages.shipment.errors.not_allowed_to_add_location_to_assigned_shipment',
					"New locations can't be added to assigned shipments right now. Please contact customer support. Sorry for the inconvenience."
				),
			});
			return;
		}
		/**
		 * // TODO: Is the toast error below still needed, now the rules options
		 *          can be set when invoking useFieldArray?
		 * ```ts
		 *   const { fields, remove, insert } = useFieldArray({
		 *     control: form.control,
		 *     name: 'locations',
		 *     rules: { maxLength: shipmentMaxRoutes },
		 *   });
		 * ```
		 */
		if (fields.length >= shipmentMaxRoutes) {
			toast({
				variant: 'destructive',
				title: t('toast.error-title', 'Uh oh! Something went wrong.')!,
				description: t(
					'pages.shipment.errors.max_routes_exceeded',
					'Maximum routes reached.'
				),
			});
			return;
		}
		/**
		 * When you append, prepend, insert and update the field array,
		 * the obj can't be empty object rather need to supply all your
		 * input's defaultValues.
		 *   append(); ❌
		 *   append({}); ❌
		 *   append({ firstName: 'bill', lastName: 'luo' }); ✅
		 *
		 * Check "Rules" in https://react-hook-form.com/docs/usefieldarray.
		 */
		if (isUpdatingShipment) {
			insert(index, {
				action: {
					id: crypto.randomUUID(),
					locationActionType,
					location: '',
					dateFrom: '',
					dateUntil: '',
					reference: '',
					remarks: '',
					customerName: '',
					shippingLineId: -1,
					shippingLine: {},
					port: '',
					vesselName: '',
					eta: '',
					arrival: '',
					departure: '',
					sequenceNumber: -1,
				},
				countryCode: '',
			});
		} else {
			insert(index, {
				action: {
					id: crypto.randomUUID(),
					locationActionType,
				},
			});
		}
	};

	return (
		<div className="relative h-full">
			<Card>
				<CardHeader>
					<CardTitle>
						<div className="flex">
							{t('pages.create_shipment.route.heading', 'Route')}
							{transportType && (
								<Badge className="ml-auto align-top" variant="outline">
									{transportTypeName(t)[transportType]}
								</Badge>
							)}
						</div>
					</CardTitle>
				</CardHeader>
				<CardContent className="flex flex-col gap-6">
					<AnimatePresence>
						{fields.map((field: any, index: number) => {
							const locationActionId = field.action?.id ?? `${index + 1}`;
							const locationActionIdIsFromBE =
								typeof locationActionId === 'number';
							return (
								<motion.div
									key={field.id}
									initial={{ x: 100, opacity: 0 }}
									animate={{ x: 0, opacity: 1 }}
									exit={{ x: -100, opacity: 0 }}
								>
									<ShipmentLocation
										id={locationActionId}
										isReadOnly={isReadOnly}
										readOnlyFields={readOnlyFields}
										indexInLocations={index}
										shippingLines={shipmentMetaData?.shippingLines ?? []}
										autoCompleteData={autoCompleteData}
										setAutocompleteValue={setAutoCompleteValue}
										autocompleteSuggestions={suggestions}
										removeLocation={() => remove(index)}
										enablePortbase={
											!isShipmentMatched(shipmentStatus) &&
											locationActionIdIsFromBE
										}
									/>
									{index !== fields.length - 1 && !isReadOnly && (
										<AddShipmentLocation
											insert={(locationActionType) =>
												insertLocation(
													index + 1,
													locationActionType,
													locationActionIdIsFromBE
												)
											}
										/>
									)}
								</motion.div>
							);
						})}
					</AnimatePresence>
				</CardContent>
			</Card>
		</div>
	);
}
