import { FormFieldReadOnly, FormItem, FormLabel } from '@uturn/ui-kit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { FormValues } from '@uturn/portal/modules/shipments/schema';
import { formatDatetime } from '@uturn/portal/utils';

// NOTE: On TEST, "Niels@Shipper.com" account has a shipment example
//       at "/shipments/70008188" with ETA and Carrier Execution data.

export type CarrierExecutionDetailsProps = {
	indexInLocations?: number;
};

export function CarrierExecutionDetails({
	indexInLocations,
}: CarrierExecutionDetailsProps) {
	const { t } = useTranslation();
	const form = useFormContext<FormValues>();

	const fieldRef = (name: string) => {
		return `locations.${indexInLocations}.${name}` as keyof FormValues;
	};

	const { eta, departure, arrival } = form.getValues(fieldRef('action')) ?? {};

	if (!eta && !departure && !arrival) {
		return null;
	}

	return (
		<>
			<div className="sm:col-span-6">
				<p className="font-bold first-letter:uppercase">
					{t(
						'components.carrier_execution.heading',
						'Carrier execution details'
					)}
				</p>
			</div>
			{eta && (
				<div className="sm:col-span-6">
					<FormItem>
						<FormLabel>
							{t('components.carrier_execution.date.eta', 'Estimated arrival')}
						</FormLabel>
						<FormFieldReadOnly value={formatDatetime(eta) ?? '-'} />
					</FormItem>
				</div>
			)}
			{arrival && (
				<div className={departure ? 'sm:col-span-3' : 'sm:col-span-6'}>
					<FormItem>
						<FormLabel>
							{t('components.carrier_execution.date.arrival', 'Actual arrival')}
						</FormLabel>
						<FormFieldReadOnly value={formatDatetime(arrival) ?? '-'} />
					</FormItem>
				</div>
			)}
			{departure && (
				<div className={arrival ? 'sm:col-span-3' : 'sm:col-span-6'}>
					<FormItem>
						<FormLabel>
							{t(
								'components.carrier_execution.date.departure',
								'Actual departure'
							)}
						</FormLabel>
						<FormFieldReadOnly value={formatDatetime(departure) ?? '-'} />
					</FormItem>
				</div>
			)}
		</>
	);
}
