import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { useQueryClient } from '@tanstack/react-query';
import type { UturnNoteDTO } from '@uturn/api/v1';
import {
	UturnNoteDTOUturnNoteType,
	getGetEventsByShipmentNumberQueryKey,
	useGetCarrierContactByShipmentNumber,
	useRegisterShipperMessageToCarrier,
} from '@uturn/api/v1';

import {
	Button,
	Form,
	FormControl,
	FormField,
	FormItem,
	Icon,
	SheetClose,
	SheetDescription,
	SheetHeader,
	SheetTitle,
	Textarea,
	useToast,
} from '@uturn/ui-kit';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export function MessageCarrier({ shipmentNumber }: { shipmentNumber: number }) {
	const form = useForm<Partial<UturnNoteDTO>>();
	const formRef = useRef<HTMLFormElement>(null);
	const { t } = useTranslation();

	const [hasContactDetails, setHasContactDetails] = useState(false);
	const [hasGeneralDetails, setHasGeneralDetails] = useState(false);

	const { toast } = useToast();
	const queryClient = useQueryClient();

	const { data: carrierDetails } = useGetCarrierContactByShipmentNumber(
		Number(shipmentNumber),
		{
			query: { suspense: false },
		}
	);

	const sendMessage = useRegisterShipperMessageToCarrier();

	useEffect(() => {
		if (
			carrierDetails?.data.contactEmail ||
			carrierDetails?.data.contactPhone
		) {
			setHasContactDetails(true);
		}

		if (
			carrierDetails?.data.organisationEmail ||
			carrierDetails?.data.organisationPhone
		) {
			setHasGeneralDetails(true);
		}
	}, [carrierDetails]);

	const onSubmit = (data: Partial<UturnNoteDTO>) => {
		const toSendData: UturnNoteDTO = {
			requestedFor: shipmentNumber,
			sendMessageToCarrier: true,
			sendMessageToShipper: false,
			uturnNote: data.uturnNote,
			uturnNoteType: UturnNoteDTOUturnNoteType.SHIPMENT,
			visibleToCarrier: true,
			visibleToShipper: true,
		};

		sendMessage.mutate(
			{
				data: toSendData,
			},
			{
				onSuccess: () => {
					toast({
						title: 'Success',
						description: t(
							'pages.order.shipment-details.assigned-carrier.contact.dm.submit_success',
							'Message sent to carrier'
						),
					});
					queryClient.invalidateQueries({
						queryKey: getGetEventsByShipmentNumberQueryKey(shipmentNumber),
					});
				},
				onError: () => {
					toast({
						variant: 'destructive',
						title: 'Error',
						description: t(
							'pages.order.shipment-details.assigned-carrier.contact.dm.submit_error',
							'Message could not be send to carrier'
						),
					});
				},
			}
		);
	};

	const handleSendMessage = () => {
		if (formRef.current === null) return;

		formRef.current.dispatchEvent(
			new Event('submit', { cancelable: true, bubbles: true })
		);
	};

	const forwardSave = (data: any) => {
		onSubmit(data);
	};

	const handleSubmitWithoutPropagation = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		form.handleSubmit(forwardSave)(e);
	};

	return (
		<>
			<SheetHeader>
				<SheetTitle>
					{t(
						'pages.order.shipment-details.assigned-carrier.contact.heading',
						'Contact carrier'
					)}
				</SheetTitle>
				<SheetDescription>
					{t(
						'pages.order.shipment-details.assigned-carrier.contact.subheading',
						'Contact the carrier via phone or email or send a direct message via the Uturn platform.'
					)}
				</SheetDescription>
			</SheetHeader>

			<div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:max-w-xl sm:grid-cols-6 pt-4">
				<div className="col-span-full flex flex-col gap-1">
					<h3 className="text font-heading font-medium tracking-tight">
						{t(
							'pages.order.shipment-details.assigned-carrier.contact.details.heading',
							'Contact details'
						)}
					</h3>
					{hasContactDetails && (
						<div className="flex flex-col gap-1">
							<h6 className="block text-sm font-medium leading-none">
								{t(
									'pages.order.shipment-details.assigned-carrier.contact.details.primary',
									'Primary contact'
								)}
							</h6>
							<ul className="flex gap-1 flex-col">
								{carrierDetails?.data.contactPhone && (
									<li className="flex gap-2 items-center">
										<Icon
											icon={faPhone}
											className="text-secondary-600 w-3.5 h-3.5"
										/>
										<a
											href={`tel:${carrierDetails?.data.contactPhone}`}
											className="text-sm text-primary-600"
										>
											{carrierDetails?.data.contactPhone}
										</a>
									</li>
								)}
								{carrierDetails?.data.contactEmail && (
									<li className="flex gap-2 items-center">
										<Icon
											icon={faEnvelope}
											className="text-secondary-600 w-3.5 h-3.5"
										/>
										<a
											href={`mailto::${carrierDetails?.data.contactEmail}`}
											className="text-sm text-primary-600"
										>
											{carrierDetails?.data.contactEmail}
										</a>
									</li>
								)}
							</ul>
						</div>
					)}
					{hasGeneralDetails && (
						<div className="flex flex-col gap-1 mt-1">
							<h6 className="block text-sm font-medium leading-6 text-secondary-900">
								{t(
									'pages.order.shipment-details.assigned-carrier.contact.details.general',
									'General'
								)}
							</h6>
							<ul className="flex gap-1 flex-col">
								{carrierDetails?.data.organisationPhone && (
									<li className="flex gap-2 items-center">
										<Icon
											icon={faPhone}
											className="text-secondary-600 w-3.5 h-3.5"
										/>
										<a
											href={`tel:${carrierDetails?.data.organisationPhone}`}
											className="text-sm text-primary-600"
										>
											{carrierDetails?.data.organisationPhone}
										</a>
									</li>
								)}
								{carrierDetails?.data.organisationEmail && (
									<li className="flex gap-2 items-center">
										<Icon
											icon={faEnvelope}
											className="text-secondary-600 w-3.5 h-3.5"
										/>
										<a
											href={`mailto::${carrierDetails?.data.organisationEmail}`}
											className="text-sm text-primary-600"
										>
											{carrierDetails?.data.organisationEmail}
										</a>
									</li>
								)}
							</ul>
						</div>
					)}
				</div>
				<div className="col-span-full flex flex-col gap-1">
					<h3 className="text font-heading font-medium tracking-tight">
						{t(
							'pages.order.shipment-details.assigned-carrier.contact.dm.heading',
							'Direct message'
						)}
					</h3>
					<p className="text-sm leading-6 pb-2">
						{t(
							'pages.order.shipment-details.assigned-carrier.contact.dm.subheading',
							'Your message will be send to the carrier and stored in the event log.'
						)}
					</p>
					<Form {...form}>
						<form
							onSubmit={handleSubmitWithoutPropagation}
							className="space-y-4"
							ref={formRef}
						>
							<FormField
								control={form.control}
								name="uturnNote"
								render={({ field }) => (
									<FormItem>
										<FormControl>
											<Textarea {...field} required rows={6} />
										</FormControl>
									</FormItem>
								)}
							/>
							<div className="sm:col-span-full flex justify-end">
								<SheetClose asChild>
									<Button onClick={handleSendMessage} size="sm">
										{t(
											'pages.order.shipment-details.assigned-carrier.contact.dm.submit',
											'Send message'
										)}
									</Button>
								</SheetClose>
							</div>
						</form>
					</Form>
				</div>
			</div>
		</>
	);
}
