import type { UserRole } from '@uturn/api/v1';
import type { InviteUserRequestRole } from '@uturn/api/v2';
import type { TFunction } from 'i18next';

export const userRole = (
	t: TFunction<'translation', undefined>
): Record<UserRole, string> => ({
	G_CARRIER: t('general.user-role.G_CARRIER', 'General Carrier'),
    G_SHIPPER: t('general.user-role.G_SHIPPER', 'General Shipper'),
    C_USER: t('general.user-role.C_USER', 'Carrier User'),
    C_ADMIN: t('general.user-role.C_ADMIN', 'Carrier Admin'),
    S_ADMIN: t('general.user-role.S_ADMIN', 'Shipper Admin'),
    S_USER: t('general.user-role.S_USER', 'Shipper User'),
    U_ADMIN: t('general.user-role.U_ADMIN', 'UTURN Admin'),
    C_DRIVER: t('general.user-role.C_DRIVER', 'Carrier Driver'),
});

export type InviteUserRole = Extract<
	InviteUserRequestRole,
	'S_ADMIN' | 'S_USER'
>;

export const inviteUserRole = (
	t: TFunction<'translation', undefined>
): Record<InviteUserRole, string> => ({
	S_ADMIN: t('general.invite-user-role.S_ADMIN', 'Admin'),
	S_USER: t('general.invite-user-role.S_USER', 'User'),
});
