import { type ShipmentLocationActionRequestDto } from '@uturn/api/v1';
import { useTranslation } from 'react-i18next';
import { useLocationTypeTranslation } from '@uturn/portal/modules/shipments/hooks';
import {
	type ValidationsResult,
	newValidationsResult,
	validationsError,
} from '@uturn/portal/types';

export const useLocationDatesValidation = () => {
	const { t } = useTranslation();
	const locationTypeTranslation = useLocationTypeTranslation();
	/**
	 * Validate shipment route dates.
	 *
	 * @logic
	 * On publishing:
	 * - Pickup until >= current time (All dates should be in the future, as you can't drive a shipment in the past)
	 * - Publish until >= current time (to make sure the shipment is actually published)
	 *
	 * Publish until validation enabled (Not in use atm):
	 * - Publish until >= Pickup until
	 *
	 * Shipment date/time location validation common logic:
	 * - Location from  <= Next location from
	 * - Location until <= Next location until
	 * - Location from  <= Same location until
	 *
	 * On other updates logic:
	 * - No check on date in the future (because shipment can we partly executed in the past)
	 *
	 * @param locations
	 * @param publishDateUntil
	 * @param publishing Publishing or updating published shipment
	 * @param skipPublishUntilValidation
	 * @param validationsResult
	 * @returns
	 */
	const validateLocationDates = (
		locations: ShipmentLocationActionRequestDto[],
		publishDateUntil: string,
		publishing: boolean,
		skipPublishUntilValidation: boolean = false,
		validationsResult?: ValidationsResult
	): ValidationsResult => {
		let results = validationsResult ?? newValidationsResult();

		const now = new Date();
		const pickupDateUntil = locations[0]?.dateUntil;

		if (publishing) {
			if (pickupDateUntil && new Date(pickupDateUntil) < now) {
				results = validationsError(
					results,
					t(
						'general.errors.shipment.location_dates.pickup_date_until_invalid',
						'Must be in the future'
					),
					'locations.0.action.dateUntil'
				);
			}

			if (publishDateUntil && new Date(publishDateUntil) < now) {
				results = validationsError(
					results,
					t(
						'general.errors.shipment.location_dates.publish_date_until_invalid',
						'Must be in the future'
					),
					'publishUntilDate'
				);
			}
		}

		if (!skipPublishUntilValidation) {
			if (
				publishDateUntil &&
				pickupDateUntil &&
				new Date(pickupDateUntil) > new Date(publishDateUntil)
			) {
				results = validationsError(
					results,
					t(
						'general.errors.shipment.location_dates.pickup_publish_dates_until_invalid',
						'Must be before publish until date'
					),
					'locations.0.action.dateUntil'
				);
			}
		}

		locations
			.map((location, index) => ({
				...location,
				sequenceNumber: index,
			}))
			.filter(
				(location) =>
					location.dateFrom &&
					location.dateUntil &&
					new Date(location.dateFrom) > new Date(location.dateUntil)
			)
			.forEach((location) => {
				results = validationsError(
					results,
					t(
						'general.errors.shipment.location_dates.date_until_after_date_from',
						'Must be after date from'
					),
					`locations.${location.sequenceNumber}.action.dateUntil`
				);
			});

		for (let i = 0; i < locations.length; i += 1) {
			const location = locations[i];
			const nextLocation = locations[i + 1];

			if (nextLocation!) {
				if (
					location.dateFrom &&
					nextLocation.dateFrom &&
					new Date(location.dateFrom) > new Date(nextLocation.dateFrom)
				) {
					results = validationsError(
						results,
						t(
							'general.errors.shipment.location_dates.date_from_after_previous_date_from',
							"Must be after the {{locationType}} location's date from",
							{
								locationType:
									locationTypeTranslation[location.locationActionType],
							}
						),
						`locations.${i + 1}.action.dateFrom`
					);
				}

				if (
					location.dateUntil &&
					nextLocation.dateUntil &&
					new Date(location.dateUntil) > new Date(nextLocation.dateUntil)
				) {
					results = validationsError(
						results,
						t(
							'general.errors.shipment.location_dates.date_until_after_previous_date_until',
							"Must be after the {{locationType}} location's date until",
							{
								locationType:
									locationTypeTranslation[location.locationActionType],
							}
						),
						`locations.${i + 1}.action.dateUntil`
					);
				}
			}
		}

		return results;
	};
	return { validateLocationDates };
};
