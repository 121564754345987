import { faArrowRight, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import type { PartySubscriptionResponse } from '@uturn/api/v1';

import {
	Button,
	Callout,
	CalloutIcon,
	CalloutTitle,
	Icon,
} from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function RemainingRequestsBanner({
	subscription,
	remainingRequests,
}: {
	subscription: PartySubscriptionResponse;
	remainingRequests: number;
}) {
	const navigate = useNavigate();
	const { t } = useTranslation();
	return (
		<Callout>
			<CalloutIcon>
				<Icon icon={faInfoCircle} />
			</CalloutIcon>
			<CalloutTitle>
				{t(
					'pages.price-calculator.subscription.banner',
					'You have a {{tier}} subscription with {{requestLimit}} requests remaining.',
					{
						tier: subscription.activeSubscription?.tierName ?? '',
						requestLimit: remainingRequests,
					}
				)}
			</CalloutTitle>
			<Button
				size="sm"
				className="ml-auto flex gap-3 items-center"
				onClick={() => {
					navigate('/calculator/subscriptions');
				}}
			>
				{t('pages.price-calculator.subscription.upgrade', 'Upgrade')}
				<Icon icon={faArrowRight} />
			</Button>
		</Callout>
	);
}
