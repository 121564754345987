import { CurrencyType, type ShipmentListDTO } from '@uturn/api/v1';
import { AssignedShipments as AssignedShipmentsEmptyState } from '@uturn/design-tokens/empty-states';
import { EmptyState } from '@uturn/ui';
import { Button } from '@uturn/ui-kit';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ShipmentPage } from '@uturn/portal/modules/shipments/components';
import { CarrierInformationSheet } from '@uturn/portal/modules/shipments/components/shipment-sheet';
import { formatPrice } from '@uturn/portal/utils';

function AssignedShipmentEmptyState() {
	const { t } = useTranslation();
	return (
		<EmptyState>
			<AssignedShipmentsEmptyState className="md:h-[20rem]" />
			<h2 className="text-secondary-700 text-2xl font-medium leading-7">
				{t(
					'pages.shipments.assigned.emptyState.title',
					'Match made in heaven!'
				)}
			</h2>
			<p className="text-secondary-600 text-lg leading-6">
				{t(
					'pages.shipments.assigned.emptyState.description',
					'Here your containers find their trucks. You can manage and monitor progress of the transports.'
				)}
			</p>
			<div>
				<Link to="/shipments/add">
					<Button>{t('pages.shipments.actions.start', 'Get started')}</Button>
				</Link>
			</div>
		</EmptyState>
	);
}

function Carrier({
	shipmentNumber,
	carrierName,
}: {
	shipmentNumber: number;
	carrierName: string;
}) {
	const [openSheet, setOpenSheet] = useState(false);

	return (
		<>
			<button
				onClick={() => {
					setOpenSheet(true);
				}}
				className="text-muted-foreground underline"
			>
				{carrierName}
			</button>

			<CarrierInformationSheet
				shipmentNumber={shipmentNumber}
				openSheet={openSheet}
				setOpenSheet={setOpenSheet}
			/>
		</>
	);
}

export function AssignedShipments() {
	const { t } = useTranslation();

	const shipmentDetails = (shipment: ShipmentListDTO) => [
		{
			label: t('pages.shipments.details.reference', 'Reference'),
			value: shipment.shipperReference,
			visibleOnMultiSelect: true,
			enableCopyToClipboard: true,
			hideOnCompact: true,
		},
		{
			label: t('pages.shipments.details.containerNumber', 'Container number'),
			value: shipment.containerNumber,
			visibleOnMultiSelect: true,
			enableCopyToClipboard: true,
		},
		{
			label: t('pages.shipments.details.agreedPrice', 'Agreed price'),
			value: formatPrice(
				shipment.price?.quantity ?? 0,
				shipment.price?.currencyCode ?? CurrencyType.EUR
			),
			visibleOnMultiSelect: true,
		},
		{
			label: t('pages.shipments.details.carrierName', 'Carrier name'),
			value: (
				<Carrier
					shipmentNumber={shipment.shipmentNumber!}
					carrierName={shipment.carrierOrganizationName ?? '-'}
				/>
			),
			visibleOnMultiSelect: true,
			clickable: true,
		},
	];

	return (
		<>
			<Helmet
				title={t('pages.shipments.assigned.title', 'Assigned shipments')!}
			/>
			<ShipmentPage
				enableGroupFilter={true}
				enableTsoFilter={false}
				EmptyState={AssignedShipmentEmptyState}
				details={shipmentDetails}
			/>
		</>
	);
}
