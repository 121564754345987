import {
	faEnvelope,
	faPlus,
	faStarHalfStroke,
} from '@fortawesome/pro-regular-svg-icons';
import { type Carrier } from '@uturn/api/v2';
import {
	Button,
	FormFieldReadOnly,
	Icon,
	Label,
	Sheet,
	SheetContent,
	SheetTrigger,
	Stars,
} from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';
import { LoadingSpan } from '@uturn/portal/components';
import { MessageCarrier } from '@uturn/portal/modules/shipments/components/message-carrier';
import { ReviewCarrierSheet } from '@uturn/portal/modules/shipments/components/shipment-sheet';
import type { CarrierReview } from '@uturn/portal/types/shipment';

export function CarrierInformation({
	shipmentNumber,
	carrier,
	carrierReview,
}: {
	shipmentNumber?: number;
	carrier: Carrier;
	carrierReview: CarrierReview;
}) {
	const { t } = useTranslation();

	if (!shipmentNumber) {
		return <LoadingSpan />;
	}

	return (
		<div className="grid grid-cols-1 gap-5 w-full sm:grid-cols-6">
			<div className="sm:col-span-full">
				<Label>
					{t(
						'pages.order.shipment-details.assigned-carrier.heading',
						'Carrier'
					)}
				</Label>
				<FormFieldReadOnly value={carrier.name} />

				<div className="mt-1 flex flex-row">
					<Stars rating={Math.floor(carrier.rating.averageRating)} />
					<span className="mt-1 ml-2 text-xs">
						{`(${t('general.reviews', {
							count: carrier.rating.totalRatings,
						})})`}
					</span>
				</div>
				<div className="flex gap-4 mt-4">
					<Sheet>
						<SheetTrigger asChild>
							<Button
								variant="secondary"
								size="sm"
								className="flex gap-2 items-center"
							>
								<Icon
									icon={faEnvelope}
									className="text-secondary-600 w-3.5 h-3.5"
								/>
								{t(
									'pages.order.shipment-details.assigned-carrier.actions.contact',
									'Contact'
								)}
							</Button>
						</SheetTrigger>
						<SheetContent>
							<MessageCarrier shipmentNumber={shipmentNumber!} />
						</SheetContent>
					</Sheet>
					<Sheet>
						<SheetTrigger asChild>
							<Button
								variant="secondary"
								size="sm"
								className="flex gap-2 items-center"
							>
								<Icon
									icon={!carrierReview ? faPlus : faStarHalfStroke}
									className="text-secondary-600 w-3.5 h-3.5"
								/>
								{!carrierReview
									? t(
											'pages.order.shipment-details.assigned-carrier.actions.review_add',
											'Add a review'
									  )
									: t(
											'pages.order.shipment-details.assigned-carrier.actions.review_get',
											'Show my review'
									  )}
							</Button>
						</SheetTrigger>
						<SheetContent>
							<ReviewCarrierSheet
								shipmentNumber={shipmentNumber}
								carrier={carrier}
								carrierReview={carrierReview}
							/>
						</SheetContent>
					</Sheet>
				</div>
			</div>
		</div>
	);
}
