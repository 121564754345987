import {
	faCheck,
	faChevronDown,
	faPlus,
	faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import {
	type OrganizationDto,
	useFetchOrganizationById,
	useUpdateOrganization,
} from '@uturn/api/v1';
import { phoneDialCodes } from '@uturn/ui';
import {
	Button,
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	Form,
	FormControl,
	FormField,
	FormFieldReadOnly,
	FormItem,
	FormLabel,
	FormMessage,
	Icon,
	Input,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Separator,
	Stars,
	cn,
	useToast,
} from '@uturn/ui-kit';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MetaDataContext } from '@uturn/portal/context';

const countryOptions = phoneDialCodes.map((country) => ({
	label: country.name,
	value: country.code,
}));

interface TForm extends Omit<OrganizationDto, 'emails'> {
	emails: {
		email: string;
	}[];
}

export function OrganizationProfile() {
	const { toast } = useToast();
	const { orgId } = useParams();
	const { metadata } = useContext(MetaDataContext);
	const {
		data: { data: organization },
	} = useFetchOrganizationById(Number(orgId));
	const { t } = useTranslation();

	const { mutate } = useUpdateOrganization({
		mutation: {
			onSuccess: () => {
				toast({
					title: t('toast.success-title', 'Success!')!,
					description: t(
						'pages.account.organisation.profile.form.submit.success.description',
						'Organisation has been updated'
					),
				});
			},
			onError: () => {
				toast({
					title: t('toast.error-title', 'Uh oh! Something went wrong')!,
					description: t('general.try-again-later', 'Please try again later.'),
				});
			},
		},
	});

	const form = useForm<TForm>({
		defaultValues: {
			...organization!,
			emails: organization!.emails.map((email) => ({ email })),
		},
	});
	const { fields, append, remove } = useFieldArray({
		control: form.control,
		name: 'emails',
	});

	return (
		<>
			<Helmet
				title={
					t(
						'pages.account.organisation.profile.title',
						'Organisation - Profile'
					)!
				}
			/>
			<div className="space-y-6">
				<div>
					<h3 className="text-lg font-medium">
						{t('pages.account.organisation.profile.title', 'General')}
					</h3>
					<p className="text-sm text-muted-foreground">
						{t(
							'pages.account.organisation.profile.description',
							'This is how others will see you on the site.'
						)}
					</p>
				</div>
				<Separator />
				<div className="sm:col-span-full">
					<h5 className="text-sm font-medium leading-6 text-secondary-900 flex items-center mb-2">
						{t('pages.organisation.settings.general.rating.label', 'Rating')}
					</h5>
					<div className="flex flex-row items-center justify-start">
						<Stars rating={Math.floor(organization.rating.averageRating)} />
						<span className="mt-1 ml-2 text-xs">
							{`(${t('general.reviews', {
								count: organization.rating.totalRatings,
							})})`}
						</span>
					</div>
				</div>
				<Form {...form}>
					<form
						className="space-y-8"
						onSubmit={form.handleSubmit((formData) => {
							mutate({
								id: Number(orgId),
								data: {
									...formData,
									emails: formData.emails
										.filter((email) => email.email)
										.map((email) => email.email),
								} satisfies OrganizationDto,
							});
						})}
					>
						<div className="grid grid-flow-col gap-3">
							<FormItem>
								<FormLabel>
									{t(
										'pages.organisation.settings.general.org_number.label',
										'Organisation number'
									)}
								</FormLabel>
								<FormControl>
									<FormFieldReadOnly value={organization!.id} />
								</FormControl>
							</FormItem>

							<FormItem>
								<FormLabel>
									{t(
										'pages.organisation.settings.general.org_type.label',
										'Organisation type'
									)}
								</FormLabel>
								<FormControl>
									<FormFieldReadOnly
										value={metadata?.user?.organisation?.organisationType ?? ''}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						</div>

						<FormField
							control={form.control}
							name="name"
							render={({ field }) => (
								<FormItem>
									<FormLabel>
										{t('general.name', 'Organisation name')}
									</FormLabel>
									<FormControl>
										<Input {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<div className="space-y-2 flex flex-col">
							<FormLabel>
								{t(
									'pages.organisation.settings.general.email.label',
									'Invoice email(s)'
								)}
							</FormLabel>
							{fields.map((field, index) => (
								<FormField
									key={field.id}
									control={form.control}
									name={`emails.${index}.email`}
									render={({ field: subField }) => (
										<FormItem>
											<div className="flex gap-2">
												<FormControl>
													<Input {...subField} />
												</FormControl>
												{index !== 0 && (
													<Button
														type="button"
														variant="destructive"
														size="icon"
														onClick={() => remove(index)}
													>
														<Icon icon={faTrashCan} />
													</Button>
												)}
											</div>
											<FormMessage />
										</FormItem>
									)}
								/>
							))}
							<div>
								<Button
									type="button"
									className="space-x-2 p-0"
									variant="link"
									onClick={() => append({ email: '' })}
								>
									<Icon icon={faPlus} />
									<span>
										{t(
											'pages.organisation.settings.general.email.add',
											'Add email'
										)}
									</span>
								</Button>
							</div>
						</div>
						<FormField
							control={form.control}
							name="website"
							render={({ field }) => (
								<FormItem>
									<FormLabel>
										{t(
											'pages.organisation.settings.general.website.label',
											'Website'
										)}
									</FormLabel>
									<FormControl>
										<Input {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="phoneNumbers.0.phone.number"
							render={({ field }) => (
								<FormItem>
									<FormLabel>
										{t(
											'pages.organisation.settings.general.phone.label',
											'Phone'
										)}
									</FormLabel>
									<FormControl>
										<Input {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="phoneNumbers.1.phone.number"
							render={({ field }) => (
								<FormItem>
									<FormLabel>
										{t(
											'pages.organisation.settings.general.out_of_office_number.label',
											'Out of office number'
										)}
									</FormLabel>
									<FormControl>
										<Input {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<div className="space-y-3">
							<div className="grid grid-cols-[3fr,_1fr] gap-3">
								<FormField
									control={form.control}
									name="address.street"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{t('general.address.street', 'Street')}
											</FormLabel>
											<FormControl>
												<Input {...field} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									name="address.houseNumber"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{t('general.address.house_number', 'House number')}
											</FormLabel>
											<FormControl>
												<Input {...field} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>

							<div className="grid grid-cols-[2fr,_4fr] gap-3 ">
								<FormField
									control={form.control}
									name="address.postalCode"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{t('general.address.postalCode', 'Postal code')}
											</FormLabel>
											<FormControl>
												<Input {...field} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									name="address.city"
									render={({ field }) => (
										<FormItem>
											<FormLabel>{t('general.address.city', 'City')}</FormLabel>
											<FormControl>
												<Input {...field} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>

							<FormField
								control={form.control}
								name="address.countryCode"
								render={({ field }) => (
									<FormItem className="flex flex-col">
										<FormLabel>
											{t('general.address.country', 'Country')}
										</FormLabel>
										<Popover>
											<PopoverTrigger asChild>
												<FormControl>
													<Button
														variant="outline"
														role="combobox"
														className={cn(
															'justify-between bg-transparent',
															!field.value && 'text-muted-foreground'
														)}
													>
														{field.value
															? countryOptions.find(
																	(country) => country.value === field.value
															  )?.label
															: 'Select language'}
														<Icon icon={faChevronDown} />
													</Button>
												</FormControl>
											</PopoverTrigger>
											<PopoverContent align="start" className="w-full p-0">
												<Command className="max-h-[300px]">
													<CommandInput
														placeholder={
															t(
																'pages.account.organisation.profile.form.country.dropdown.placeholder',
																'Search country...'
															)!
														}
													/>
													<CommandEmpty>
														{t(
															'pages.account.organisation.profile.form.country.dropdown.no-result',
															'No country found.'
														)}
													</CommandEmpty>
													<CommandGroup className="overflow-y-scroll">
														{countryOptions.map((country) => (
															<CommandItem
																value={country.label}
																key={country.value}
																onSelect={() => {
																	form.setValue(
																		'address.countryCode',
																		country.value
																	);
																}}
															>
																<Icon
																	icon={faCheck}
																	className={cn(
																		'mr-2 h-4 w-4',
																		country.value === field.value
																			? 'opacity-100'
																			: 'opacity-0'
																	)}
																/>
																{country.label}
															</CommandItem>
														))}
													</CommandGroup>
												</Command>
											</PopoverContent>
										</Popover>
										<FormMessage />
									</FormItem>
								)}
							/>

							<Button type="submit" disabled={form.formState.isSubmitting}>
								{form.formState.isSubmitting && (
									<Icon spin className="mr-3" icon={faSpinnerThird} />
								)}
								{t('general.actions.save', 'save')}
							</Button>
						</div>
					</form>
				</Form>
			</div>
		</>
	);
}
