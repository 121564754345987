import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import type { GetShipmentsParams } from '@uturn/api/v2';
import {
	Accordion,
	AccordionItem,
	Card,
	CardContent,
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
	Icon,
} from '@uturn/ui-kit';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterContext } from './context';
import { DateFilter } from './date-filter';
import { GroupFilter } from './group-filter';
import { RequirementsFilter } from './requirements-filter';
import { ShipmentNumberSorting } from './shipmentnr-sorting';
import { StatusFilter } from './status-filter';
import { TransportTypeFilter } from './transport-type-filter';
import { TsoStatusFilter } from './tso-status-filter';
import { ContainerTypeFilter } from './type-filter';
import { UserFilter } from './user-filter';
import { useShipmentsSearchStore } from '@uturn/portal/store/shipments/shipments-search';
import { defaultStatuses } from '@uturn/portal/types/shipments';

function Filters({
	enableTsoFilter,
	enableGroupFilter,
}: {
	/**
	 * @deprecated
	 */
	enableTsoFilter: boolean;
	enableGroupFilter: boolean;
}) {
	const { t } = useTranslation();
	const { activeStatusTab } = useShipmentsSearchStore();

	const statusOptions = defaultStatuses(t, activeStatusTab);

	return (
		<Accordion
			type="multiple"
			defaultValue={['sort', 'status', 'date', 'users', 'groups']}
		>
			<AccordionItem value="sort">
				<ShipmentNumberSorting />
			</AccordionItem>
			{statusOptions?.length > 1 && (
				<AccordionItem value="status">
					<StatusFilter />
				</AccordionItem>
			)}
			{enableTsoFilter && (
				<AccordionItem value="tso-status">
					<TsoStatusFilter />
				</AccordionItem>
			)}
			<AccordionItem value="date">
				<DateFilter />
			</AccordionItem>
			{enableGroupFilter && (
				<AccordionItem value="groups">
					<GroupFilter />
				</AccordionItem>
			)}
			<AccordionItem value="users">
				<UserFilter />
			</AccordionItem>
			<AccordionItem value="transport">
				<TransportTypeFilter />
			</AccordionItem>
			<AccordionItem value="requirements">
				<RequirementsFilter />
			</AccordionItem>
			<AccordionItem value="size">
				<ContainerTypeFilter />
			</AccordionItem>
		</Accordion>
	);
}

function DesktopFilters({
	enableTsoFilter,
	enableGroupFilter,
}: {
	/**
	 * @deprecated
	 */
	enableTsoFilter: boolean;
	enableGroupFilter: boolean;
}) {
	return (
		<div className="hidden lg:block">
			<Filters
				enableGroupFilter={enableGroupFilter}
				enableTsoFilter={enableTsoFilter}
			/>
		</div>
	);
}

function MobileFilters({
	enableTsoFilter,
	enableGroupFilter,
}: {
	/**
	 * @deprecated
	 */
	enableTsoFilter: boolean;
	enableGroupFilter: boolean;
}) {
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();

	return (
		<Collapsible open={open} onOpenChange={setOpen} className="block lg:hidden">
			<CollapsibleTrigger className="w-full">
				<Card className="h-9 px-4 flex items-center justify-between dark:hover:bg-muted/20 hover:bg-secondary cursor-pointer">
					<p className="text-sm font-medium">
						{t('pages.shipments.filters.title', 'Filters')}
					</p>
					<Icon icon={open ? faChevronUp : faChevronDown} />
				</Card>
			</CollapsibleTrigger>
			<CollapsibleContent>
				<Card className="mt-2 mx-4">
					<CardContent>
						<Filters
							enableGroupFilter={enableGroupFilter}
							enableTsoFilter={enableTsoFilter}
						/>
					</CardContent>
				</Card>
			</CollapsibleContent>
		</Collapsible>
	);
}

export function ShipmentFilter({
	onChange,
	initialFilters,
	enableTsoFilter,
	enableGroupFilter,
}: {
	onChange(filters: Partial<GetShipmentsParams> | null): void;
	initialFilters: Partial<GetShipmentsParams>;
	/**
	 * @deprecated
	 */
	enableTsoFilter: boolean;
	enableGroupFilter: boolean;
}) {
	const [filters, setFiltersState] =
		useState<Partial<GetShipmentsParams>>(initialFilters);

	// This is a helper function that allows us to update the filters
	// without having to pass the entire filters object to the setFilters function.
	const setFilters = (filterChanges: Partial<GetShipmentsParams>) => {
		const newFilters = { ...filters, ...filterChanges };
		setFiltersState(newFilters);
		onChange(newFilters);
	};

	// TODO: Remove this hack.
	// This is a hack to force the filters to re-render when the user clicks the clear button.
	// I dont want to talk about it. It works. It's fine. (I am trying to convince myself I am okay with it)
	const [render, setRender] = useState(true);

	useEffect(() => {
		if (!render) {
			setFilters({});
			onChange(null);
			setRender(true);
		}
	}, [render]);

	return (
		<FilterContext.Provider
			value={{
				filters,
				setFilters,
			}}
		>
			{render && (
				<>
					<DesktopFilters
						enableGroupFilter={enableGroupFilter}
						enableTsoFilter={enableTsoFilter}
					/>
					<MobileFilters
						enableGroupFilter={enableGroupFilter}
						enableTsoFilter={enableTsoFilter}
					/>
				</>
			)}
		</FilterContext.Provider>
	);
}
