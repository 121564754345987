import type { TFunction } from 'i18next';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
	Outlet,
	useLocation,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import { EventsHistorySheet } from '../components/shipments-sheet';
import { ShipmentViewProvider } from '../providers/shipment-view';
import { Page } from '@uturn/portal/components/page';
import type { TabButton, TabLink } from '@uturn/portal/components/page/tabs';
import useShipmentViews, {
	ShipmentView,
} from '@uturn/portal/hooks/shipments/use-shipment-view';
import {
	CancelShipmentsException,
	ShipmentsCancelModal,
	ShipmentsExportModal,
} from '@uturn/portal/modules/shipments/components';
import { useShipmentsSearchStore } from '@uturn/portal/store/shipments/shipments-search';
import {
	type DefaultFilters,
	type StatusTab,
	defaultStatusFilters,
} from '@uturn/portal/types/shipments';

const pages = (
	t: TFunction<'translation', undefined>
): (TabLink & {
	navTab: StatusTab;
	defaultFilters: DefaultFilters;
})[] => [
	{
		title: t('pages.shipments.tabs.published', 'Published')!,
		to: '/shipments',
		navTab: 'published',
		defaultFilters: defaultStatusFilters(t, 'published'),
	},
	{
		title: t('pages.shipments.tabs.assigned', 'Assigned')!,
		to: '/shipments/assigned',
		navTab: 'assigned',
		defaultFilters: defaultStatusFilters(t, 'assigned'),
	},
	{
		title: t('pages.shipments.tabs.drafted', 'Drafted')!,
		to: '/shipments/drafted',
		navTab: 'drafted',
		defaultFilters: defaultStatusFilters(t, 'drafted'),
	},
	{
		title: t('pages.shipments.tabs.archived', 'Archived')!,
		to: '/shipments/archived',
		navTab: 'archived',
		defaultFilters: defaultStatusFilters(t, 'archived'),
	},
	{
		title: t('pages.shipments.tabs.groups', 'Groups')!,
		to: '/shipments/groups',
		navTab: 'groups',
		defaultFilters: {
			'filter.groups': undefined,
		},
	},
];

export function ShipmentsOverviewLayout() {
	const views = useShipmentViews();
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();

	const { totalShipments, activeStatusTab, setActiveStatusTab } =
		useShipmentsSearchStore();

	const { pathname } = useLocation();
	const navigate = useNavigate();
	const [shipmentView, setView] = useState(
		ShipmentView[
			(localStorage.getItem('user.settings.shipments.overview.view_mode') ??
				'Comfortable') as keyof typeof ShipmentView
		]
	);

	const handleCancelShipmentsExceptions = ():
		| CancelShipmentsException
		| undefined => {
		if (pathname.includes('/groups')) {
			return CancelShipmentsException.GROUPS;
		}
		if (pathname.includes('/archived')) {
			return CancelShipmentsException.ARCHIVED;
		}
		if (totalShipments === 0) {
			return CancelShipmentsException.NO_SHIPMENTS;
		}
		return undefined;
	};

	const tabs = pages(t).map((item) => {
		const active = pathname === item.to;
		if (active && activeStatusTab !== item.navTab) {
			setActiveStatusTab(item.navTab);
		}
		return {
			...item,
			active,
		};
	});

	const rightTabs: TabButton[] = pathname.includes('groups')
		? []
		: views.map((view) => {
				return {
					title: view.title,
					onClick: () => {
						localStorage.setItem(
							'user.settings.shipments.overview.view_mode',
							view.value
						);
						setView(view.value);
					},
					active: shipmentView === view.value,
				};
		  });

	const [openExport, setOpenExport] = useState(false);
	const [openEvents, setOpenEvents] = useState(false);
	const [openCancel, setOpenCancel] = useState(false);

	return (
		<>
			<Helmet title={t('pages.shipments.title', 'Shipments')!} />
			<Page
				title={t('pages.shipments.title', 'Shipments') ?? ''}
				tabsStart={tabs}
				tabsEnd={rightTabs}
				contextActions={
					shipmentView !== ShipmentView.Table
						? [
								{
									label: t('pages.shippers.actions.export', 'Export'),
									onClick: () => setOpenExport(true),
								},
								{
									label: t('pages.shippers.actions.cancel', 'Cancel'),
									onClick: () => setOpenCancel(true),
									renderCondition:
										!handleCancelShipmentsExceptions() ||
										handleCancelShipmentsExceptions() ===
											CancelShipmentsException.NO_SHIPMENTS,
								},
						  ]
						: []
				}
				primaryAction={{
					label: t('pages.shippers.actions.add', 'Create shipment'),
					onClick: () => navigate('/shipments/add'),
				}}
				secondaryAction={{
					label: t('pages.shippers.actions.events', 'Events'),
					className: 'block md:hidden',
					onClick: () => setOpenEvents(true),
				}}
			>
				<EventsHistorySheet
					openSheet={openEvents}
					setOpenSheet={setOpenEvents}
				/>
				<ShipmentsExportModal
					exportFilters={{
						...tabs.filter((page) => page.to === pathname)[0]?.defaultFilters,
						...Object.fromEntries(searchParams),
					}}
					isOpenModal={openExport}
					setOpenModal={setOpenExport}
					totalShipments={totalShipments}
				/>
				{(!handleCancelShipmentsExceptions() ||
					handleCancelShipmentsExceptions() ===
						CancelShipmentsException.NO_SHIPMENTS) && (
					<ShipmentsCancelModal
						exportFilters={{
							...tabs.filter((page) => page.to === pathname)[0]?.defaultFilters,
							...Object.fromEntries(searchParams),
						}}
						statuses={
							tabs.filter((page) => page.to === pathname)[0]?.defaultFilters[
								'filter.statuses'
							] ?? []
						}
						isOpenModal={openCancel}
						setOpenModal={setOpenCancel}
					/>
				)}
				<ShipmentViewProvider view={shipmentView}>
					<Outlet />
				</ShipmentViewProvider>
			</Page>
		</>
	);
}
