import type { QueryKey } from '@tanstack/react-query';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';
import { Quotes } from './quotes';

export function QuotesSheet({
	shipmentNumber,
	openSheet,
	setOpenSheet,
	onAssign,
	onDecline,
	parentQueryKey,
}: {
	openSheet: boolean;
	setOpenSheet: (open: boolean) => void;
	shipmentNumber: number;
	onAssign?: () => void;
	onDecline?: () => void;
	parentQueryKey?: QueryKey;
}) {
	const { t } = useTranslation();

	return (
		<Sheet open={openSheet} onOpenChange={setOpenSheet}>
			<SheetContent className="max-w-[400px] sm:max-w-[540px] overflow-auto">
				<SheetHeader>
					<SheetTitle>{t('general.quotes.modal.heading', 'Quotes')}</SheetTitle>
				</SheetHeader>
				{openSheet && (
					<Quotes
						shipmentNumber={shipmentNumber}
						onAssign={onAssign}
						onDecline={onDecline}
						setOpenSheet={setOpenSheet}
						parentQueryKey={parentQueryKey}
					/>
				)}
			</SheetContent>
		</Sheet>
	);
}
