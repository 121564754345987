import { User } from '@uturn/api/v1';
import { QuoteDto } from '@uturn/api/v2';

const webhookUrl = import.meta.env.VITE_HEVO_WEBHOOK_URL;

const postRequest = async <T>(url: string, body: T): Promise<boolean> => {
	if (!url) {
		console.error('Missing VITE_HEVO_WEBHOOK_URL in .env');
		return false;
	}
	const response = await fetch(url, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	});
	return response.ok;
};

enum HevoEvents {
	QUOTE_VIEWED = 'quote_viewed',
}

export type QuoteViewed = {
	user: User;
	data: {
		shipmentNumber: number;
		quotes: QuoteDto[];
	};
	view_name: string;
	timestamp: number;
	metadata: string;
};

const sendEventQuoteViewed = async (
	quoteViewed: QuoteViewed,
): Promise<boolean> => {
	const ok = await postRequest(webhookUrl, {
		event: HevoEvents.QUOTE_VIEWED,
		properties: quoteViewed,
	});
	return ok;
};

export const hevoClient = {
	sendEventQuoteViewed,
};
