import {
	CommandDialog,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
	DialogTitle,
	Icon,
} from '@uturn/ui-kit';
import { useEffect, useState } from 'react';
import { VisuallyHidden } from 'react-aria';
import { useTranslation } from 'react-i18next';
import { devActions } from '../dev/command-palette';

export function CommandMenu() {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
				e.preventDefault();
				setOpen((state) => !state);
			}
		};
		document.addEventListener('keydown', down);
		return () => document.removeEventListener('keydown', down);
	}, []);

	return (
		<>
			<CommandDialog open={open} onOpenChange={setOpen}>
				<VisuallyHidden>
					<DialogTitle>
						{t('general.dev_commands.title', 'Dev commands')}
					</DialogTitle>
				</VisuallyHidden>
				<CommandInput placeholder="Type a command or search..." />
				<CommandList>
					<CommandEmpty>No results found.</CommandEmpty>
					{import.meta.env.DEV && (
						<CommandGroup heading="Developer actions">
							{devActions.map((action) => (
								<CommandItem key={action.name} onSelect={action.onAction}>
									<Icon icon={action.icon} className="mr-2" />
									{action.name}
								</CommandItem>
							))}
						</CommandGroup>
					)}
				</CommandList>
				<CommandSeparator />
				<p className="mx-4 my-2 uppercase text-xs font-bold text-gray-400">
					BUILD {APP_VERSION}
				</p>
			</CommandDialog>
		</>
	);
}
