import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { ToolTip } from '@uturn/ui';
import { Icon } from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';

export function HiddenFieldLabelInfo() {
	const { t } = useTranslation();
	return (
		<ToolTip
			title={`${t(
				'pages.shipment.tooltip.hidden_fields.info',
				'Information visible after carrier has been assigned'
			)}`}
		>
			<div className="ml-1 rounded-2xl w-5 h-5 bg-transparent flex justify-center items-center">
				<Icon icon={faEyeSlash} className="w-3.5 h-3.5 text-secondary-700" />
			</div>
		</ToolTip>
	);
}
