import type { MyCarriersDTO } from '@uturn/api/v1';
import {
	getGetMyCarriersListQueryKey,
	useRemoveCarrierPartyRelation,
} from '@uturn/api/v1';
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
	Button,
} from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';
import { queryClient } from '@uturn/portal/api';

export function UncontractCarrier({ carrier }: { carrier: MyCarriersDTO }) {
	const { t } = useTranslation();
	const { mutate } = useRemoveCarrierPartyRelation({
		mutation: {
			onMutate: async (data) => {
				const removedCarrier = data.data;
				await queryClient.cancelQueries({
					queryKey: getGetMyCarriersListQueryKey(),
				});

				const previousCarriers = queryClient.getQueryData(
					getGetMyCarriersListQueryKey()
				);

				queryClient.setQueryData(getGetMyCarriersListQueryKey(), (old) => {
					const newCarriersList = old?.data!.filter(
						(item) => item.carrierParty !== removedCarrier.carrierParty
					);

					return {
						...old,
						data: newCarriersList,
					};
				});

				return { previousCarriers };
			},
			onError: (err, data, context) => {
				queryClient.setQueryData(
					getGetMyCarriersListQueryKey(),
					context?.previousCarriers
				);
			},

			onSettled: () => {
				queryClient.invalidateQueries({
					queryKey: getGetMyCarriersListQueryKey(),
				});
			},
		},
	});

	return (
		<AlertDialog>
			<AlertDialogTrigger>
				<Button variant="outline">
					{t('pages.carriers.actions.remove', 'Remove')}
				</Button>
			</AlertDialogTrigger>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>
						{t('pages.carriers.modal.title', 'Remove Carrier relationship')}
					</AlertDialogTitle>
					<AlertDialogDescription>
						{t(
							'pages.carriers.modal.body',
							'Are you sure that you want to remove your relationship with {{carrierParty}}?',
							{ carrierParty: carrier.carrierParty }
						)}
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel>
						{t('general.actions.cancel', 'Cancel')}
					</AlertDialogCancel>
					<AlertDialogAction
						onClick={() => {
							mutate({
								data: carrier,
							});
						}}
					>
						{t('pages.carriers.actions.remove', 'Remove')}
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
}
