import { type ShipmentLocationActionDto } from '@uturn/api/v1';
import { ShipmentDtoShipmentStatus as ShipmentStatus } from '@uturn/api/v2';
import { Card } from '@uturn/ui-kit';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
	isShipmentArchived,
	isShipmentMatched,
	toZodLocations,
} from '../../utils';
import { PossibleRoutes } from './possible-routes';
import { type Coordinates, GoogleMap } from '@uturn/portal/components/map';
import type {
	FormValues,
	ZodLocation,
} from '@uturn/portal/modules/shipments/schema';

const defaultCoordinates: Coordinates = {
	lat: 51.91359903088673,
	lng: 4.439946801361003,
};

export function ShipmentMapSection({
	shipmentStatus = ShipmentStatus.UNKNOWN,
	initLocationActions,
}: {
	shipmentStatus?: ShipmentStatus;
	initLocationActions?: ShipmentLocationActionDto[];
}) {
	const form = useFormContext<FormValues>();
	const [coordinates, setCoordinates] = useState<Coordinates[]>([]);

	const updateCoordinates = (locations: ZodLocation[]) => {
		const newCoordinates: Coordinates[] =
			locations
				?.filter(
					(location) =>
						!!location?.action?.location?.address?.latitude &&
						!!location?.action?.location?.address?.longitude
				)
				.map((location) => ({
					// TODO: Upgrade to Typescript 5.5 to rewrite code below to:
					// lat: location.action.location.address.latitude,
					// lng: location.action.location.address.longitude,
					lat: location?.action?.location?.address?.latitude as number,
					lng: location?.action?.location?.address?.longitude as number,
				})) ?? [];
		setCoordinates(newCoordinates);
	};

	useEffect(() => {
		if (!initLocationActions) return;
		const locationActions = toZodLocations(initLocationActions);
		updateCoordinates(locationActions);
	}, [initLocationActions]);

	useEffect(() => {
		const subscription = form.watch((value, { name }) => {
			if (name?.match(/^locations\.[0-9]+\.action\.location/)) {
				const locationActions = value.locations as ZodLocation[];
				updateCoordinates(locationActions);
			}
		});
		return () => subscription.unsubscribe();
	}, [form.watch]);

	return (
		<Card className="h-full overflow-hidden">
			<GoogleMap
				initialLocation={defaultCoordinates}
				locations={coordinates}
				zoom={7}
			/>
			<PossibleRoutes
				archived={isShipmentArchived(shipmentStatus)}
				matched={isShipmentMatched(shipmentStatus)}
			/>
		</Card>
	);
}
