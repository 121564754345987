import { faEllipsis, faFile } from '@fortawesome/pro-regular-svg-icons';
import type { ColumnDef } from '@tanstack/react-table';
import {
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	useReactTable,
} from '@tanstack/react-table';
import {
	getInvoiceExcel,
	getInvoicePdf,
	getInvoiceUBL,
	useGetShipperInvoices,
} from '@uturn/api/v1';
import type { InvoiceDTO } from '@uturn/api/v1';

import {
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
	Icon,
	PaginationCompact,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@uturn/ui-kit';
import type { TFunction } from 'i18next';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OverviewEmptyState } from './empty-state';
import { downloadFile, formatDate, formatPrice } from '@uturn/portal/utils';

const columns = (
	t: TFunction<'translation', undefined>
): ColumnDef<InvoiceDTO>[] => [
	{
		accessorKey: 'invoiceNumber',
		header: t('pages.invoices.invoice_number', 'Invoice number')!,
	},
	{
		accessorFn: (row) => formatPrice(row.amount!, row.currency!.code!),
		header: t('general.amount', 'Amount')!,
	},
	{
		accessorFn: (row) => formatPrice(row.vat!, row.currency!.code!),
		header: t('general.VAT', 'VAT')!,
	},
	{
		accessorFn: (row) => formatPrice(row.totalAmount!, row.currency!.code!),
		header: t('general.total_amount', 'Total amount')!,
	},
	{
		accessorFn: (row) => formatDate(row.invoiceDateUtc!),
		header: t('pages.invoices.invoice_date', 'Invoice date')!,
	},
	{
		id: 'download',
		cell: (cell) => {
			const row = cell.row.original;
			return (
				<div className="flex justify-end pr-3.5 gap-3">
					<Button
						onClick={() => {
							downloadFile(
								getInvoicePdf(row.invoiceNumber!, {
									responseType: 'blob',
								}),
								// TODO: Update translation to use query
								`${t(
									'pages.invoices.invoice',
									'Invoice'
								)}-${row.invoiceNumber!}.pdf`
							);
						}}
						variant="outline"
					>
						<Icon icon={faFile} className="mr-1.5" />
						{t('pages.invoices.download', 'Download {{type}}', {
							type: 'PDF',
						})}
					</Button>
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button variant="outline">
								<Icon icon={faEllipsis} />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent>
							<DropdownMenuItem
								onClick={() =>
									downloadFile(
										getInvoiceExcel(row.invoiceNumber!, {
											responseType: 'blob',
										}),
										// TODO: Update translation to use query
										`${t(
											'pages.invoices.invoice',
											'Invoice'
										)}-${row.invoiceNumber!}.xlsx`
									)
								}
							>
								{t('pages.invoices.dropdown.details', 'Details (Excel)')}
							</DropdownMenuItem>
							<DropdownMenuItem
								onClick={() =>
									downloadFile(
										getInvoiceUBL(row.invoiceNumber!, {
											responseType: 'blob',
										}),
										// TODO: Update translation to use query
										`${t(
											'pages.invoices.invoice',
											'Invoice'
										)}-${row.invoiceNumber!}.xml`
									)
								}
							>
								{t('pages.invoices.dropdown.e-invoice', 'E-invoice (UBL)')}
							</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			);
		},
	},
];

export function InvoicesTable() {
	const [page, setPage] = useState(0);
	const { data } = useGetShipperInvoices({ page, pageSize: 20 } as any);
	const totalPages = useMemo(() => {
		return Math.ceil(
			(data?.data.totalElements ?? 1) / (data?.data.pageSize ?? 1)
		);
	}, []);
	const { t } = useTranslation();

	const table = useReactTable({
		data: data?.data.data as InvoiceDTO[],
		columns: columns(t),
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		pageCount: totalPages,
		manualPagination: true,
		onPaginationChange: (newPagination: any) => {
			setPage(newPagination(page).pageIndex);
		},
	});

	if (!data?.data.data!.length) {
		return <OverviewEmptyState />;
	}

	return (
		<>
			<div className="rounded-md border">
				<Table>
					<TableHeader>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<TableHead key={header.id}>
											{header.isPlaceholder
												? null
												: flexRender(
														header.column.columnDef.header,
														header.getContext()
												  )}
										</TableHead>
									);
								})}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows?.length ? (
							table.getRowModel().rows.map((row) => (
								<TableRow
									key={row.id}
									data-state={row.getIsSelected() && 'selected'}
								>
									{row.getVisibleCells().map((cell) => (
										<TableCell key={cell.id}>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</TableCell>
									))}
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell
									colSpan={columns.length}
									className="h-24 text-center"
								>
									No results.
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>

			<PaginationCompact
				page={page}
				setPage={setPage}
				totalPages={totalPages}
			/>
		</>
	);
}
