import {
	faArrowTurnDownLeft,
	faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import { Icon } from '@uturn/ui-kit';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type Location = {
	name: string;
	city: string;
};

type ShipmentSearchResult = {
	invoiceReference: string;
	containerNumber: string;
	shipmentNumber: number;
	id: number;
	organizationId: number;
	locations: Location[];
	_highlightResult: any;
};

const TAG_REPLACEMENT = {
	highlightPreTag: '<strong>',
	highlightPostTag: '</strong>',
};

const TAG_PLACEHOLDER = {
	highlightPreTag: '__ais-highlight__',
	highlightPostTag: '__/ais-highlight__',
};

function getHighlightedPartsFromString(highlightedValue: string) {
	const { highlightPostTag, highlightPreTag } = TAG_REPLACEMENT;

	const splitByPreTag = highlightedValue.split(highlightPreTag);
	const firstValue = splitByPreTag.shift();
	const elements = !firstValue
		? []
		: [{ value: firstValue, isHighlighted: false }];

	splitByPreTag.forEach((split) => {
		const splitByPostTag = split.split(highlightPostTag);

		elements.push({
			value: splitByPostTag[0],
			isHighlighted: true,
		});

		if (splitByPostTag[1] !== '') {
			elements.push({
				value: splitByPostTag[1],
				isHighlighted: false,
			});
		}
	});

	return elements;
}

function Highlighted({ value }: { value: string }) {
	const highlightedValue = value
		.replaceAll(
			TAG_PLACEHOLDER.highlightPreTag,
			TAG_REPLACEMENT.highlightPreTag
		)
		.replaceAll(
			TAG_PLACEHOLDER.highlightPostTag,
			TAG_REPLACEMENT.highlightPostTag
		);

	const parts = getHighlightedPartsFromString(highlightedValue);

	return (
		<>
			{parts.map((part, partIndex) => {
				if (Array.isArray(part)) {
					return (
						<Fragment key={partIndex}>
							{part.map((subPart, subPartIndex) => (
								<span key={subPartIndex}>{subPart.value}</span>
							))}
						</Fragment>
					);
				}

				if (part.isHighlighted) {
					return (
						<span key={partIndex} className="font-bold text-primary">
							{part.value}
						</span>
					);
				}

				return (
					<>
						{part.value.startsWith(' ') ? (
							<>&nbsp;{part.value.trim()}</>
						) : (
							<span key={partIndex}>{part.value}</span>
						)}
					</>
				);
			})}
		</>
	);
}

function getHighlightedValues(obj: any) {
	const highlightedParts: any[] = [];
	Object.entries(obj).forEach(([key, value]) => {
		const keyValue = (value as { value?: string })?.value ?? '';

		if (typeof keyValue === 'string') {
			const hasMatchWithQuery = keyValue
				.toLowerCase()
				.includes('ais-highlight');

			if (hasMatchWithQuery) {
				highlightedParts.push({ key, value: keyValue });
			}
		}
	});

	return highlightedParts;
}

function getHighlightedParts({ result }: { result: ShipmentSearchResult }) {
	const highlightedParts: any[] = [];

	// eslint-disable-next-line no-underscore-dangle
	const highlightedValues = getHighlightedValues(result._highlightResult);
	highlightedParts.push(...highlightedValues);

	// eslint-disable-next-line no-underscore-dangle
	(result._highlightResult.locations as Location[]).forEach((location) => {
		const highlightedValuesLocation = getHighlightedValues(location);
		highlightedParts.push(...highlightedValuesLocation);
	});

	return highlightedParts;
}

export function ShipmentSearchHit({
	result,

	handleCloseSearch,
}: {
	result: ShipmentSearchResult;

	handleCloseSearch?: () => void;
}) {
	const { t } = useTranslation();
	const highlightedParts = getHighlightedParts({
		result,
	});

	return (
		<Link
			to={`/shipments/${result.shipmentNumber}`}
			onClick={handleCloseSearch}
			onKeyDown={(event) => {
				if (event.key === 'Enter') {
					event.currentTarget.click();
				}
			}}
		>
			<div className="flex flex-col gap-1 items-start py-2 px-5 cursor-pointer hover:bg-accent hover:text-accent-foreground group relative">
				<div className="flex">
					{highlightedParts.length > 0 &&
						highlightedParts.map(({ key, value }, index) => {
							if (index !== 0) return null;
							return <Highlighted key={key} value={value} />;
						})}
				</div>
				<span className="font-light text-sm items-center text-muted-foreground flex gap-2">
					{t(
						`general.shipments.search_status.${result.shipmentStatus.toLowerCase()}` // TODO - Add default?
					)}
					<Icon
						className="w-3 h-3 text-muted-foreground"
						icon={faChevronRight}
					/>
					#{result.shipmentNumber}
				</span>
				<div className="absolute top-1/2 -translate-y-1/2 right-5 opacity-0 group-hover:opacity-100">
					<Icon
						className="w-3 h-3 text-muted-foreground"
						icon={faArrowTurnDownLeft}
					/>
				</div>
			</div>
		</Link>
	);
}
