import {
	Button,
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';

export function ConfirmRevokePendingChangesModal({
	handleRevokeChangeRequest,
	openModal,
	setOpenModal,
}: {
	handleRevokeChangeRequest: () => void;
	openModal: boolean;
	setOpenModal: (open: boolean) => void;
}) {
	const { t } = useTranslation();
	return (
		<Dialog open={openModal} onOpenChange={setOpenModal}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>
						{t(
							'pages.shipment-details.modal.revoke-change-request.heading',
							'Revoke changes'
						)}
					</DialogTitle>
					<DialogDescription>
						{t(
							'pages.shipment-details.modal.revoke-change-request.desc',
							'Are you sure you would like to revoke your changes?'
						)}
					</DialogDescription>
				</DialogHeader>
				<DialogFooter>
					<DialogClose asChild>
						<Button variant="outline">
							{t('general.actions.cancel', 'Cancel')}
						</Button>
					</DialogClose>
					<Button
						variant="destructive"
						onClick={(e) => {
							e.preventDefault();
							handleRevokeChangeRequest();
						}}
					>
						{t(
							'pages.shipment-details.modal.revoke-change-request.actions.confirm',
							'Revoke changes'
						)}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
