export function getNextPrevShipments({
	shipments,
	currentShipmentNumber,
}: {
	shipments: any[];
	currentShipmentNumber: number;
}) {
	const currentShipmentIndex = shipments.findIndex(
		(shipment) => shipment.shipmentNumber === currentShipmentNumber
	);
	const next = shipments[currentShipmentIndex + 1];
	const prev = shipments[currentShipmentIndex - 1];
	return { next, prev };
}
