import { Logo } from '@uturn/ui';
import { Button } from '@uturn/ui-kit';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import authPhoto from '../../../assets/truck-on-road.webp';
import { PasswordResetForm } from '../components/password-reset-form';
import LanguageSelector from '@uturn/portal/components/language-selector';

export function AuthResetPassword() {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<>
			<Helmet title={t('pages.auth.password_reset.title', 'Reset password')!} />
			<div className="container relative min-h-screen flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
				<div className="absolute right-12 bottom-12 sm:right-4 sm:top-4 md:right-8 md:top-8">
					<LanguageSelector />
				</div>
				<div className="hidden lg:block h-screen overflow-hidden">
					<img
						src={authPhoto as string}
						alt=""
						className="saturate-150 object-cover w-full h-full"
					/>
				</div>
				<div className="lg:p-8 w-full">
					<div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
						<div className="flex flex-col space-y-2 text-center">
							<Logo className="h-12 mb-8 w-auto" />
							<h1 className="text-2xl font-semibold tracking-tight">
								{t('pages.auth.password_reset.title', 'Reset your password')}
							</h1>
							<p className="text-sm text-muted-foreground">
								{t(
									'pages.auth.password_reset.description',
									`Enter your new password and we'll get you on your way.`
								)}
							</p>
						</div>
						<PasswordResetForm />
						<div className="text-sm text-muted-foreground text-center">
							<Button variant="link" onClick={() => navigate('/sign-in')}>
								{t('pages.auth.password_reset.actions.back', 'Back to login')}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
