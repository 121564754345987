import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import {
	type ValidationsResult,
	newValidationsResult,
	validationsError,
} from '@uturn/portal/types';

export function useShipperReferenceValidation() {
	const { t } = useTranslation();
	/**
	 * If organisation settings state that invoice is required,
	 * then shipperReference is required.
	 *
	 * @param shipperReference
	 * @param invoiceRequired
	 * @param publishing Publishing or updating published shipment
	 * @param validationsResult
	 * @returns
	 */
	const validateShipperReference = (
		shipperReference: string,
		invoiceRequired: boolean,
		publishing: boolean,
		validationsResult?: ValidationsResult
	): ValidationsResult => {
		let results = validationsResult ?? newValidationsResult();

		if (!publishing || invoiceRequired) {
			return results;
		}

		const shipperReferenceSchema = z.string({
			required_error: t(
				'general.errors.form_validation.required',
				'Required.'
			)!,
		});
		try {
			shipperReferenceSchema.parse(shipperReference);
		} catch (err) {
			if (err instanceof z.ZodError) {
				results = validationsError(
					results,
					err.issues[0].message,
					'shipperReference'
				);
			}
		}
		return results;
	};
	return { validateShipperReference };
}
