import { ShipmentLocationActionRequestDtoLocationActionType as LocationActionType } from '@uturn/api/v1';
import { shippingLineSchema } from '../container';
import { z } from '@uturn/portal/zod';

/**
 * a.k.a. locationActions
 *
 * ShipmentLocationActionRequestDto as LocationAction, // [POST] Shipment
 * ShipmentLocationActionDto, //                          [GET]  Shipment
 * ShipmentLocationActionUpdateDto, //                    [PUT]  Shipment
 */

/** ShipmentLocationActionRequestDtoLocationActionType */
const locationActionTypeSchema = z.nativeEnum(LocationActionType).optional();

/** AddressDto */
const addressSchema = z.object({
	city: z.string().optional(),
	countryCode: z.string().optional(),
	houseNumber: z.string().optional(),
	latitude: z.number().optional(),
	longitude: z.number().optional(),
	postalCode: z.string().optional(),
	street: z.string().optional(),
});

/** ShippingLocationRequestDto for POST and PUT Shipment */
export const locationRequestSchema = z.object({
	id: z.number().optional(),
	googlePlaceId: z.string().optional(), // required when GET Shipment
});

/** ShippingLocationDto for GET Shipment */
const locationResponseSchema = z.object({
	name: z.string().optional(), // required when GET Shipment
	address: addressSchema.optional(),
	averageRating: z.number().optional(),
	fourShippingTerminalCode: z.string().optional(),
	portbaseBics: z.string().optional(),
	timesRated: z.number().optional(),
});

export const locationSchema = locationRequestSchema.merge(
	locationResponseSchema
);

/** ShipmentLocationActionRequestDto for POST Shipment */
const locationActionRequestSchema = z.object({
	location: locationSchema, // 🔴 Required
	// .optional()
	// .refine((val) => !!val?.action?.location?.id, {
	// 	message: 'Location is required',
	// }),
	locationActionType: locationActionTypeSchema, // 🔴 Required
	// .refine((val) => !!val, {
	// 	message: 'Action type is required', // Not on the form, so, useless validation?
	// }),
	customerName: z.string().optional(),
	reference: z.string().optional(),
	remarks: z.string().optional(),
	shippingLineId: z.coerce.number().optional(), // from the new shipment only
	sequenceNumber: z.number().optional(), // required if creating or publishing
	port: z.string().optional(),
	vesselName: z.string().optional(),
});

const locationActionRequestConceptSchema = locationActionRequestSchema.extend({
	dateFrom: z.string().datetime({ offset: true }).optional(),
	dateUntil: z.string().datetime({ offset: true }).optional(),
});

const locationActionRequestPublishSchema = locationActionRequestSchema.extend({
	// LEGACY: z.string().pipe(z.coerce.date()),
	// .transform((value) => value && convertToIsoDateWithTimezone(new Date(value))),
	dateFrom: z.string().datetime({ offset: true }), // 🔴 Required
	dateUntil: z.string().datetime({ offset: true }), // 🔴 Required
});

/** ShipmentLocationActionDto for GET Shipment */
const locationActionResponseSchema = z.object({
	/**
	 * number <= BE
	 * string <= FE: '1' | '2' | '3' | crypto.randomUUID()
	 */
	id: z.union([z.string(), z.number()]).optional(),
	shippingLine: shippingLineSchema.optional(),
	eta: z.string().optional(),
	arrival: z.string().optional(),
	departure: z.string().optional(),
});

export const locationActionConceptSchema =
	locationActionRequestConceptSchema.merge(locationActionResponseSchema);

export const locationActionPublishSchema =
	locationActionRequestPublishSchema.merge(locationActionResponseSchema);
