import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Input,
} from '@uturn/ui-kit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { FormValues } from '@uturn/portal/modules/shipments/schema';

export function ShipmentGroup() {
	const { t } = useTranslation();
	const form = useFormContext<FormValues>();

	return (
		<div className="grid grid-cols-1 sm:grid-cols-6 w-full gap-3">
			<div className="grid grid-cols-1 col-span-3 sm:grid-cols-6 gap-6">
				<div className="sm:col-span-full">
					<FormField
						control={form.control}
						name="newGroup.name"
						render={({ field }) => (
							<FormItem>
								<FormLabel className="required-asterix">
									{t('pages.shipment-details.group.name', 'Group name')}
								</FormLabel>
								<FormControl>
									<Input {...field} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<div className="sm:col-span-3">
					<FormField
						control={form.control}
						name="numberOfCopiesToBeCreated"
						render={({ field }) => (
							<FormItem>
								<FormLabel className="required-asterix">
									{t(
										'pages.shipment-details.group.number-of-shipments',
										'Number of shipments'
									)}
								</FormLabel>
								<FormControl>
									<Input
										{...field}
										type="number"
										onChange={(e) => field.onChange(e.target.valueAsNumber)}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
			</div>
		</div>
	);
}
