import { Logo } from '@uturn/ui';
import { Button } from '@uturn/ui-kit';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import authPhoto from '../../../assets/truck-on-road.webp';
import { PasswordResetRequestForm } from '../components/password-reset-request-form';
import { SignInForm } from '../components/sign-in-form';
import LanguageSelector from '@uturn/portal/components/language-selector';

export function AuthSignIn() {
	const [passwordReset, setPasswordReset] = useState(false);

	const { t } = useTranslation();

	const toOldPlatform = async (url: string) => {
		window.location.href = `${window.location.origin}${url}`
			.replace('staging', 'test')
			.replace('uturn-now', 'uturn-system');
	};

	return (
		<>
			<Helmet
				title={
					passwordReset
						? t('pages.auth.password_reset.title', 'Reset password')!
						: t('pages.auth.sign-in.title', 'Sign in')!
				}
			/>
			<div className="container relative min-h-screen flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
				<div className="absolute right-12 bottom-12 sm:right-4 sm:top-4 md:right-8 md:top-8">
					<div className="flex items-center gap-4">
						<Button
							variant="ghost"
							onClick={async () => toOldPlatform('/register')}
						>
							{t('auth.register', 'Register')}
						</Button>
						<LanguageSelector />
					</div>
				</div>
				<div className="hidden lg:block h-screen overflow-hidden">
					<img
						src={authPhoto as string}
						alt=""
						className="saturate-150 object-cover w-full h-full"
					/>
				</div>
				<div className="lg:p-8 w-full">
					<div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
						{passwordReset ? (
							<>
								<div className="flex flex-col space-y-2 text-center">
									<Logo className="h-12 mb-8 w-auto" />
									<h1 className="text-2xl font-semibold tracking-tight">
										{t(
											'pages.auth.password_reset_request.title',
											'Request a password reset'
										)}
									</h1>
									<p className="text-sm text-muted-foreground">
										{t(
											'pages.auth.password_reset_request.description',
											'Enter your email and we ll email you a link to reset your password.'
										)}
									</p>
								</div>
								<PasswordResetRequestForm />
								<div className="text-sm text-muted-foreground text-center">
									<Button
										variant="link"
										onClick={() => setPasswordReset(false)}
									>
										{t(
											'pages.auth.password_reset_request.actions.back',
											'Back to login'
										)}
									</Button>
								</div>
							</>
						) : (
							<>
								<div className="flex flex-col space-y-2 text-center">
									<Logo className="h-12 mb-8 w-auto" />
									<h1 className="text-2xl font-semibold tracking-tight">
										{t(
											'pages.auth.sign-in.heading',
											'Lets sign in to your account'
										)}
									</h1>
									<p className="text-sm text-muted-foreground">
										{t(
											'pages.auth.sign-in.description',
											'Enter your email and password and we ll get you on your way.'
										)}
									</p>
								</div>
								<SignInForm />
								<p className="text-sm text-muted-foreground text-center">
									{t('pages.auth.sign-in.question', 'Are you a carrier?')}{' '}
									<Button
										variant="link"
										onClick={() => toOldPlatform('/login')}
									>
										{t(
											'pages.auth.sign-in.actions.carrier-login',
											'Please sign in here'
										)}
									</Button>
								</p>
								<p className="text-sm text-muted-foreground text-center">
									<Button variant="link" onClick={() => setPasswordReset(true)}>
										{t(
											'pages.auth.sign-in.actions.reset-password',
											'Forgot password?'
										)}
									</Button>
								</p>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
