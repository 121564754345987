import { ShipmentLocationActionDtoLocationActionType } from '@uturn/api/v1';
import type { Item } from '../components/multi-select-editor';
import { TransportTypeCode } from '@uturn/portal/types/shipment';

export const matchLocationField = (colId: string, property: string) => {
	const regex = new RegExp(`location_[0-9]*_${property}`);
	return !!regex.exec(colId);
};

export const getInitialLocations = (
	transportType: TransportTypeCode
): ShipmentLocationActionDtoLocationActionType[] => {
	if (transportType === TransportTypeCode.IMPORT) {
		return [
			ShipmentLocationActionDtoLocationActionType['Pick-up'],
			ShipmentLocationActionDtoLocationActionType.Deliver,
			ShipmentLocationActionDtoLocationActionType.Drop,
		];
	}

	if (transportType === TransportTypeCode.EXPORT) {
		return [
			ShipmentLocationActionDtoLocationActionType['Pick-up'],
			ShipmentLocationActionDtoLocationActionType.Load,
			ShipmentLocationActionDtoLocationActionType.Drop,
		];
	}

	if (transportType === TransportTypeCode.SHUNT) {
		return [
			ShipmentLocationActionDtoLocationActionType['Pick-up'],
			ShipmentLocationActionDtoLocationActionType.Drop,
		];
	}

	return [];
};

export const getDefaultLocationData = (
	transportType: TransportTypeCode
): Record<string, Item> => {
	const locations = getInitialLocations(transportType);

	return locations.reduce(
		(acc, location, index) => ({
			...acc,
			[`location_${index + 1}_type`]: {
				value: {
					value: location.toLowerCase(),
					label: location,
				},
			},
		}),
		{}
	);
};
