import {
	WeightUnit,
	useFetchOrganizationById,
	useFetchUturnUserById,
} from '@uturn/api/v1';
import type { ConditionType, ShippingLineDTO } from '@uturn/api/v1';
import {
	Button,
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	RadioGroup,
	RadioGroupItem,
} from '@uturn/ui-kit';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EditDefaultValuesModal } from '../../components';
import { MetaDataContext } from '@uturn/portal/context';
import { useAddShipmentStore } from '@uturn/portal/store/shipments/add-shipment';
import {
	ContainerStatus,
	TransportTypeCode,
	TypeOfShipment,
} from '@uturn/portal/types/shipment';

const ManualOption = () => {
	const {
		initData,
		orgDefaultValues,
		currencyCode,
		setManualCreateInit,
		setDefaultValues,
		setOrgDefaultValues,
		setCurrencyCode,
	} = useAddShipmentStore();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { shipmentMetaData, metadata } = useContext(MetaDataContext);

	const { data: user } = useFetchUturnUserById(
		metadata!.user?.id as number,
		{}
	);

	const { data: organisation } = useFetchOrganizationById(
		Number(metadata?.user?.organisation?.id) ?? 0
	);

	const [openEditDefaultValuesModal, setOpenEditDefaultValuesModal] =
		useState(false);

	const form = useForm({
		defaultValues: {
			transportType: TransportTypeCode.IMPORT,
			typeOfShipment: TypeOfShipment.SINGLE,
		},
	});

	const formRef = useRef<HTMLFormElement>(null);

	const handleFormSubmit = () => {
		if (formRef.current === null) return;

		formRef.current.dispatchEvent(
			new Event('submit', { cancelable: true, bubbles: true })
		);
	};

	const onSubmit = (data: any) => {
		setManualCreateInit(
			data.transportType,
			data.typeOfShipment,
			initData,
			orgDefaultValues,
			currencyCode
		);

		navigate('/shipments/add/manual');
	};

	useEffect(() => {
		if (!organisation) return;

		const { invoiceRequired, shipmentCondition } =
			organisation?.data.settings ?? {};

		setOrgDefaultValues({
			invoiceRequired: invoiceRequired ?? false,
			conditions: shipmentCondition as ConditionType,
		});

		const { currency } = organisation.data.financialDetails ?? {};

		if (currency) {
			setCurrencyCode(currency);
		}
	}, [organisation]);

	useEffect(() => {
		if (!user) return;

		const {
			fixedPrice,
			shippingLineId,
			requiresVat,
			transportType: transportTypeString,
			containerStatus: containerStatusString,
		} = user.data.settings ?? {};

		const transportType =
			(transportTypeString?.toLowerCase() as TransportTypeCode) ??
			TransportTypeCode.IMPORT;

		const containerStatus =
			(containerStatusString?.toLowerCase() as ContainerStatus) ??
			ContainerStatus.FULL;

		form.setValue('transportType', transportType);

		const defaultShippingLine =
			shippingLineId && shippingLineId > -1
				? shipmentMetaData?.shippingLines?.find(
						(shippingLine: ShippingLineDTO) =>
							shippingLine.id === shippingLineId
				  )
				: undefined;

		setDefaultValues({
			fixedPrice,
			dutiesPaid: requiresVat,
			containerStatus,
			grossWeight: {
				quantity: 0,
				unit: WeightUnit.kilogram,
			},
			shippingLineId: defaultShippingLine?.id,
			shippingLine: defaultShippingLine,
		});
	}, [user]);

	return (
		<Card>
			<CardHeader className="pb-4">
				<CardTitle>
					{t('pages.addshipment.manual.heading', 'Create manual shipment')}
				</CardTitle>
				<CardDescription>
					{t(
						'pages.addshipment.manual.subheading',
						'Select a transport type to create a new shipment.'
					)}
				</CardDescription>
			</CardHeader>
			<CardContent className="flex flex-col gap-4">
				<Form {...form}>
					<form ref={formRef} onSubmit={form.handleSubmit(onSubmit)}>
						<FormField
							control={form.control}
							name="transportType"
							defaultValue={TransportTypeCode.IMPORT}
							render={({ field }) => (
								<FormItem>
									<FormControl>
										<RadioGroup
											onValueChange={(e) => {
												field.onChange(e);
											}}
											value={field.value}
											className="grid grid-cols-3 gap-4 capitalize"
										>
											<FormItem>
												<FormControl>
													<RadioGroupItem
														value={TransportTypeCode.IMPORT}
														id="import"
														className="peer sr-only"
													/>
												</FormControl>
												<FormLabel
													htmlFor="import"
													className="cursor-pointer flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover px-4 py-3 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary peer-data-[state=checked]:text-primary [&:has([data-state=checked])]:text-primary"
												>
													{TransportTypeCode.IMPORT}
												</FormLabel>
											</FormItem>
											<FormItem>
												<FormControl>
													<RadioGroupItem
														id="export"
														className="peer sr-only"
														value={TransportTypeCode.EXPORT}
													/>
												</FormControl>
												<FormLabel
													htmlFor="export"
													className="cursor-pointer flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover px-4 py-3 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary  peer-data-[state=checked]:text-primary [&:has([data-state=checked])]:text-primary"
												>
													{TransportTypeCode.EXPORT}
												</FormLabel>
											</FormItem>
											<FormItem>
												<FormControl>
													<RadioGroupItem
														id="shunt"
														className="peer sr-only"
														value={TransportTypeCode.SHUNT}
													/>
												</FormControl>
												<FormLabel
													htmlFor="shunt"
													className="cursor-pointer flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover px-4 py-3 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary peer-data-[state=checked]:text-primary [&:has([data-state=checked])]:text-primary"
												>
													{TransportTypeCode.SHUNT}
												</FormLabel>
											</FormItem>
										</RadioGroup>
									</FormControl>
								</FormItem>
							)}
						/>
					</form>
				</Form>
				<div className="my-4 grid grid-cols-2 gap-3">
					<div className="col-span-2 flex flex-col space-y-1.5">
						<h3 className="font-semibold leading-none tracking-tight">
							My prefilled values
						</h3>
						<p className="text-sm text-muted-foreground">
							The new shipment you create will be prefilled with your default
							values.
						</p>
					</div>
					<div>
						<Button
							variant="secondary"
							onClick={() => setOpenEditDefaultValuesModal(true)}
						>
							{t('pages.addshipment.manual.default_values', 'Update my values')}
						</Button>
						<EditDefaultValuesModal
							openModal={openEditDefaultValuesModal}
							setOpenModal={setOpenEditDefaultValuesModal}
						/>
					</div>
				</div>
			</CardContent>
			<CardFooter className="flex justify-end gap-2">
				<Button
					disabled={!form.formState.isValid}
					onClick={() => {
						form.setValue('typeOfShipment', TypeOfShipment.GROUP);
						handleFormSubmit();
					}}
					variant="secondary"
				>
					{t(
						'pages.addshipment.manual.grouped-submit',
						'Create grouped shipment'
					)}
				</Button>
				<Button disabled={!form.formState.isValid} onClick={handleFormSubmit}>
					{t('pages.addshipment.manual.submit', 'Create new shipment')}
				</Button>
			</CardFooter>
		</Card>
	);
};

export default ManualOption;
