import { DateTimePicker } from '@uturn/ui';
import {
	AccordionContent,
	AccordionTrigger,
	FormItem,
	Input,
	Label,
} from '@uturn/ui-kit';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterContext } from './context';
import { formatDatetime } from '@uturn/portal/utils';

export function DateFilter() {
	const { filters, setFilters } = useContext(FilterContext);
	const { t } = useTranslation();
	return (
		<>
			<AccordionTrigger>
				{t('pages.shipments.filters.date.title', 'date')}
			</AccordionTrigger>
			<AccordionContent className="grid grid-cols-2 lg:grid-cols-none gap-3">
				<FormItem>
					<Label>{t('pages.shipments.filters.date.from', 'From')}</Label>
					<DateTimePicker
						value={formatDatetime(filters['filter.fromDateTime'])}
						onChange={(date: Date | null) => {
							if (date === null) {
								setFilters({
									'filter.fromDateTime': undefined,
								});
								return;
							}
							// const date = new Date(e!);
							date.setHours(0, 0, 0, 0);

							setFilters({
								'filter.fromDateTime': date.toISOString(),
							});
						}}
						customInput={<Input />}
					/>
				</FormItem>
				<FormItem>
					<Label>{t('pages.shipments.filters.date.until', 'Until')}</Label>
					<DateTimePicker
						value={formatDatetime(filters['filter.untilDateTime'])}
						onChange={(date: Date | null) => {
							if (date === null) {
								setFilters({
									'filter.untilDateTime': undefined,
								});
								return;
							}

							// const date = new Date(e!);
							date.setHours(23, 59, 59, 0);

							setFilters({
								'filter.untilDateTime': date.toISOString(),
							});
						}}
						customInput={<Input />}
					/>
				</FormItem>
			</AccordionContent>
		</>
	);
}
