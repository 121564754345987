import {
	Button,
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	RadioGroup,
	RadioGroupItem,
} from '@uturn/ui-kit';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { TransportTypeCode } from '@uturn/portal/types/shipment';

const BulkOption = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const form = useForm({
		defaultValues: {
			bulkTransportType: TransportTypeCode.IMPORT,
		},
	});

	const formRef = useRef<HTMLFormElement>(null);

	const handleFormSubmit = () => {
		if (formRef.current === null) return;

		formRef.current.dispatchEvent(
			new Event('submit', { cancelable: true, bubbles: true })
		);
	};

	const onSubmit = (data: any) => {
		navigate({
			pathname: '/shipments/add/bulk',
			search: createSearchParams({
				transportType: data.bulkTransportType,
			}).toString(),
		});
	};

	return (
		<Card>
			<CardHeader className="flex flex-row justify-between items-center">
				<CardTitle>
					{t('pages.addshipment.bulk.heading', 'Bulk create shipments')}
				</CardTitle>
			</CardHeader>
			<CardContent>
				<Form {...form}>
					<form ref={formRef} onSubmit={form.handleSubmit(onSubmit)}>
						<FormField
							control={form.control}
							name="bulkTransportType"
							defaultValue={TransportTypeCode.IMPORT}
							render={({ field }) => (
								<FormItem>
									<FormControl>
										<RadioGroup
											onValueChange={(e) => {
												field.onChange(e);
											}}
											value={field.value}
											className="grid grid-cols-3 gap-4 capitalize"
										>
											<FormItem>
												<FormControl>
													<RadioGroupItem
														value={TransportTypeCode.IMPORT}
														id="bulkImport"
														className="peer sr-only"
													/>
												</FormControl>
												<FormLabel
													htmlFor="bulkImport"
													className="cursor-pointer flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover px-4 py-3 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary peer-data-[state=checked]:text-primary [&:has([data-state=checked])]:text-primary"
												>
													{TransportTypeCode.IMPORT}
												</FormLabel>
											</FormItem>
											<FormItem>
												<FormControl>
													<RadioGroupItem
														id="bulkExport"
														className="peer sr-only"
														value={TransportTypeCode.EXPORT}
													/>
												</FormControl>
												<FormLabel
													htmlFor="bulkExport"
													className="cursor-pointer flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover px-4 py-3 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary  peer-data-[state=checked]:text-primary [&:has([data-state=checked])]:text-primary"
												>
													{TransportTypeCode.EXPORT}
												</FormLabel>
											</FormItem>
											<FormItem>
												<FormControl>
													<RadioGroupItem
														id="bulkShunt"
														className="peer sr-only"
														value={TransportTypeCode.SHUNT}
													/>
												</FormControl>
												<FormLabel
													htmlFor="bulkShunt"
													className="cursor-pointer flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover px-4 py-3 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary peer-data-[state=checked]:text-primary [&:has([data-state=checked])]:text-primary"
												>
													{TransportTypeCode.SHUNT}
												</FormLabel>
											</FormItem>
										</RadioGroup>
									</FormControl>
								</FormItem>
							)}
						/>
					</form>
				</Form>
			</CardContent>
			<CardFooter className="flex justify-end gap-2">
				<Button disabled={!form.formState.isValid} onClick={handleFormSubmit}>
					{t('pages.addshipment.bulk.submit', 'Create bulk shipments')}
				</Button>
			</CardFooter>
		</Card>
	);
};

export default BulkOption;
