import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import type { ShippingLineDTO } from '@uturn/api/v1';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	FormControl,
	FormField,
	FormFieldReadOnly,
	FormItem,
	FormLabel,
	FormMessage,
	Icon,
	Input,
	PopoverSelect,
} from '@uturn/ui-kit';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { FormValues } from '@uturn/portal/modules/shipments/schema';
import {
	isFieldReadOnly,
	shouldUpdate,
} from '@uturn/portal/modules/shipments/utils';

export type ShipInformationProps = {
	readOnlyFields?: string[];
	shippingLines: ShippingLineDTO[];
	indexInLocations?: number;
};

export function ShipInformation({
	readOnlyFields,
	shippingLines,
	indexInLocations,
}: ShipInformationProps) {
	const { t } = useTranslation();
	const form = useFormContext<FormValues>();

	const fieldRef = (name: string) => {
		return `locations.${indexInLocations}.${name}` as keyof FormValues; // as const;
	};

	const hasData = (): boolean => {
		const fields = ['shippingLineId', 'port', 'vesselName'];
		return fields.some(
			(field) => !!form.getValues(fieldRef(`action.${field}`))
		);
	};

	const [collapsed, setCollapsed] = useState(!hasData());

	return (
		<div className="sm:col-span-full">
			<Accordion type="single" collapsible value={collapsed ? '' : 'ship-info'}>
				<AccordionItem value="ship-info" className="border-0">
					<AccordionPrimitive.Header className="flex">
						<AccordionPrimitive.Trigger
							className={
								'flex flex-1 items-center justify-between py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180'
							}
							onClick={() => setCollapsed(!collapsed)}
						>
							<div className="text-sm pb-3 flex items-center font-medium leading-6">
								<Icon
									icon={collapsed ? faPlus : faMinus}
									className="ml-1 mr-2 h-5"
								/>
								{t(
									'pages.shipment-details.add-ship-information.button',
									'Add ship information'
								)}
							</div>
						</AccordionPrimitive.Trigger>
					</AccordionPrimitive.Header>
					<AccordionContent>
						<div className="grid grid-cols-1 gap-5 sm:grid-cols-6">
							<div className="sm:col-span-full">
								<FormField
									control={form.control}
									name={fieldRef('action.shippingLineId')}
									render={({ field }) => (
										<FormItem className="flex flex-col">
											<FormLabel>
												{t(
													'pages.shipment-details.add-ship-information.shipping-line.label',
													'Shipping Line'
												)}
											</FormLabel>
											{isFieldReadOnly(
												readOnlyFields!,
												'locationActions.shippingLineId'
											) ? (
												<FormFieldReadOnly
													value={
														form.getValues(fieldRef('action.shippingLine'))
															?.description ?? '-'
													}
												/>
											) : (
												<FormControl>
													<PopoverSelect
														onChange={field.onChange}
														selected={String(field.value ?? '')}
														options={shippingLines.map((item) => {
															return {
																label: item.description!,
																value: String(item.id!),
																raw: item,
															};
														})}
														onSelect={(item) =>
															form.setValue(
																fieldRef('action.shippingLine'),
																item,
																shouldUpdate
															)
														}
														placeholder={
															t(
																'pages.shipment-details.add-ship-information.shipping-line.placeholder',
																'Select shipping line'
															) as string
														}
														filter={{
															placeholder: t(
																'pages.shipment-details.add-ship-information.shipping-line.dropdown.placeholder',
																'Search shipping line...'
															) as string,
															empty: 'No shipping line found.',
														}}
														position="top"
													/>
												</FormControl>
											)}
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
							<div className="sm:col-span-3">
								<FormField
									control={form.control}
									name={fieldRef('action.vesselName')}
									render={({ field }) => (
										<FormItem>
											<FormLabel className="flex items-center">
												{t(
													'pages.shipment-details.add-shipment-location.vessel-name',
													'Vessel name'
												)}
											</FormLabel>
											{isFieldReadOnly(
												readOnlyFields!,
												'locationActions.vesselName'
											) ? (
												<FormFieldReadOnly value={field.value ?? '-'} />
											) : (
												<FormControl>
													<Input {...field} />
												</FormControl>
											)}
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
							<div className="sm:col-span-3">
								<FormField
									control={form.control}
									name={fieldRef('action.port')}
									render={({ field }) => (
										<FormItem>
											<FormLabel className="flex items-center">
												{t(
													'pages.shipment-details.add-shipment-location.port-of-origin',
													'port of origin'
												)}
											</FormLabel>
											{isFieldReadOnly(
												readOnlyFields!,
												'locationActions.portOfOrigin'
											) ? (
												<FormFieldReadOnly value={field.value ?? '-'} />
											) : (
												<FormControl>
													<Input {...field} />
												</FormControl>
											)}
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
						</div>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</div>
	);
}
