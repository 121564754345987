import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { Button, Icon } from '@uturn/ui-kit';
import { useTranslation } from 'react-i18next';

export type StickyBarActionsProps = {
	sendingShipmentData?: boolean;
	publishShipment: () => void;
	saveAsConcept: () => void;
	isConcept: boolean;
	targetPrice: string;
	numberOfShipments?: number;
	isGroupedShipment?: boolean;
	isDirty?: boolean;
};

export function StickyBarActions({
	sendingShipmentData = false,
	publishShipment,
	saveAsConcept,
	isConcept,
	targetPrice,
	numberOfShipments = 1,
	isGroupedShipment = false,
	isDirty,
}: StickyBarActionsProps) {
	const { t } = useTranslation();

	const labels = {
		save: t(
			'pages.create_shipment.sticky_bar.save_concept_button',
			'Save as concept'
		),
		saving: t(
			'pages.create_shipment.sticky_bar.saving_concept_button',
			'Saving...'
		),
		publish: isGroupedShipment
			? t(
					'pages.create_shipment.sticky_bar.publish_group_shipment_button',
					'Publish {{numberOfShipments}} shipment(s) - {{targetPrice}}/container',
					{ targetPrice, numberOfShipments }
			  )
			: t(
					'pages.create_shipment.sticky_bar.publish_shipment_button',
					'Publish shipment - target price {{targetPrice}}',
					{ targetPrice }
			  ),
		publishing: isGroupedShipment
			? t(
					'pages.create_shipment.sticky_bar.publishing_group_shipment_button',
					'Publishing {{numberOfShipments}} shipment(s)...',
					{ numberOfShipments }
			  )
			: t(
					'pages.create_shipment.sticky_bar.publishing_shipment_button',
					'Publishing shipment - price {{targetPrice}}...',
					{ targetPrice }
			  ),
	};

	return (
		<div className="flex justify-end items-center h-full my-auto">
			<div className="flex flex-row gap-4">
				<Button
					variant="outline"
					onClick={saveAsConcept}
					disabled={sendingShipmentData || !isDirty}
				>
					{isConcept && sendingShipmentData ? (
						<>
							<Icon className="mr-2 h-4 w-4" icon={faSpinnerThird} spin />
							{labels.saving}
						</>
					) : (
						labels.save
					)}
				</Button>
				<Button
					variant="default"
					onClick={publishShipment}
					disabled={sendingShipmentData || !isDirty}
				>
					{!isConcept && sendingShipmentData ? (
						<>
							<Icon className="mr-2 h-4 w-4" icon={faSpinnerThird} spin />
							{labels.publishing}
						</>
					) : (
						labels.publish
					)}
				</Button>
			</div>
		</div>
	);
}
