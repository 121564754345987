import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { Avatar, Logo } from '@uturn/ui';
import {
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
	Icon,
	ModeToggle,
} from '@uturn/ui-kit';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GlobalSearch } from './global-search';
import ReportABug from './report-a-bug';
import { Navigation } from '@uturn/portal/components/header/navigation';
import LanguageSelector from '@uturn/portal/components/language-selector';

export function UserNav({
	dropdownItems,
	user,
}: {
	dropdownItems: { name: string; href: string }[];
	user: { name: string; role: string };
}) {
	const { t } = useTranslation();

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" className="relative h-8 w-8 rounded-full">
					<Avatar round size="2.3rem" name={user.name} />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-56" align="end" forceMount>
				<DropdownMenuLabel className="font-normal">
					<div className="flex flex-col space-y-1">
						<p className="text-sm font-medium leading-none">{user.name}</p>
						<p className="text-xs leading-none text-muted-foreground">
							{user.role}
						</p>
					</div>
				</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<DropdownMenuGroup>
					{dropdownItems.map((item) => (
						<Link to={item.href} key={item.href}>
							<DropdownMenuItem className="cursor-pointer">
								{item.name}
							</DropdownMenuItem>
						</Link>
					))}
				</DropdownMenuGroup>
				<DropdownMenuSeparator />
				<ReportABug />
				<DropdownMenuSeparator />
				<Link to="/logout">
					<DropdownMenuItem className="cursor-pointer">
						{t('header.navigation.user.log-out', 'Log out')}
					</DropdownMenuItem>
				</Link>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}

export function Search() {
	const { t } = useTranslation();
	const [searchOpen, setSearchOpen] = useState(false);

	return (
		<>
			<Button
				variant="outline"
				className="bg-transparent justify-start text-muted-foreground text-sm"
				onClick={() => setSearchOpen(!searchOpen)}
			>
				<span className="hidden lg:block">
					{t('header.search.trigger-button', 'Search shipments')}
				</span>
				<Icon className="block lg:hidden -mx-1" icon={faMagnifyingGlass} />
			</Button>

			<Suspense>
				<GlobalSearch
					openSearch={searchOpen}
					handleCloseSearch={() => setSearchOpen(!searchOpen)}
				/>
			</Suspense>
		</>
	);
}

export function Header({
	userNavigation,
	dropdownItems,
	user,
}: {
	userNavigation: { name: string; href: string }[];
	dropdownItems: { name: string; href: string }[];
	user: { name: string; role: string };
}) {
	return (
		<div className="border-b sticky top-0 z-20 bg-card/95 backdrop-blur supports-[backdrop-filter]:bg-card/60">
			<div className="flex h-16 items-center px-4 lg:px-8 3xl:px-0 3xl:w-2/3 m-auto">
				<Link to="/shipments">
					<Logo className="h-8 w-auto -mt-1" />
				</Link>
				<div className="flex w-full ml-6 gap-4 md:gap-0 flex-row-reverse md:flex-row">
					<Navigation navItems={userNavigation} />
					<div className="ml-auto flex items-center gap-4 md:gap-1 lg:gap-4">
						<Search />
						<div className="hidden md:block">
							<ModeToggle />
						</div>
						<div className="hidden md:block">
							<LanguageSelector />
						</div>
						<UserNav dropdownItems={dropdownItems} user={user} />
					</div>
				</div>
			</div>
		</div>
	);
}
