import {
	CommandDialog,
	DialogTitle,
	ScrollArea,
	buttonVariants,
} from '@uturn/ui-kit';
import { VisuallyHidden } from 'react-aria';
import { useTranslation } from 'react-i18next';
import { useInstantSearch, useSearchBox } from 'react-instantsearch';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { CustomSearchBox } from './search-box';
import { ShipmentSearchHit } from './shipment-search-result';

const SearchResults = ({
	handleCloseSearch,
}: {
	handleCloseSearch: () => void;
}) => {
	const { results } = useInstantSearch();
	const { t } = useTranslation();

	return (
		<>
			{results.query.length > 2 && (
				<>
					<ScrollArea className="max-h-[27rem]">
						<div className="w-full transition-all py-4">
							{results.hits.map((hit) => (
								<ShipmentSearchHit
									key={hit.id}
									result={hit}
									handleCloseSearch={handleCloseSearch}
								/>
							))}
							{results.hits.length === 0 && (
								<div className="text-center text-gray-400 px-5">
									{t(
										'general.globalSearch.noResults',
										'No results found for {{query}}',
										{ query: results.query }
									)}
								</div>
							)}
						</div>
					</ScrollArea>
					{results.hits.length > 0 && (
						<Link
							className={twMerge(
								buttonVariants({ variant: 'link' }),
								'text-popover-foreground text-center w-full py-3 mt-2 border-t-2 rounded-none'
							)}
							to="/search"
							onClick={handleCloseSearch}
						>
							{t(
								'general.globalSearch.see_all_results',
								'See more details of {{amount_of_results}} results',
								{ amount_of_results: results.hits.length }
							)}
						</Link>
					)}
				</>
			)}
		</>
	);
};

export function GlobalSearch({
	openSearch,
	handleCloseSearch,
}: {
	openSearch: boolean;
	handleCloseSearch: () => void;
}) {
	const searchBox = useSearchBox();
	const { t } = useTranslation();
	return (
		<CommandDialog open={openSearch} onOpenChange={handleCloseSearch}>
			<div className="w-full">
				<VisuallyHidden>
					<DialogTitle>
						{t('general.globalSearch.title', 'Global search')}
					</DialogTitle>
				</VisuallyHidden>
				<CustomSearchBox {...searchBox} handleSearchClose={handleCloseSearch} />
				<SearchResults handleCloseSearch={handleCloseSearch} />
			</div>
		</CommandDialog>
	);
}
