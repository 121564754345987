import type { ButtonProps } from '@uturn/ui-kit';
import { Button, buttonVariants, cn } from '@uturn/ui-kit';
import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';

const activeTabClass = 'bg-card hover:bg-card/80 shadow-sm';
const inactiveTabClass =
	'opacity-50 hover:bg-transparent hover:underline hover:opacity-100';
const defaultTabClass = 'justify-start px-4';

export function Tab(props: TabType) {
	const { title, active } = props;
	if ('onClick' in props && props.onClick) {
		return (
			<Button
				variant="ghost"
				size="sm"
				onClick={props.onClick}
				className={cn(
					active ? activeTabClass : inactiveTabClass,
					defaultTabClass
				)}
			>
				{title}
			</Button>
		);
	}
	return (
		<Link
			to={'to' in props ? props.to : ''}
			className={cn(
				buttonVariants({
					variant: 'ghost',
					size: 'sm',
				}),
				active ? activeTabClass : inactiveTabClass,
				defaultTabClass
			)}
		>
			{title}
		</Link>
	);
}

type BasicTab = {
	title: ReactNode;
	active?: boolean;
};

export type TabLink = BasicTab & { to: string };
export type TabButton = BasicTab & {
	onClick: ButtonProps['onClick'];
};

export type TabType = TabLink | TabButton;

export function Tabs({ start, end }: { start?: TabType[]; end?: TabType[] }) {
	if (!start?.length && !end?.length) return null;

	return (
		<div className="flex justify-between flex-wrap gap-2">
			{!!start?.length && (
				<div className="flex bg-accent p-1 rounded-md w-fit flex-wrap">
					{start.map((tab, i) => (
						<Tab
							key={i}
							to={'to' in tab ? tab.to : undefined}
							onClick={'onClick' in tab ? tab.onClick : undefined}
							title={tab.title}
							active={tab.active}
						/>
					))}
				</div>
			)}
			{!!end?.length && (
				<div className="flex bg-accent p-1 rounded-md w-fit ml-auto">
					{end.map((tab, i) => (
						<Tab
							key={i}
							to={'to' in tab ? tab.to : undefined}
							onClick={'onClick' in tab ? tab.onClick : undefined}
							title={tab.title}
							active={tab.active}
						/>
					))}
				</div>
			)}
		</div>
	);
}
