import { CurrencyType, type ShipmentListDTO } from '@uturn/api/v1';
import { OpenShipments as OpenShipmentsEmptyState } from '@uturn/design-tokens/empty-states';
import { EmptyState } from '@uturn/ui';
import { Button } from '@uturn/ui-kit';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
	RequirementBadges,
	ShipmentPage,
} from '@uturn/portal/modules/shipments/components';
import { formatPrice } from '@uturn/portal/utils';

function PublishedShipmentEmptyState() {
	const { t } = useTranslation();
	return (
		<EmptyState>
			<OpenShipmentsEmptyState className="md:h-[20rem]" />
			<h2 className="text-secondary-700 text-2xl font-medium leading-7">
				{t(
					'pages.shipments.published.emptyState.title',
					'Time to start shipping!'
				)}
			</h2>
			<p className="text-secondary-600 text-lg leading-6">
				{t(
					'pages.shipments.published.emptyState.description',
					"We noticed that you don't have any open shipments right now. Publish a new shipment right away or create a concept first."
				)}
			</p>
			<div>
				<Link to="/shipments/add">
					<Button>{t('pages.shipments.actions.start', 'Get started')}</Button>
				</Link>
			</div>
		</EmptyState>
	);
}

export function PublishedShipments() {
	const { t } = useTranslation();

	const shipmentDetails = (shipment: ShipmentListDTO) => [
		{
			label: t('pages.shipments.details.reference'),
			value: shipment.shipperReference,
			visibleOnMultiSelect: true,
			enableCopyToClipboard: true,
			hideOnCompact: true,
		},
		{
			label: t('pages.shipments.details.containerNumber'),
			value: shipment.containerNumber,
			visibleOnMultiSelect: true,
			enableCopyToClipboard: true,
		},
		{
			label: t('pages.shipments.details.targetPrice', 'target price'),
			value: formatPrice(
				shipment.price?.quantity ?? 0,
				shipment.price?.currencyCode ?? CurrencyType.EUR
			),
			visibleOnMultiSelect: true,
		},
		{
			label: t('pages.shipments.details.details', 'Details'),
			value: (shipment.requirements || shipment.shipmentUnitDescription) && (
				<RequirementBadges
					requirements={[
						shipment.shipmentUnitDescription ?? '',
						...shipment.requirements,
					]}
				/>
			),
		},
	];

	return (
		<>
			<Helmet
				title={t('pages.shipments.published.title', 'Published shipments')!}
			/>
			<ShipmentPage
				enableGroupFilter={true}
				EmptyState={PublishedShipmentEmptyState}
				details={shipmentDetails}
				hasQuotes
			/>
		</>
	);
}
