import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { useQueryClient } from '@tanstack/react-query';
import {
	type FileTypeDTO,
	type FileUploadRequest,
	useAddDocumentToOrganization,
} from '@uturn/api/v1';
import {
	Button,
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	Form,
	FormItem,
	Icon,
	Input,
	Label,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
	Separator,
	useToast,
} from '@uturn/ui-kit';

import { useContext, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { FileDropzone } from '@uturn/portal/components/file-dropzone';
import { MetaDataContext } from '@uturn/portal/context';

export function OrganizationDocumentsLayout() {
	const { shipmentMetaData } = useContext(MetaDataContext);
	const { t } = useTranslation();

	const [rawFileTypes, setRawFileTypes] = useState<FileTypeDTO[]>([]);
	const [fileTypeOptions, setFileTypeOptions] = useState([] as any[]);

	const [openModal, setOpenModal] = useState(false);

	const queryClient = useQueryClient();

	const [toUploadFile, setToUploadedFile] = useState<File>();

	const { toast } = useToast();

	const methods = useForm<FileUploadRequest>({
		defaultValues: {
			description: '',
			fileName: '',
			type: undefined,
		},
	});

	const onFileChangeCapture = (files: File[]) => {
		methods.setValue('fileName', files![0].name);
		setToUploadedFile(files![0]);
	};

	const removeFile = () => {
		setToUploadedFile(undefined);
		methods.setValue('fileName', '');
	};

	const addDocumentToOrganization = useAddDocumentToOrganization();

	const onSubmit = (data: FileUploadRequest) => {
		if (!toUploadFile) return;

		const properties = {
			description: data.description,
			fileName: data.fileName,
			type: data.type,
		};

		addDocumentToOrganization.mutate(
			{
				data: {
					file: toUploadFile,
					properties,
				},
			},
			{
				onSuccess: () => {
					queryClient.invalidateQueries();
					setOpenModal(false);
					setToUploadedFile(undefined);
					toast({
						title: t(
							'pages.organisation.documents.form.submit.success.title',
							'Document uploaded'
						)!,
						description: t(
							'pages.organisation.documents.form.submit.success.description',
							'Your document has been uploaded'
						),
					});
				},
				onError: (e: any) => {
					console.log('error', e);
					toast({
						title: t('toast.error-title', 'Uh oh! Something went wrong')!,
						description: t(
							'pages.organisation.documents.form.submit.error.description',
							'Your document has not been uploaded'
						),
					});
				},
			}
		);
	};

	useEffect(() => {
		if (!shipmentMetaData) return;

		const { fileTypes } = shipmentMetaData;

		if (!fileTypes) return;

		setRawFileTypes(fileTypes);

		setFileTypeOptions(
			// @TODO remove any when types are fixed for cancellationTypes
			fileTypes?.reduce((result: Record<string, string>[], type: any) => {
				if (type.objectType === 'PARTY') {
					result.push({
						label: type.name ?? '',
						value: type.code ?? '',
					});
				}

				return result;
			}, []) ?? []
		);
	}, [shipmentMetaData]);

	return (
		<>
			<Helmet
				title={
					t(
						'pages.account.organisation.documents.title',
						'Organisation - Documents'
					)!
				}
			/>
			<div className="space-y-6">
				<div className="flex items-center justify-between space-y-2">
					<div>
						<h3 className="text-lg font-medium">
							{t('pages.account.organisation.documents.title', 'Documents')}
						</h3>
						<p className="text-sm text-muted-foreground">
							{t(
								'pages.account.organisation.documents.description',
								'Manage the documents in your organisation'
							)}
						</p>
					</div>
					<div className="flex items-center space-x-2">
						<Dialog open={openModal} onOpenChange={setOpenModal}>
							<DialogTrigger asChild>
								<Button>
									{t(
										'pages.organisation.documents.actions.add',
										'Add new document'
									)}
								</Button>
							</DialogTrigger>
							<DialogContent className="sm:max-w-[425px]">
								<DialogHeader>
									<DialogTitle>
										{t('pages.adddocument.pagetitle', 'Document upload')}
									</DialogTitle>
									<DialogDescription>
										{t('general.text', 'Text')}
									</DialogDescription>
								</DialogHeader>
								<Form {...methods}>
									<form onSubmit={methods.handleSubmit(onSubmit)}>
										<div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:max-w-xl sm:grid-cols-6">
											<div className="col-span-full">
												<FormItem>
													<Label>
														{t('pages.adddocument.formlabels.type', 'Type')}
													</Label>
													<Select
														onValueChange={(value) => {
															methods.setValue(
																'type',
																rawFileTypes.find(
																	(type: any) => type.code === value
																)!
															);
														}}
													>
														<SelectTrigger className="w-full">
															<SelectValue
																placeholder={
																	<span>
																		{t(
																			'pages.adddocument.formlabels.type-placeholder',
																			'Select a file type'
																		)}
																	</span>
																}
															/>
														</SelectTrigger>
														<SelectContent>
															{fileTypeOptions.map((option) => (
																<SelectItem
																	key={option.value}
																	value={option.value}
																>
																	{option.label}
																</SelectItem>
															))}
														</SelectContent>
													</Select>
												</FormItem>
											</div>
											<div className="col-span-full">
												<FormItem>
													<Label>
														{t(
															'pages.adddocument.formlabels.description',
															'Description'
														)}
													</Label>
													<Input
														onChange={(e) =>
															methods.setValue('description', e.target.value)
														}
													/>
												</FormItem>
											</div>
											<div className="col-span-full">
												{toUploadFile ? (
													<section className="max-w-xl">
														<div className="flex justify-center items-center w-full h-40 p-4 transition bg-secondary-50/20 border-2 border-secondary-300 border-dashed rounded-md appearance-none focus:outline-none">
															<div className="flex items-center">
																<span className="text-sm">
																	{methods.getValues('fileName')}
																</span>
																<Button variant="ghost" onClick={removeFile}>
																	<Icon
																		icon={faTrashCan}
																		className="w-4 h-4 -ml-1 pt-0.5"
																	/>
																</Button>
															</div>
														</div>
													</section>
												) : (
													<Dropzone
														onDrop={(acceptedFiles) =>
															onFileChangeCapture(acceptedFiles)
														}
													>
														{({ getRootProps, getInputProps }) => (
															<FileDropzone
																getInputProps={getInputProps}
																getRootProps={getRootProps}
															/>
														)}
													</Dropzone>
												)}
											</div>
											<div className="col-span-full">
												<DialogFooter>
													<DialogClose asChild>
														<Button type="button" variant="outline">
															{t('general.cancel', 'Cancel')}
														</Button>
													</DialogClose>
													<Button type="submit">
														{t('pages.adddocument.submit', 'Save document')}
													</Button>
												</DialogFooter>
											</div>
										</div>
									</form>
								</Form>
							</DialogContent>
						</Dialog>
					</div>
				</div>
				<Separator />
				<Outlet />
			</div>
		</>
	);
}
