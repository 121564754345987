import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import {
	type Carrier,
	type ReviewRequest,
	type ReviewResponse,
	getFetchReviewQueryKey,
	useSaveReview,
} from '@uturn/api/v2';
import {
	Button,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Icon,
	Ratings,
	SheetClose,
	SheetDescription,
	SheetHeader,
	SheetTitle,
	Stars,
	Textarea,
	useToast,
} from '@uturn/ui-kit';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import type { CarrierReview } from '@uturn/portal/types/shipment';

const useLabels = () => {
	const { t } = useTranslation();
	return {
		rating: t(
			'pages.order.shipment-details.assigned-carrier.review.form.rating',
			'Rating'
		),
		comment: t(
			'pages.order.shipment-details.assigned-carrier.review.form.comment',
			'Comment'
		),
		createdAt: t(
			'pages.order.shipment-details.assigned-carrier.review.form.create_at',
			'Date reviewed'
		),
	};
};

export function CarrierReviewForm({
	shipmentNumber,
}: {
	shipmentNumber: number;
}) {
	const { t } = useTranslation();
	const labels = useLabels();

	const formSchema = z
		.object({
			rating: z.coerce
				.number()
				.min(1, {
					message: t(
						'general.errors.form_validation.select_required',
						'Must select a {{propertyName}}',
						{ propertyName: labels.rating }
					) as string,
				})
				.max(5),
			comment: z
				.string()
				.max(1000, {
					message: t(
						'general.errors.form_validation.max',
						'{{propertyName}} must be less than {{maxLength}} characters',
						{ propertyName: labels.comment, maxLength: 1000 }
					) as string,
				})
				.optional(),
		})
		.superRefine((values, context) => {
			if (values.rating <= 3 && (!values.comment || values.comment === '')) {
				context.addIssue({
					code: z.ZodIssueCode.custom,
					message: t(
						'pages.order.shipment-details.assigned-carrier.review.form.validation.comment_required',
						'Tell us something about your review'
					) as string,
					path: ['comment'],
				});
			}
		});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			rating: 0,
			comment: '',
		},
	});

	const { toast } = useToast();
	const queryClient = useQueryClient();
	const saveReview = useSaveReview();

	const onSubmit = (formData: z.infer<typeof formSchema>) => {
		saveReview.mutate(
			{
				id: shipmentNumber,
				data: formData as unknown as ReviewRequest,
			},
			{
				onSuccess: () => {
					toast({
						title: 'Success',
						description: t(
							'pages.order.shipment-details.assigned-carrier.review.submit.success',
							'Review saved'
						),
					});
					queryClient.invalidateQueries({
						queryKey: getFetchReviewQueryKey(shipmentNumber),
					});
				},
				onError: () => {
					toast({
						variant: 'destructive',
						title: 'Uh oh! Something went wrong.',
						description: t(
							'pages.order.shipment-details.assigned-carrier.review.submit.error',
							'Review could not be saved'
						),
					});
				},
			}
		);
	};

	const formRef = useRef<HTMLFormElement>(null);

	const handleSubmit = () => {
		if (formRef.current === null) return;

		formRef.current.dispatchEvent(
			new Event('submit', { cancelable: true, bubbles: true })
		);
	};

	const forwardSave = (data: any) => {
		onSubmit(data);
	};

	const handleSubmitWithoutPropagation = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		form.handleSubmit(forwardSave)(e);
	};

	return (
		<div className="col-span-full flex flex-col gap-1">
			<h3 className="text font-heading font-medium tracking-tight">
				{t(
					'pages.order.shipment-details.assigned-carrier.review.form.heading',
					'Leave a review'
				)}
			</h3>
			<p className="text-sm leading-6 pb-2">
				{t(
					'pages.order.shipment-details.assigned-carrier.review.form.subheading',
					'UTURN backoffice will evaluate reviews and potentially forward them to the carrier. A review can only be added once for each shipment.'
				)}
			</p>
			<Form {...form}>
				<form
					onSubmit={handleSubmitWithoutPropagation}
					className="space-y-4"
					ref={formRef}
				>
					<FormField
						control={form.control}
						name="rating"
						render={({ field }) => (
							<FormItem>
								<FormLabel>{labels.rating}</FormLabel>
								<FormControl>
									{/*
									 * Not really sure why spreading field here works since the Ratings component does not have any callbacks.
									 * should probably add a callback function to the Ratings component, but this fixes the issue for now
									 */}
									<Ratings {...field} value={field.value ?? 0} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="comment"
						render={({ field }) => (
							<FormItem>
								<FormLabel>{labels.comment}</FormLabel>
								<FormControl>
									<Textarea {...field} rows={6} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<div className="sm:col-span-full flex justify-end gap-3">
						<SheetClose asChild>
							<Button
								size="sm"
								variant="secondary"
								disabled={saveReview.isLoading}
							>
								{t(
									'pages.order.shipment-details.assigned-carrier.review.form.cancel',
									'Cancel'
								)}
							</Button>
						</SheetClose>
						<Button
							type="submit"
							size="sm"
							onClick={handleSubmit}
							disabled={saveReview.isLoading}
						>
							{saveReview.isLoading ? (
								<>
									<Icon className="mr-2 h-4 w-4" icon={faSpinnerThird} spin />
									{t(
										'pages.order.shipment-details.assigned-carrier.review.form.loading',
										'Saving...'
									)}
								</>
							) : (
								t(
									'pages.order.shipment-details.assigned-carrier.review.form.submit',
									'Send review'
								)
							)}
						</Button>
					</div>
				</form>
			</Form>
		</div>
	);
}

export function CarrierReviewDetails({
	carrierReview,
}: {
	carrierReview: ReviewResponse;
}) {
	const { t } = useTranslation();
	const labels = useLabels();
	return (
		<div className="col-span-full flex flex-col gap-1">
			<h3 className="text font-heading font-medium tracking-tight pb-2">
				{t(
					'pages.order.shipment-details.assigned-carrier.review.details.title',
					'Review'
				)}
			</h3>
			<p className="text-sm text-muted-foreground leading-6 pb-2">
				{t(
					'pages.order.shipment-details.assigned-carrier.review.form.subheading',
					'UTURN backoffice will evaluate reviews and potentially forward them to the carrier. A review can only be added once for each shipment.'
				)}
			</p>
			<dl className="pb-2">
				<dt>{labels.rating}</dt>
				<dd>
					<Stars rating={carrierReview.rating} />
				</dd>
			</dl>
			{carrierReview.comment && (
				<dl className="pb-5">
					<dt>{labels.comment}</dt>
					<dd>
						<Textarea disabled rows={6}>
							{carrierReview.comment}
						</Textarea>
					</dd>
				</dl>
			)}
			<div className="sm:col-span-full flex justify-end">
				<SheetClose asChild>
					<Button size="sm" variant="secondary">
						{t(
							'pages.order.shipment-details.assigned-carrier.review.details.close',
							'Close'
						)}
					</Button>
				</SheetClose>
			</div>
		</div>
	);
}

export function ReviewCarrierSheet({
	shipmentNumber,
	carrier,
	carrierReview,
}: {
	shipmentNumber: number;
	carrier: Carrier;
	carrierReview: CarrierReview;
}) {
	const { t } = useTranslation();

	return (
		<>
			<SheetHeader>
				<SheetTitle>
					{t(
						'pages.order.shipment-details.assigned-carrier.review.heading',
						'Review carrier'
					)}
				</SheetTitle>
				<SheetDescription>
					{t(
						'pages.order.shipment-details.assigned-carrier.review.subheading',
						'Review the carrier on the Uturn platform.'
					)}
				</SheetDescription>
			</SheetHeader>

			<div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:max-w-xl sm:grid-cols-6 pt-4">
				<div className="col-span-full flex flex-col gap-1">
					<h3 className="text font-heading font-medium tracking-tight">
						{carrier.name}
					</h3>
				</div>
				{!carrierReview ? (
					<CarrierReviewForm shipmentNumber={shipmentNumber} />
				) : (
					<CarrierReviewDetails carrierReview={carrierReview} />
				)}
			</div>
		</>
	);
}
