import './sentry/instrument';
import { acquireTokenSilent, configureAPI, isAuthenticated } from '@uturn/api';
import { LicenseManager, ThemeProvider } from '@uturn/ui-kit';
import type { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { StrictMode, Suspense } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ApiProvider } from './api';
import 'leaflet/dist/leaflet.css';
import { App } from './app/app';
import { LoadingSpinnerWithLogo } from './components';
import { DevTools } from './dev';
import { Scripts } from './scripts';
import './i18n';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

dayjs.extend(customParseFormat);

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

configureAPI({
	portal: {
		url: import.meta.env.VITE_API_URL,
		key: import.meta.env.VITE_API_KEY,
	},
	auth: {
		url: import.meta.env.VITE_API_AUTH_URL,
		key: import.meta.env.VITE_API_AUTH_KEY,
	},
	portalInterceptors: {
		request: [
			async (config: any) => {
				// If we're not authenticated, just return the config.
				if (!isAuthenticated()) return config;

				try {
					await acquireTokenSilent();
					return config;
				} catch (e) {
					window.location.href = '/sign-in';
				}

				return undefined;
			},
		],
	},
});

const options: Partial<PostHogConfig> = {
	api_host: import.meta.env.VITE_POSTHOG_HOST,
	disable_session_recording: true,
	debug: true,
	capture_pageleave: false,
	capture_pageview: false,
	autocapture: false,
};

function AppRoot() {
	const { t } = useTranslation();

	return (
		<StrictMode>
			<PostHogProvider
				apiKey={import.meta.env.VITE_POSTHOG_KEY}
				options={options}
			>
				<HelmetProvider>
					<Helmet
						title={t('general.title', 'Portal')!}
						titleTemplate={'%s | UTURN'}
					/>
					<Scripts />
					<ThemeProvider
						defaultMode="light"
						defaultColor="uturn"
						storageKey="platform-theme"
					>
						<ApiProvider>
							<Suspense fallback={<LoadingSpinnerWithLogo />}>
								<App />
							</Suspense>
							{import.meta.env.DEV && <DevTools />}
						</ApiProvider>
					</ThemeProvider>
				</HelmetProvider>
			</PostHogProvider>
		</StrictMode>
	);
}

ReactDOM.createRoot(document.getElementById('root')!).render(<AppRoot />);
