import { CurrencyType, WeightUnit } from '@uturn/api/v1';
import { z } from '@uturn/portal/zod';

const itemSchema = z.object({
	value: z.string(),
	label: z.string(),
});

const locationSchema = z
	.object({
		location: z.object({
			id: z.number().optional(),
			googlePlaceId: z.string().optional(),
		}),
		from: z.date().refine((data) => data > new Date(), {
			message: 'Start date must be in the future',
		}),
		until: z.date(),
		type: itemSchema, // Should be PICKUP, DROP, STOP, DELIVER etc. (enum preferably)
		reference: z.string().optional(),
		vesselName: z.string().optional(),
		port: z.string().optional(),
		remarks: z.string().optional(),
		shippingLine: itemSchema.optional(),
	})
	.refine((data) => data.until > data.from, {
		message: 'Until date cannot be earlier than from date',
		path: ['until'],
	});

export const schema = z.object({
	containerType: itemSchema.extend({ isoCode: z.string() }),
	isoCode: z.string().length(4),
	generatorSet: z.boolean().default(false),
	containerNumber: z
		.string()
		.length(11)
		.refine((value: string) => value.substring(4).match('[0-9]{7}'), {
			params: {
				i18n: {
					key: 'last_7_numbers',
				},
			},
		})
		.refine((value: string) => /^[aA-zZ]{4}/.test(value), {
			params: {
				i18n: {
					key: 'first_4_alpha',
				},
			},
		})
		.optional(),
	shippingLine: itemSchema.optional(),
	seal: z.string().optional(),
	cargoDescription: z.string().optional(),
	requirements: itemSchema.array().optional(),
	unCode: z.string().length(4).pipe(z.coerce.number()).optional(),
	cargoGrossWeight: z.object({
		quantity: z.number().max(99999),
		unit: z.nativeEnum(WeightUnit),
	}),
	price: z.object({
		quantity: z.number(),
		currency: z.nativeEnum(CurrencyType),
	}),
	acceptancePrice: z.boolean().default(false),
	invoiceReference: z.string().optional(),
	vat: z.boolean().default(false),
	preferredCarriers: itemSchema.array().optional(),
	termsAndConditions: itemSchema,
	locations: locationSchema.array(),
});

type Create = z.infer<typeof schema>;
type PartialCreate = Partial<Create>;

type Transform<T> = {
	[K in keyof T]: { value: T[K]; isValid?: boolean; errorMessage?: string };
};
export type TransformedPartialCreate = Transform<PartialCreate>;

export const transformBack = <T>(obj: Transform<T>) =>
	Object.keys(obj).reduce((acc, key) => {
		acc[key as keyof T] = obj[key as keyof Transform<T>].value;
		return acc;
	}, {} as T);
