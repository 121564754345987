import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { CurrencyType, WeightUnit } from '@uturn/api/v1';
import { AgGridReact, Icon } from '@uturn/ui-kit';
import type {
	CustomCellEditorProps,
	CustomCellRendererProps,
	GridOptions,
	ITooltipParams,
	ValueFormatterParams,
	ValueGetterParams,
	ValueSetterParams,
} from '@uturn/ui-kit';
import equal from 'fast-deep-equal';
import { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CurrencyCellEditor from './components/currency-editor';
import DateTimePickerEditor from './components/date-time-picker-editor';
import LocationAutoCompleteEditor from './components/location-auto-complete-editor';
import MultiSelectEditor from './components/multi-select-editor';
import type { Item } from './components/select-editor';
import SelectEditor from './components/select-editor';
import WeightCellEditor from './components/weight-editor';
import useColumns from './hooks/use-columns';
import useShipmentTableOptions from './hooks/use-shipment-table-options';
import { getDefaultLocationData, matchLocationField } from './utils/locations';
import { TransportTypeCode } from '@uturn/portal/types/shipment';
import { formatDatetime, formatPrice, formatWeight } from '@uturn/portal/utils';
import { formatSuggestion } from '@uturn/portal/utils/format-address-lookup-suggestions';
import dayjs from 'dayjs';

const GRID_ROW_COUNT = 50;

const getTermsAndConditions = (locations) => {
	const locationCountryCodes = Object.values(locations)
		.filter((location) => location?.country?.countryCode)
		.map((location) => location?.country?.countryCode);

	if (locationCountryCodes.length === 0) {
		// Reset to default data when there are no (longer any) locations
		return {
			value: 'avc',
			label: 'AVC',
		};
	}

	const isCrossBorderShipment = locationCountryCodes.some(
		(countryCode) => countryCode !== 'NLD',
	);
	const isGBRShipment = locationCountryCodes.every(
		(countryCode) => countryCode === 'GBR',
	);
	switch (true) {
		case isGBRShipment:
			return {
				value: 'rha',
				label: 'RHA',
			};
		case isCrossBorderShipment:
			return {
				value: 'cmr',
				label: 'CMR',
			};
		default:
			return {
				value: 'avc',
				label: 'AVC',
			};
	}
};

const getDefaultRowData = (transportType: TransportTypeCode) => {
	const DEFAULT_ROW_DATA = {
		cargoGrossWeight: {
			value: {
				unit: WeightUnit.kilogram,
			},
		},
		price: {
			value: {
				currency: CurrencyType.EUR,
			},
		},
		vat: { value: false },
		generatorSet: { value: false },
		termsAndConditions: {
			value: {
				value: 'avc',
				label: 'AVC',
			},
		},
		bidOnly: { value: false },
		emptyContainer: { value: false },
	};

	return {
		...DEFAULT_ROW_DATA,
		...getDefaultLocationData(transportType),
	};
};

const isDirty = (newData: any, defaultData: any) => {
	const { id, dirty, ...data } = newData;
	const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
		// Deleting a value in the table, will set the cell to an empty string
		if (value.value !== undefined && value.value !== '') {
			// We only wanna compare the actual values not any other properties, so we implicity only se tthe value property
			acc[key] = { value: value.value };
		}
		return acc;
	}, {});

	return !equal(filteredData, defaultData);
};

const getSelectColumnDefaults = (values: Item[]) => ({
	cellEditor: (params: CustomCellEditorProps) => (
		<SelectEditor {...params} values={values} />
	),
	cellRenderer: (params: CustomCellRendererProps) => {
		const colId = params.column?.getColId();
		if (!colId) {
			return <span>{params.value?.label}</span>;
		}

		const data = params.data[colId];
		return (
			<>
				<span className="pr-2">{params.value?.label}</span>
				{data?.isValid === false && (
					<Icon
						className="text-red-500 absolute bottom-2/4 translate-y-2/4 right-3"
						icon={faExclamationCircle}
					/>
				)}
			</>
		);
	},
});

const getMultiSelectColumnDefaults = (values: Item[]) => ({
	valueFormatter: (params: ValueFormatterParams) => params.value,
	cellRenderer: (params: CustomCellRendererProps) => {
		const colId = params.column?.getColId();
		if (!colId) {
			return (
				<span>{params.value?.map((value) => value.label).join(', ')}</span>
			);
		}

		const data = params.data[colId];
		return (
			<>
				<span className="pr-2">
					{params.value?.map((value) => value.label).join(', ')}
				</span>
				{data?.isValid === false && (
					<Icon
						className="text-red-500 absolute bottom-2/4 translate-y-2/4 right-3"
						icon={faExclamationCircle}
					/>
				)}
			</>
		);
	},
	cellEditor: (params: CustomCellEditorProps) => (
		<MultiSelectEditor {...params} values={values} />
	),
});

const useLocationAutoCompleteDefaults = () => {
	const getLocationAutoCompleteDefaults = () => ({
		minWidth: 250,
		cellEditor: (params: CustomCellEditorProps) => (
			<LocationAutoCompleteEditor {...params} />
		),
		cellRenderer: (params: CustomCellRendererProps) => {
			const colId = params.column?.getColId();
			if (!colId) {
				return <span>{formatSuggestion(params.data[colId].value)}</span>;
			}

			const data = params.data[colId];
			return (
				<>
					<span className="pr-2">
						{params.data[colId]?.value &&
							formatSuggestion(params.data[colId].value)}
					</span>
					{data?.isValid === false && (
						<Icon
							className="text-red-500 absolute bottom-2/4 translate-y-2/4 right-3"
							icon={faExclamationCircle}
						/>
					)}
				</>
			);
		},
	});

	return { getLocationAutoCompleteDefaults };
};

type Props = {
	transportType?: TransportTypeCode;
};

const MultiCreateTable = forwardRef<AgGridReact, Props>(
	({ transportType = TransportTypeCode.IMPORT }, ref) => {
		const { t } = useTranslation();
		const { columns, requiredColumns } = useColumns(transportType);
		const {
			containerTypes,
			shippingLines,
			requirements,
			locationTypes,
			termsAndConditions,
			carriers,
		} = useShipmentTableOptions();

		const defaultRowData = getDefaultRowData(transportType);
		const { getLocationAutoCompleteDefaults } =
			useLocationAutoCompleteDefaults();
		const gridOptions: GridOptions = useMemo(
			() => ({
				// Column options
				defaultColDef: {
					editable: true,
					suppressHeaderMenuButton: true,
					suppressHeaderContextMenu: true,
					sortable: false,
					minWidth: 150,
					flex: 1,
					valueSetter: (params: ValueSetterParams) => {
						let { newValue } = params;
						// If the cell is empty and the column has a default value, reset it to default value instead
						if (!newValue || newValue === '') {
							if (params.column.getColId() in defaultRowData) {
								newValue = defaultRowData[params.column.getColId()].value;
							} else {
								newValue = undefined;
							}
						}

						let newData = {
							...params.data,
							[params.column.getColId()]: {
								value: newValue,
								isValid: undefined,
							},
						};

						// Columns with side effects
						if (params.column.getColId() === 'containerType') {
							newData = {
								...params.data,
								[params.column.getColId()]: {
									value: newValue,
									isValid: undefined,
								},
								isoCode: {
									value: newValue?.isoCode,
									isValid: undefined,
								},
							};
						}

						if (params.column.getColId() === 'emptyContainer') {
							newData = {
								...params.data,
								[params.column.getColId()]: {
									value: newValue,
									isValid: undefined,
								},
								cargoGrossWeight: {
									value: {
										...params.data.cargoGrossWeight.value,
										quantity: newValue === true ? 0 : undefined,
									},
									isValid: undefined,
								},
							};
						}

						if (params.column.getColId() === 'cargoGrossWeight') {
							newData = {
								...params.data,
								[params.column.getColId()]: {
									value: newValue,
									isValid: undefined,
								},
								emptyContainer: {
									value: newValue?.quantity === 0,
									isValid: undefined,
								},
							};
						}

						if (params.column.getColId() === 'containerNumber') {
							newData = {
								...params.data,
								[params.column.getColId()]: {
									value: newValue?.toUpperCase(),
									isValid: undefined,
								},
							};
						}

						const locationRegex = /.*_location$/;
						if (params.column.getColId().match(locationRegex)) {
							const locations = {
								...Object.keys(params.data).reduce((result, key) => {
									if (locationRegex.test(key)) {
										result[key] = params.data[key].value;
									}
									return result;
								}, {}),
								[params.column.getColId()]: newValue,
							};

							newData = {
								...params.data,
								[params.column.getColId()]: {
									value: newValue,
									isValid: undefined,
								},
								termsAndConditions: {
									value: getTermsAndConditions(locations),
									isValid: undefined,
								},
							};
						}

						/**
						 * If the row is no longer dirty, which means the user has removed all the data
						 * we reset the row state to to default row state
						 * and redraw the rows inside the grid to remove any validation indicators on that row
						 */
						const isRowDirty = isDirty(newData, defaultRowData);
						if (!isRowDirty) {
							const { id } = params.data;

							params.api.applyTransaction({
								update: [{ id, dirty: isRowDirty, ...defaultRowData }],
							});
							params.api.redrawRows();

							return true;
						}

						params.api.applyTransaction({
							update: [
								{
									...newData,
									dirty: isRowDirty,
								},
							],
						});

						return true;
					},
					valueGetter: (params: ValueGetterParams) =>
						params.data[params.column.getColId()]?.value,
					tooltipValueGetter: (params: ITooltipParams) => {
						const colId = params.column?.getColId();
						if (!colId) {
							return;
						}

						return params.data[colId]?.errorMessage;
					},
					cellRenderer: (params: CustomCellRendererProps) => {
						const colId = params.column?.getColId();
						if (!colId) {
							return <span>{params.value}</span>;
						}

						const data = params.data[colId];
						return (
							<>
								<span className="pr-2">{params.value}</span>
								{data?.isValid === false && (
									<Icon
										className="text-red-500 absolute bottom-2/4 translate-y-2/4 right-3"
										icon={faExclamationCircle}
									/>
								)}
							</>
						);
					},
				},
				columnDefs: [
					{
						pinned: 'left',
						minWidth: 40,
						flex: 0,
						colId: 'rowNumber',
						headerName: '',
						valueGetter: 'node.rowIndex + 1',
						width: 40,
						cellClass: 'text-muted-foreground bg-muted text-center',
						suppressFillHandle: true,
						suppressPaste: true,
						resizeable: false,
						cellStyle: { 'padding-left': 0, 'padding-right': 0 },
						cellRenderer: (params: CustomCellRendererProps) => params.value,
					},
					...columns.map((column) => ({
						...column,
						children: column.children?.map((childColumn) => ({
							...childColumn,
							headerComponent: requiredColumns.includes(childColumn.field)
								? () => (
										<span className="truncate">
											{childColumn.headerName}
											<span className="ml-1 text-red-500">*</span>
										</span>
									)
								: childColumn.headerName,
							...(childColumn.field === 'containerType' &&
								getSelectColumnDefaults(containerTypes)),
							...(childColumn.field === 'preferredCarriers' &&
								getMultiSelectColumnDefaults(carriers)),
							...(childColumn.field === 'termsAndConditions' &&
								getSelectColumnDefaults(termsAndConditions)),
							...((childColumn.field === 'shippingLine' ||
								matchLocationField(childColumn.field, 'shippingLine')) &&
								getSelectColumnDefaults(shippingLines)),
							...(childColumn.field === 'cargoGrossWeight' && {
								cellEditor: WeightCellEditor,
								cellRenderer: (params: CustomCellRendererProps) => {
									const colId = params.column?.getColId();
									if (!colId) {
										return (
											params.value?.quantity !== undefined && (
												<span>
													{formatWeight(
														params.value.quantity,
														params.value.unit,
													)}
												</span>
											)
										);
									}

									const data = params.data[colId];
									return (
										<>
											{params.value?.quantity && (
												<span className="pr-2">
													{formatWeight(
														params.value.quantity,
														params.value.unit,
													)}
												</span>
											)}
											{data?.isValid === false && (
												<Icon
													className="text-red-500 absolute bottom-2/4 translate-y-2/4 right-3"
													icon={faExclamationCircle}
												/>
											)}
										</>
									);
								},
							}),
							...(childColumn.field === 'requirements' &&
								getMultiSelectColumnDefaults(requirements)),
							...(childColumn.field === 'price' && {
								cellEditor: CurrencyCellEditor,
								cellRenderer: (params: CustomCellRendererProps) => {
									const colId = params.column?.getColId();
									if (!colId) {
										return (
											params.value?.quantity && (
												<span>
													{formatPrice(
														params.value.quantity,
														params.value.currency,
													)}
												</span>
											)
										);
									}

									const data = params.data[colId];
									return (
										<>
											{params.value?.quantity && (
												<span className="pr-2">
													{formatPrice(
														params.value.quantity,
														params.value.currency,
													)}
												</span>
											)}
											{data?.isValid === false && (
												<Icon
													className="text-red-500 absolute bottom-2/4 translate-y-2/4 right-3"
													icon={faExclamationCircle}
												/>
											)}
										</>
									);
								},
							}),
							...(matchLocationField(childColumn.field, 'location') &&
								getLocationAutoCompleteDefaults()),
							...(matchLocationField(childColumn.field, 'type') && {
								...getSelectColumnDefaults(locationTypes),
								suppressFillHandle: true,
								editable: false,
								lockVisible: true,
							}),
							...((matchLocationField(childColumn.field, 'from') ||
								matchLocationField(childColumn.field, 'until')) && {
								minWidth: 200,
								cellEditor: (params: CustomCellEditorProps) => (
									<DateTimePickerEditor {...params} />
								),
								cellRenderer: (params: CustomCellRendererProps) => {
									const colId = params.column?.getColId();
									if (!colId) {
										return (
											<span>
												{params.value && formatDatetime(params.value)}
											</span>
										);
									}

									const data = params.data[colId];
									return (
										<>
											<span className="pr-2">
												{params.value && formatDatetime(params.value)}
											</span>
											{data?.isValid === false && (
												<Icon
													className="text-red-500 absolute bottom-2/4 translate-y-2/4 right-3"
													icon={faExclamationCircle}
												/>
											)}
										</>
									);
								},
							}),
							...((childColumn.field === 'vat' ||
								childColumn.field === 'generatorSet' ||
								childColumn.field === 'acceptancePrice' ||
								childColumn.field === 'emptyContainer' ||
								childColumn.field === 'bidOnly') && {
								cellDataType: 'boolean',
							}),
						})),
					})),
					/**
					 * Any data we want available in the grid has to be a column
					 * using hide: true and suppressColumnsToolPanel: true will hide the column and remove it from the columns tool panel
					 */
					{ field: 'dirty', hide: true, suppressColumnsToolPanel: true },
				],
				// Row options
				rowData: [...Array(GRID_ROW_COUNT)].map((_, i) => ({
					id: i + 1,
					dirty: false,
					...defaultRowData,
				})),
				getRowId: (row) => row.data.id.toString(),
				onCellClicked: (params) => {
					if (params.column.getColId() === 'rowNumber') {
						const visibleColumns = params.api.getAllDisplayedColumns();

						params.api.clearRangeSelection();
						params.api.addCellRange({
							rowStartIndex: params.node.rowIndex,
							rowEndIndex: params.node.rowIndex,
							columnStart: visibleColumns[1].getColId(),
							columnEnd: visibleColumns[visibleColumns.length - 1].getColId(),
						});
					}
				},

				autoSizeStrategy: {
					type: 'fitCellContents',
				},
				// Cell options
				stopEditingWhenCellsLoseFocus: true,
				enableRangeSelection: true,
				enableFillHandle: true,
				fillHandleDirection: 'y',
				suppressContextMenu: true,
				tooltipShowDelay: 500,
				// Sidepanel options
				sideBar: {
					toolPanels: [
						{
							id: 'columns',
							labelDefault: t('shipments.table.sidebar.columns', {
								defaultValue: 'Columns',
							}),
							labelKey: 'columns',
							iconKey: 'columns',
							toolPanel: 'agColumnsToolPanel',
							toolPanelParams: {
								suppressRowGroups: true,
								suppressValues: true,
								suppressPivots: true,
								suppressPivotMode: true,
								suppressColumnFilter: true,
								suppressColumnSelectAll: true,
								suppressColumnExpandAll: true,
							},
						},
					],
				},
				// Clipboard options
				processCellFromClipboard: ({ value, column, ...rest }) => {
					const handleProcessCellFromClipboard = (
						valueToCheck: string,
						columnToCheck: string,
					) => {
						if (
							matchLocationField(columnToCheck, 'from') ||
							matchLocationField(columnToCheck, 'until')
						) {
							if (!valueToCheck) {
								return null;
							}

							const date = dayjs(
								valueToCheck,
								[
									'DD.MM.YYYY HH:mm',
									'DD.MM.YYYY',
									'D.M.YYYY HH:mm',
									'D.M.YYYY',
									'DD-MM-YYYY HH:mm',
									'DD-MM-YYYY',
									'D-M-YYYY HH:mm',
									'D-M-YYYY',
									'DD MM YYYY HH:mm',
									'DD MM YYYY',
									'D M YYYY HH:mm',
									'D M YYYY',
									'MM/DD/YYYY HH:mm',
									'MM/DD/YYYY',
									'M/D/YYYY HH:mm',
									'M/D/YYYY',
									'YYYY-MM-DD HH:mm',
									'YYYY-MM-DD',
									'YYYY-M-D HH:mm',
									'YYYY-M-D',
								],
								false,
							);

							if (!date.isValid()) {
								return null;
							}

							return date.toDate();
						}

						if (columnToCheck === 'cargoGrossWeight') {
							return {
								...defaultRowData[columnToCheck].value,
								quantity: valueToCheck ? Number(valueToCheck) : undefined,
							};
						}

						if (columnToCheck === 'price') {
							return {
								...defaultRowData[columnToCheck].value,
								quantity: valueToCheck ? Number(valueToCheck) : undefined,
							};
						}

						if (columnToCheck === 'containerType') {
							return (
								valueToCheck &&
								containerTypes.find(
									(containerType) =>
										containerType.label.toLowerCase() === valueToCheck ||
										containerType.isoCode.toLowerCase() === valueToCheck,
								)
							);
						}

						if (columnToCheck === 'shippingLine') {
							return shippingLines.find(
								(shippingLine) =>
									shippingLine.label.toLowerCase() === valueToCheck ||
									shippingLine.scacCode?.toLowerCase() === valueToCheck,
							);
						}

						if (columnToCheck === 'requirements') {
							const valueArr = valueToCheck.replace(' ', '').split(',');

							return valueArr.map((val) =>
								requirements.find(
									(requirement) =>
										requirement.label.toLowerCase() === val.toLowerCase(),
								),
							);
						}

						return valueToCheck;
					};

					try {
						const parsedValue = JSON.parse(value);
						if (
							typeof parsedValue !== 'string' &&
							typeof parsedValue !== 'number'
						) {
							return parsedValue;
						}

						return handleProcessCellFromClipboard(
							parsedValue.toString().toLocaleLowerCase(),
							column.getColId(),
						);
					} catch {
						return handleProcessCellFromClipboard(
							value.toLocaleLowerCase(),
							column.getColId(),
						);
					}
				},
				processCellForClipboard: (params) => {
					if (params.value instanceof Date) {
						return dayjs(params.value).format('YYYY-MM-DD, HH:mm');
					}

					if (typeof params.value === 'object') {
						return JSON.stringify(params.value);
					}

					return params.value;
				},
				suppressLastEmptyLineOnPaste: true,
			}),
			[],
		);

		return (
			<div className="w-full h-[calc(100dvh_-_275px)]">
				<AgGridReact
					{...gridOptions}
					ref={ref}
					className="ag-theme-quartz align-baseline"
				/>
			</div>
		);
	},
);

MultiCreateTable.displayName = 'MultiCreateTable';
export default MultiCreateTable;
