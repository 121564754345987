import { Card, CardContent, CardHeader, CardTitle, Label } from '@uturn/ui-kit';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LaneDetailContext } from './detail-context';
import { useLocationSuggestions } from '@uturn/portal/hooks/use-location-suggestions';

export function RouteDetails() {
	const details = useContext(LaneDetailContext)!;
	const { getAddress } = useLocationSuggestions();
	const { t } = useTranslation();

	return (
		<Card>
			<CardHeader>
				<CardTitle>
					{t('pages.lanes.detail.card.route.title', 'Route')}
				</CardTitle>
			</CardHeader>
			<CardContent className="space-y-3">
				<dl>
					<dt>
						<Label>
							{t(
								'pages.lanes.detail.card.route.transport-type',
								'Transport Type'
							)}
						</Label>
					</dt>
					<dd>
						{
							// TODO: check if this works
							t(
								`general.transport_type.${details.transportType.toLowerCase()}`,
								details.transportType.toLowerCase()
							)
						}
					</dd>
				</dl>

				{details.route?.map((route, index) => (
					<dl key={index}>
						<dt>
							<Label>
								{
									t(
										`general.route-locations.${(
											route.locationType as string
										).toLowerCase()}`,
										(route.locationType as string).toLowerCase()
									)!
								}
							</Label>
						</dt>
						<dd>
							<p>{route.location.name}</p>
							<p className="text-xs">{getAddress(route.location)}</p>
						</dd>
					</dl>
				))}
			</CardContent>
		</Card>
	);
}
