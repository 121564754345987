import { type ShipmentRequirementDTO } from '@uturn/api/v1';
import {
	FormControl,
	FormField,
	FormItem,
	FormMessage,
	MultiSelect,
} from '@uturn/ui-kit';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MetaDataContext } from '@uturn/portal/context';
import { ShipmentDetailContext } from '@uturn/portal/modules/shipments/providers/shipment-detail';
import type { FormValues } from '@uturn/portal/modules/shipments/schema';
import { isFieldReadOnly } from '@uturn/portal/modules/shipments/utils';

export type ShippingRequirementsProps = {
	requirements: ShipmentRequirementDTO[];
};

export function ShippingRequirements({
	requirements,
}: ShippingRequirementsProps) {
	const { t } = useTranslation();
	const { shippingRequirementExceptions } = useContext(MetaDataContext);

	const { control } = useFormContext<FormValues>();

	const { readOnlyFields } = useContext(ShipmentDetailContext);

	return (
		<div className="grid grid-cols-1 gap-3 w-full sm:grid-cols-6">
			<div className="sm:col-span-full">
				<h3 className="text-lg font-heading font-medium tracking-tight">
					{t(
						'pages.create_shipment.shipment_details.shipping_req.heading',
						'Shipping requirements'
					)}
				</h3>
			</div>
			<div className="sm:col-span-full">
				<FormField
					control={control}
					name="requirementIds"
					render={({ field }) => (
						<FormItem>
							<FormControl>
								<MultiSelect
									onChange={field.onChange}
									placeholder={
										t(
											'pages.create_shipment.shipment_details.shipping_req.placeholder',
											'Select requirements'
										) as string
									}
									selected={
										field.value?.length
											? field.value.map((value: number) => String(value))
											: []
									}
									options={requirements
										.filter(
											(item) =>
												!shippingRequirementExceptions.includes(item.code!)
										)
										.map((item) => {
											return {
												label: item.description ?? item.code!,
												value: String(item.id!),
											};
										})}
									exclusions={requirements
										.filter((item) =>
											shippingRequirementExceptions.includes(item.code!)
										)
										.map((item) => {
											return {
												label: item.description ?? item.code!,
												value: String(item.id!),
											};
										})}
									selectAllEnabled={false}
									variant="default"
									align="start"
									filter={{
										placeholder: t(
											'pages.create_shipment.shipment_details.shipping_req.search.placeholder',
											'Search requirements...'
										) as string,
										empty: t(
											'pages.create_shipment.shipment_details.shipping_req.search.empty',
											'No requirements found.'
										) as string,
									}}
									disabled={isFieldReadOnly(readOnlyFields, 'requirementIds')}
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
			</div>
		</div>
	);
}
