import { z } from '@uturn/portal/zod';

export const formValidationMessage = (
	formValidationKey: string
): Pick<z.ZodCustomIssue, 'params'> => {
	return {
		params: {
			i18n: {
				key: formValidationKey,
			},
		},
	};
};

/**
 * Helper function to add an issue to the context, while supporting translated issue messages.
 * Where `formValidationKey` is the key of the i18n message from the `formValidation` namespace.
 * View/manage them all on [Tolgee](https://translate.uturn-now.com/projects/1/translations?filters=%7B%22filterNamespace%22%3A%5B%22formValidation%22%5D%7D).
 *
 * @usage
 * ```ts
 * addIssueCustom(ctx, 'phone_invalid');
 * ```
 *
 * **Notes on the i18n property**
 *
 * According to source code of [zod-i18n](https://github.com/teovillanueva/zod-i18n/blob/1092770400174c04e2f9684f323a93f64beaa02f/packages/core/src/index.ts#L254)
 * , the property i18n object is equivalent to the following type:
 * ```ts
 * type i18n = {
 *   key: string;
 *   values: Record<string, unknown>;
 * }
 * ```
 *
 * Where `values`'s purpose and documentation are [here](https://github.com/aiji42/zod-i18n/pull/115).
 *
 * **Not supported**
 * - ❌ Default message
 *     - No workaround, unless we decide to create a PR in the zod-i18n
 *       library itself.
 * - ❌ Specific namespace.
 *     - Workaround is to position 'formValidation' namespace prior to 'zod'
 *       in the parameters list of the makeZodI18nMap function. That way, if
 *       the key is missing in Tolgee, adding the key in-context translation
 *       assumes adding it to the 'formValidation' namespace.
 */
export const addIssueCustom = (
	ctx: z.RefinementCtx,
	formValidationKey: string
) => {
	const { params } = formValidationMessage(formValidationKey);
	ctx.addIssue({
		code: z.ZodIssueCode.custom,
		params,
	});
};
