/* eslint-disable no-restricted-syntax */
import type { AddressComponent, LocationDetailsDto } from '@uturn/api/v1';
import { AddressComponentTypesItem, searchLocation } from '@uturn/api/v1';
import type { SetStateAction } from 'react';
import { useState } from 'react';

export function useLocationSuggestions() {
	const [locations, setLocations] = useState<LocationDetailsDto[]>([]);

	const getAddressItems = (location: LocationDetailsDto): string[] => {
		const details: string[] = [];
		const types: AddressComponentTypesItem[] = [
			AddressComponentTypesItem.LOCALITY,
			AddressComponentTypesItem.POSTAL_CODE,
			AddressComponentTypesItem.COUNTRY,
		];
		for (const type of types) {
			const detail: string | undefined = location.addressComponents.find(
				(component: AddressComponent) =>
					component.types.includes(type.toLowerCase())
			)?.shortName;
			if (detail && !details.includes(detail)) {
				details.push(detail);
			}
		}
		return details;
	};

	const getAddress = (location: LocationDetailsDto): string => {
		return getAddressItems(location).join(', ');
	};

	const getLocationWithAddress = (location: LocationDetailsDto): string => {
		const details: string[] = [
			location.name,
			...getAddressItems(location).filter((item) => item !== location.name),
		];
		return details.join(', ');
	};

	return {
		getAddress,
		getLocationWithAddress,
		locations,
		getLocations: (text: string) => {
			searchLocation({ name: text }).then(
				// eslint-disable-next-line @typescript-eslint/no-shadow
				(locations: { data: SetStateAction<LocationDetailsDto[]> }) => {
					setLocations(locations.data);
				}
			);
		},
	};
}
