import { faCheck, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { type GroupDto, type GroupSummary, useGetGroups } from '@uturn/api/v1';
import {
	AccordionContent,
	AccordionTrigger,
	Button,
	Checkbox,
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	Icon,
	MultiSelectBadge,
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@uturn/ui-kit';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twJoin } from 'tailwind-merge';
import { FilterContext } from './context';

const SelectedGroupBadges = ({
	groupIds,
	groups,
	onChange,
}: {
	groupIds: number[];
	groups: GroupDto[];
	onChange: (groupId: number, select: boolean) => void;
}) => {
	if (!groupIds) return <></>;

	return groupIds.map((groupId) => {
		if (!groupId) return <></>;
		const selectedGroupName =
			groups.find((group) => groupId === group.id)?.name || '-';
		return (
			<MultiSelectBadge
				key={String(groupId)}
				label={selectedGroupName}
				removeIcon={<Icon icon={faXmark} />}
				removeValue={() => onChange(groupId, false)}
				disableRemoveValue={false}
			/>
		);
	});
};

export function GroupFilter() {
	const { filters, setFilters } = useContext(FilterContext);
	const [excludeGroups, setExcludeGroups] = useState(false);
	const { t } = useTranslation();

	const { data: pageableResponseGroup } = useGetGroups({
		pageable: { page: 0 },
	});

	const groups: GroupDto[] = pageableResponseGroup?.data?.data ?? [];
	const filterGroups = (
		typeof filters['filter.groups'] === 'string'
			? [filters['filter.groups']]
			: filters['filter.groups'] ?? []
	).map((item) => Number(item)); // => [ 279 ]

	const handleOnChange = (groupId: number, select: boolean) => {
		let newFilterGroups: number[] = [];
		if (select) {
			newFilterGroups = filterGroups.length
				? [...filterGroups, groupId]
				: [groupId];
		} else {
			newFilterGroups = filterGroups.filter((item) => item !== groupId);
		}
		setFilters({ 'filter.groups': newFilterGroups });
	};

	useEffect(() => {
		setFilters({ 'filter.groups': excludeGroups ? '' : [] });
	}, [excludeGroups]);

	return (
		<>
			<AccordionTrigger>
				{t('pages.shipments.filters.groups.title', 'Groups')}
			</AccordionTrigger>
			<AccordionContent>
				<div className="flex items-center space-x-2 mb-2">
					<Checkbox
						checked={excludeGroups}
						onCheckedChange={() => setExcludeGroups(!excludeGroups)}
						id="exculde-all-groups"
					/>
					<label
						htmlFor="exculde-all-groups"
						className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
					>
						{t('pages.shipments.filters.groups.exclude', 'exclude all groups')}
					</label>
				</div>
				{!excludeGroups && (
					<div className="grid gap-1">
						<Popover>
							<PopoverTrigger asChild>
								<Button
									variant="outline"
									role="combobox"
									className={twJoin(
										'w-full justify-between items-center bg-transparent',
										filterGroups.length < 1 && 'text-muted-foreground'
									)}
								>
									<div className="flex">
										{filterGroups.length < 1 ? (
											t(
												'pages.shipments.filters.groups.include',
												'Only include selected groups'
											)
										) : (
											<SelectedGroupBadges
												groupIds={filterGroups}
												groups={groups}
												onChange={handleOnChange}
											/>
										)}
									</div>
								</Button>
							</PopoverTrigger>
							<PopoverContent align="start" className="w-full p-0">
								<Command className="max-h-[300px]">
									<CommandInput placeholder="Search groups..." />
									<CommandEmpty>No groups found.</CommandEmpty>
									<CommandGroup className="overflow-y-scroll">
										{groups.map((group) => {
											const isSelected = filterGroups.indexOf(group.id) >= 0;
											return (
												<CommandItem
													value={group.name}
													key={group.id?.toString()}
													onSelect={() => {
														handleOnChange(group.id!, !isSelected);
													}}
												>
													<Icon
														icon={faCheck}
														className={twJoin(
															'mr-2 h-4 w-4',
															isSelected ? 'opacity-100' : 'opacity-0'
														)}
													/>
													{group.name}
												</CommandItem>
											);
										})}
									</CommandGroup>
								</Command>
							</PopoverContent>
						</Popover>
					</div>
				)}
			</AccordionContent>
		</>
	);
}
